import type {
  SetEndpointType,
  SetEndPointOptionsType,
  SetEndPointExtendedOptionsType,
  ApiErrorType,
} from '../getApiLayer.types';

export const mergeArrays = (
  first: Array<any> = [],
  second: Array<any> = [],
) => {
  return [...first, ...second];
};

const RETRYABLE_STATUSES = [500];

export const normalizeQueryString = (query: Record<string, any>): string => {
  const searchParams = new URLSearchParams({});

  Object.entries(query).forEach(([key, value]) => {
    if (Array.isArray(query[key])) {
      return query[key].forEach((v: any) => {
        // Rails expects the array-like query param
        // EX) sources[]=in_app&sources[]=rss
        searchParams.append(`${key}[]`, v);
      });
    }

    searchParams.append(key, value);
  });

  return searchParams.toString();
};

export const appendUrlQueryString = (
  baseUrl: string,
  query?: Record<string, any>,
) => {
  const url = new URL(baseUrl);

  if (query) {
    url.search = normalizeQueryString(query);
  }

  return url.toString();
};

export const replaceVariables = (
  baseUrl: string,
  params?: Record<string, any>,
) => {
  const url = new URL(baseUrl);
  const route = `${url.pathname}${url.search}`;
  const replacedParams = route.replace(/:\w+/g, (key) => {
    const objKey = key.replace(':', '');
    return params?.[objKey] ?? key;
  });

  // concatenate host again with the replaced params
  return `${url.origin}${replacedParams}`;
};

export const checkEndPointId =
  <PathType = any, ParamsType = any, QueryType = any, ReturnType = any>(
    setEndpoint: SetEndpointType<PathType, ParamsType, QueryType, ReturnType>,
    getAbortControllerMap: () => Map<any, any>,
  ) =>
  (endPointOptions: SetEndPointOptionsType<ReturnType>) => {
    if (getAbortControllerMap().get(endPointOptions.id)) {
      throw new Error('endpoint should have a unique id');
    }

    return setEndpoint(endPointOptions);
  };

export const mergeEndPointOptions = <ExtendedReturnType, ReturnType>(
  originalEndpointOptions: SetEndPointOptionsType<ReturnType>,
  extendedEndpointOptions: SetEndPointExtendedOptionsType<ExtendedReturnType>,
) => {
  return {
    ...originalEndpointOptions,
    globals: {
      ...(originalEndpointOptions.globals ?? {}),
      headers: {
        ...(originalEndpointOptions.globals?.headers || {}),
        ...(extendedEndpointOptions.globals?.headers || {}),
      },
      interceptor: {
        before: mergeArrays(
          originalEndpointOptions.globals?.interceptor?.before,
          extendedEndpointOptions.globals?.interceptor?.before,
        ),
        after: mergeArrays(
          originalEndpointOptions.globals?.interceptor?.after,
          extendedEndpointOptions.globals?.interceptor?.after,
        ),
        errorHandler: mergeArrays(
          originalEndpointOptions.globals?.interceptor?.errorHandler,
          extendedEndpointOptions.globals?.interceptor?.errorHandler,
        ),
      },
    },
  } as SetEndPointOptionsType<ExtendedReturnType>;
};

export const isRetryable = (error: ApiErrorType): boolean =>
  RETRYABLE_STATUSES.includes(error?.status as number);
