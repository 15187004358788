import uFuzzy from '@leeoniya/ufuzzy';

const sortFuzzy = (haystack: string[], needle: string) => {
  const uf = new uFuzzy();
  const idxs = uf.filter(haystack, needle);
  const returnValue = [];

  if (idxs != null && idxs.length > 0) {
    const info = uf.info(idxs, haystack, needle);

    const order = uf.sort(info, haystack, needle);

    for (let i = 0; i < order.length; i++) {
      returnValue.push(haystack[info.idx[order[i]]]);
    }
  }

  return returnValue;
};

export default sortFuzzy;
