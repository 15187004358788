import parse, {
  attributesToProps,
  type DOMNode,
  domToReact,
  Element,
} from 'html-react-parser';

import { KLUE_CARD_NO_ZOOM_IMG } from 'lib/constants';

import { ImageContent } from './image-content';

const options = (logEventLabel: string, cardId?: number, rank?: number) => {
  return {
    replace: (domNode: DOMNode) => {
      if (domNode instanceof Element && domNode.attribs) {
        if (
          domNode.name === 'a' &&
          domNode.attribs.class?.includes('card-image-link')
        ) {
          const { href, target } = domNode.attribs;
          const childImage = domNode.children?.find(
            (node) => node instanceof Element && node.name === 'img',
          ) as Element | undefined;
          const src = childImage?.attribs?.src;
          if (href && src) {
            const zoom = !childImage.attribs.class?.includes(
              KLUE_CARD_NO_ZOOM_IMG,
            );
            return (
              <ImageContent
                zoom={zoom}
                src={src}
                externalLink={{ href, target }}
                logEventLabel={logEventLabel}
                cardId={cardId}
                rank={rank}
              >
                <>{domToReact(domNode.children)}</>
              </ImageContent>
            );
          }
        }

        if (
          domNode.name === 'img' &&
          !domNode.attribs.class?.includes(KLUE_CARD_NO_ZOOM_IMG)
        ) {
          const src = domNode.attribs.src;
          if (src) {
            return (
              <ImageContent
                {...attributesToProps(domNode.attribs)}
                src={src}
                zoom={true}
                logEventLabel={logEventLabel}
                cardId={cardId}
                rank={rank}
              />
            );
          }
        }
      }
    },
  };
};

const transformHTML = (
  html: string,
  logEventLabel: string,
  cardId?: number,
  rank?: number,
) => {
  const opt = options(logEventLabel, cardId, rank);
  return parse(html, opt);
};

export default transformHTML;
