import { createContext, useContext, useState, type ReactNode } from 'react';
export type ReportLoadingType = {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};
export const defaultValues: ReportLoadingType = {
  isLoading: false,
  setIsLoading: () => null,
};
export const ReportLoadingContext =
  createContext<ReportLoadingType>(defaultValues);
export const useReportLoadingProvider = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return {
    isLoading,
    setIsLoading,
  };
};
type Props = {
  children?: ReactNode;
};
export const ReportLoadingProvider = ({ children }: Props) => {
  const reportLoadingState = useReportLoadingProvider();
  return (
    <ReportLoadingContext.Provider
      children={children}
      value={reportLoadingState}
    />
  );
};
export const useReportLoadingContext = () => {
  const context = useContext(ReportLoadingContext);
  if (!context) {
    throw new Error(
      'useReportLoadingContext must be used within a ReportLoadingContext',
    );
  }
  return context;
};
