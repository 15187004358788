import { parse } from 'node-html-parser';

import { getInternalHostnames } from 'lib/utils';

/**
 *
 * @param href string
 * @returns valid hostname or empty string
 */
export const getHostname = (href: string) => {
  try {
    const url = new URL(href);
    return url.hostname.toLowerCase();
  } catch (error) {
    return '';
  }
};

/**
 * @param textHtml string input
 * @returns parsed string
 * @description It should ignore only internal links, see isInternalLink function on utils.js. For any other link,
 * it should add a target="_blank" attribute to force it open in a new tab
 * @example <a href="https://app.klue.com/profile/123" title="Link title">Internal link</a>
 * will be converted to <a href="https://app.klue.com/profile/123" target="_blank" title="Link title">Internal link</a>
 *
 */
export function transformLinks(textHtml: string) {
  const document = parse(textHtml, { parseNoneClosedTags: true });
  const links = document.querySelectorAll('a');

  links.forEach((link) => {
    const hrefValue = link.getAttribute('href');

    if (!hrefValue) {
      return;
    }

    const hostname = getHostname(hrefValue);

    if (!hostname) {
      return;
    }
    const isKlueLink = hostname.includes('klue');

    if (!isKlueLink) {
      link.setAttribute('target', '_blank');
    }

    const isInternalLink = getInternalHostnames().some((part) => {
      return hostname.startsWith(part);
    });

    if (isInternalLink) {
      return;
    }

    link.setAttribute('target', '_blank');
  });

  return document.toString();
}

const YOUTUBE_URL_REGEX =
  /"?(https?:\/\/)?(www\.)?youtu\.?be(.com)?\/(v\/|u\/\w\/|embed\/|watch\?v=|&v=)?([^#&?]{11})(&[\w\-&=;]+)?/gi;

/**
 * @param textHtml string input
 * @returns parsed string
 */
export function transformYouTubeUrls(textHtml: string) {
  if (!textHtml || typeof textHtml !== 'string') {
    return textHtml;
  }

  // convert Youtube URLs to inline video
  return textHtml.replace(YOUTUBE_URL_REGEX, (...args) => {
    const ytid = args[5];

    // if leads with a quote we'll ignore it as it's probably a href in a link
    if (args[0].slice(0, 1) === '"' || !ytid) {
      return args[0];
    }

    return `<span class="fr-video fr-deletable fr-rv fr-fvc fr-dvb">
         <iframe width="640" height="360"
           src="https://www.youtube-nocookie.com/embed/${ytid}?modestbranding=1&showinfo=0&rel=0&controls=2&color=white&wmode=opaque"
           loading="lazy"
           allowtransparency="true"
           frameborder="0"
           allowfullscreen></iframe>
       </span>`;
  });
}

/**
 *
 * @param htmlString input html content
 * @param functions array of transform functions to be applied
 * @example const parsed = applyDecorators(item, [func1, func2, func3]);
 *
 */
export const applyDecorators = (
  htmlString: string,
  functions: Array<(data: string) => string>,
): string => {
  return functions.reduce((acc, func) => {
    return func(acc);
  }, htmlString);
};
