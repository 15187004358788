import { Box, Skeleton, Text } from '@kluein/klue-ui';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { Formulas } from 'klue-html/formulas/formulas.types';
import ErrorBoundary from 'lib/ErrorBoundary';
import TEST_IDS from 'test-ids';

import EmptyData from './templates/common/EmptyData';

import type { Formula } from 'klue-html/formulas/formulas.types';

const formulaComponentsByType: Record<string, any> = {
  [Formulas.homepageScreenshot]: lazy(
    () => import('./templates/website/HomepageScreenshot'),
  ),
  [Formulas.websiteTrafficOrganicKeywords]: lazy(
    () => import('./templates/website/OrganicKeywords'),
  ),
  [Formulas.websiteTrafficPaidKeywords]: lazy(
    () => import('./templates/website/PaidKeywords'),
  ),
  [Formulas.operatingMetricsSummary]: lazy(
    () => import('./templates/financials/OperatingMetrics'),
  ),
  [Formulas.recentBlogPosts]: lazy(
    () => import('./templates/publications/RecentBlogPosts'),
  ),
  [Formulas.recentCaseStudies]: lazy(
    () => import('./templates/publications/RecentCaseStudies'),
  ),
  [Formulas.recentPressReleases]: lazy(
    () => import('./templates/publications/RecentPressReleases'),
  ),
  [Formulas.recentPublications]: lazy(
    () => import('./templates/publications/RecentPublications'),
  ),
  [Formulas.recentSocialUpdates]: lazy(
    () => import('./templates/socialMedia/RecentSocialUpdates'),
  ),
  [Formulas.employeeCount]: lazy(
    () => import('./templates/company/EmployeeCount'),
  ),
  [Formulas.compareEmployeeCount]: lazy(
    () => import('./templates/company/CompareEmployeeGrowth'),
  ),
  [Formulas.trafficPageViews]: lazy(
    () => import('./templates/website/TrafficPageViews'),
  ),
  [Formulas.recentVideos]: lazy(
    () => import('./templates/socialMedia/RecentVideos'),
  ),
  [Formulas.twitterFollowers]: lazy(
    () => import('./templates/socialMedia/TwitterFollowers'),
  ),
  [Formulas.trafficSources]: lazy(
    () => import('./templates/website/TrafficShareByType'),
  ),
  [Formulas.jobCategories]: lazy(
    () => import('./templates/people/JobCategories'),
  ),
  [Formulas.jobPostings]: lazy(() => import('./templates/people/JobPostings')),
  [Formulas.jobPostingsByRegion]: lazy(
    () => import('./templates/people/JobPostingsByRegion'),
  ),
  [Formulas.balanceSheetStatements]: lazy(
    () => import('./templates/financials/BalanceSheetStatementsYearly'),
  ),
  [Formulas.balanceSheetStatementsQuarterly]: lazy(
    () => import('./templates/financials/BalanceSheetStatementsQuarterly'),
  ),
  [Formulas.cashFlowStatements]: lazy(
    () => import('./templates/financials/CashFlowStatementsYearly'),
  ),
  [Formulas.cashFlowStatementsQuarterly]: lazy(
    () => import('./templates/financials/CashFlowStatementsQuarterly'),
  ),
  [Formulas.incomeStatements]: lazy(
    () => import('./templates/financials/IncomeStatementsYearly'),
  ),
  [Formulas.incomeStatementsQuarterly]: lazy(
    () => import('./templates/financials/IncomeStatementsQuarterly'),
  ),
  [Formulas.engagementSummary]: lazy(
    () => import('./templates/website/EngagementSummary'),
  ),
  [Formulas.keyPeople]: lazy(() => import('./templates/people/KeyPeople')),
  [Formulas.similarWebsites]: lazy(
    () => import('./templates/website/SimilarWebsites'),
  ),
  [Formulas.sfdcLatestOpportunities]: lazy(
    () => import('./templates/salesforce/SalesforceLastOpportunities'),
  ),
  [Formulas.sfdcWins]: lazy(
    () => import('./templates/salesforce/SalesforceWinRate'),
  ),
  [Formulas.revenues]: lazy(() => import('./templates/financials/Revenue')),
  [Formulas.compareRevenues]: lazy(
    () => import('./templates/financials/CompareRevenueGrowth'),
  ),
  [Formulas.officeLocations]: lazy(
    () => import('./templates/company/OfficeLocations'),
  ),
};

const dataProvidersByFormulaType = {
  [Formulas.homepageScreenshot]: lazy(
    () => import('./dataProviders/KlueProvider'),
  ),
  [Formulas.recentBlogPosts]: lazy(
    () => import('./dataProviders/ContifyProvider'),
  ),
  [Formulas.recentCaseStudies]: lazy(
    () => import('./dataProviders/ContifyProvider'),
  ),
  [Formulas.recentPressReleases]: lazy(
    () => import('./dataProviders/ContifyProvider'),
  ),
  [Formulas.recentPublications]: lazy(
    () => import('./dataProviders/ContifyProvider'),
  ),
  [Formulas.recentSocialUpdates]: lazy(
    () => import('./dataProviders/ContifyProvider'),
  ),
  [Formulas.websiteTrafficOrganicKeywords]: lazy(
    () => import('./dataProviders/SimilarwebProvider'),
  ),
  [Formulas.websiteTrafficPaidKeywords]: lazy(
    () => import('./dataProviders/SimilarwebProvider'),
  ),
  [Formulas.operatingMetricsSummary]: lazy(
    () => import('./dataProviders/CraftProvider'),
  ),
  [Formulas.employeeCount]: lazy(
    () => import('./dataProviders/PdlDataProvider'),
  ),
  [Formulas.compareEmployeeCount]: lazy(
    () => import('./dataProviders/PdlDataProvider'),
  ),
  [Formulas.trafficPageViews]: lazy(
    () => import('./dataProviders/SimilarwebProvider'),
  ),
  [Formulas.recentVideos]: lazy(
    () => import('./dataProviders/ContifyProvider'),
  ),
  [Formulas.twitterFollowers]: lazy(
    () => import('./dataProviders/CraftProvider'),
  ),
  [Formulas.trafficSources]: lazy(
    () => import('./dataProviders/SimilarwebProvider'),
  ),
  [Formulas.jobCategories]: lazy(
    () => import('./dataProviders/PdlDataProvider'),
  ),
  [Formulas.jobPostings]: lazy(
    () => import('./dataProviders/PredictLeadsProvider'),
  ),
  [Formulas.jobPostingsByRegion]: lazy(
    () => import('./dataProviders/PredictLeadsProvider'),
  ),
  [Formulas.balanceSheetStatements]: lazy(
    () => import('./dataProviders/CraftProvider'),
  ),
  [Formulas.balanceSheetStatementsQuarterly]: lazy(
    () => import('./dataProviders/CraftProvider'),
  ),
  [Formulas.cashFlowStatements]: lazy(
    () => import('./dataProviders/CraftProvider'),
  ),
  [Formulas.cashFlowStatementsQuarterly]: lazy(
    () => import('./dataProviders/CraftProvider'),
  ),
  [Formulas.incomeStatements]: lazy(
    () => import('./dataProviders/CraftProvider'),
  ),
  [Formulas.incomeStatementsQuarterly]: lazy(
    () => import('./dataProviders/CraftProvider'),
  ),
  [Formulas.engagementSummary]: lazy(
    () => import('./dataProviders/SimilarwebProvider'),
  ),
  [Formulas.keyPeople]: lazy(() => import('./dataProviders/PdlDataProvider')),
  [Formulas.similarWebsites]: lazy(
    () => import('./dataProviders/SimilarwebProvider'),
  ),
  [Formulas.sfdcLatestOpportunities]: lazy(
    () => import('./dataProviders/SalesforceProvider'),
  ),
  [Formulas.sfdcWins]: lazy(() => import('./dataProviders/SalesforceProvider')),
  [Formulas.revenues]: lazy(() => import('./dataProviders/CraftProvider')),
  [Formulas.compareRevenues]: lazy(
    () => import('./dataProviders/CraftProvider'),
  ),
  [Formulas.officeLocations]: lazy(
    () => import('./dataProviders/PdlDataProvider'),
  ),
};

type DynamicBlockProps = {
  formula: Formula;
  cardId?: number;
  editorView?: boolean;
};

export const ErrorFallback = () => {
  const { t } = useTranslation();

  return (
    <Text data-test-id={TEST_IDS.klueCard.dynamicBlock.errorFallbackText}>
      {t('Card:dynamicContent.loadError')}
    </Text>
  );
};

const DynamicBlock = ({
  formula,
  cardId,
  editorView = false,
}: DynamicBlockProps) => {
  const Component = formulaComponentsByType[formula.formulaType];
  const DataProvider = (dataProvidersByFormulaType as any)[formula.formulaType];

  //@TODO remove when all components are done
  if (!Component || !DataProvider) return null;

  return (
    <Box
      pad={!editorView ? { top: 'medium', bottom: 'large' } : undefined}
      className="inline-formula"
      contentEditable={false}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense fallback={<div />}>
          <DataProvider
            formula={formula}
            data-test-id={TEST_IDS.klueCard.dynamicBlock.container}
          >
            {({ data, loading, error }: any) => {
              if (error) {
                if (formula.formulaType.startsWith('sfdc')) {
                  return (
                    <EmptyData
                      text="dynamicContent.salesforce.emptyData"
                      cardId={cardId}
                    />
                  );
                }

                return <EmptyData cardId={cardId} />;
              }

              if (loading) {
                // TODO: Use more realistic placeholder based on dynamic type
                return (
                  <Skeleton
                    data-test-id={TEST_IDS.klueCard.dynamicBlock.skeleton}
                    height="60px"
                    animated
                  />
                );
              }

              return (
                <Box>
                  <Component formula={formula} data={data} cardId={cardId} />
                </Box>
              );
            }}
          </DataProvider>
        </Suspense>
      </ErrorBoundary>
    </Box>
  );
};

export default DynamicBlock;
