export type UpdateLastProfileViewedType = {
  profileId: number;
  profileName: string;
  rivalLogo: string;
};

const LIMIT_RECENTLY_VIEWED_ITEMS = 10;

/**
 * Sort recently viewed items
 * @param currentList Current last viewed items from user data
 * @param newItem new item to be added
 * @returns array with sorted data (Most recent should always be the first)
 */
export const sortRecentlyViewedItems = (
  currentList: Array<{
    id: number;
    name: string;
    logo: string;
  }>,
  newItem: UpdateLastProfileViewedType,
) => {
  const filteredItems = currentList.length
    ? currentList.filter((cur) => cur.id !== newItem.profileId)
    : currentList;

  const newItems = [
    {
      id: newItem.profileId,
      name: newItem.profileName,
      logo: newItem.rivalLogo,
    },
  ]
    .concat(filteredItems)
    .slice(0, LIMIT_RECENTLY_VIEWED_ITEMS);

  return newItems;
};
