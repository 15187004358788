import isEmpty from 'lodash/isEmpty';

import store from 'store';
import { SALES_FORCE_ERROR } from 'store/models/dynamic-data/dynamic-data.model.types';
import { normalizeCompanySFDCData } from 'store/models/dynamic-data/dynamic-data.utils';

import { fetchSalesforceInstance } from '../endpoints/dynamic-data';

import type { CompanyType, UsageReportsType } from 'api/api.types';
import type { ComposedReturnType } from 'lib/api';

// TODO: Remove after V1 is deployed with the new shape
export function massageUsageReports(company: CompanyType) {
  const { usageReports } = company;

  const {
    consumer = '',
    curator = '',
    individual = '',
    leaderboards = '',
  } = usageReports || {};

  if (consumer) {
    company.usageReports = {
      'consumer-usage': {
        overview: consumer as string,
        leaderboards: leaderboards as string,
        individual: individual as string,
      },
      curator,
      'competitive-analytics': {
        overview: '',
        'threat-analysis': '',
        'rep-performance': '',
        impact: '',
        'data-hygiene': '',
      },
      individual,
    } as UsageReportsType;
  }
}

/**
 *
 * SF content will need instance data and company sdfc object
 * in order to match card formulas with sf instance data
 *
 * This interceptor will fetch the current sf instance
 * whenever the sfdc is not empty
 */
const companyInterceptor = async (
  response: ComposedReturnType<CompanyType>,
) => {
  const data: CompanyType = response.data;

  const sfdc = data?.companyData?.sfdc;

  if (!isEmpty(sfdc)) {
    try {
      const {
        data: { instance },
      } = await fetchSalesforceInstance();

      if (!instance) {
        store.dispatch.dynamicData.setSalesforceError(
          SALES_FORCE_ERROR.NO_INSTANCE,
        );
      } else {
        const {
          opportunityCondition = {},
          notesTitle = '',
          notesField = '',
          opportunityValue = {},
          competitorsField = ['MainCompetitors__c', 'text'],
        } = sfdc?.[instance];

        const opportunitiesOptions =
          normalizeCompanySFDCData(opportunityCondition);

        store.dispatch.dynamicData.setSalesforceInstance({
          instance,
          opportunities: opportunitiesOptions,
          notesTitle,
          notesField,
          opportunityValue,
          competitorsField,
        });
      }
    } catch {
      store.dispatch.dynamicData.setSalesforceError(SALES_FORCE_ERROR.API);
    }
  }

  massageUsageReports(data);
  return response;
};

export default companyInterceptor;
