import type {
  CommonQueryStringType,
  ContifyQueryStringType,
  ContifyReturnType,
  CraftReturnType,
  KlueReturnType,
  PredictLeadsReturnType,
  SalesforceOpportunity,
  SimilarwebReturnType,
} from 'api/endpoints/dynamic-data.types';

export enum ProvidersEnum {
  craft,
  similarweb,
  klue,
  contify,
  Salesforce,
  predictLeads,
  pdl,
}

export enum SALES_FORCE_ERROR {
  NO_INSTANCE,
  API,
}

export type SalesforceInstanceOpportunitiesType = Record<
  string,
  Record<string, string>
>;

export type SalesforceInstanceOpportunityValueType = {
  title?: string;
  fieldName?: keyof SalesforceOpportunity | null;
};

export type SalesforceMetadataType = {
  status: 'hasInstance' | 'error' | undefined;
  instance?: string;
  error?: SALES_FORCE_ERROR;
  instanceOpportunities: SalesforceInstanceOpportunitiesType | undefined;
  notesTitle?: string;
  notesField?: keyof SalesforceOpportunity;
  opportunityValue?: SalesforceInstanceOpportunityValueType;
  competitorsField?: string[];
};

export type PopulatePayloadType = {
  key: string;
  provider: ProvidersEnum;
  data:
    | CraftReturnType
    | SimilarwebReturnType
    | KlueReturnType
    | ContifyReturnType
    | PredictLeadsReturnType
    | {
        opportunities: SalesforceOpportunity[];
      };
};

export type FetchProviderDataParamsType = {
  provider: Exclude<ProvidersEnum, ProvidersEnum.Salesforce>;
  query: CommonQueryStringType | ContifyQueryStringType;
  cardId?: number;
  extra: {
    profileId: number;
  };
};

export type PopulateSalesforceWidgetsPayloadType = {
  key: string;
  loadingKey: string;
  data: any;
};

export type FetchSalesforceDataType = {
  query: CommonQueryStringType;
  cardId: number;
  formulaType?: string;
  extra: Record<string, any>;
};
