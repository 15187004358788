import { useTranslation } from 'react-i18next';

import IMG_404 from 'assets/padlock.svg?inline';
import { APP_V1_BASEURL } from 'lib/urls';
import TEST_IDS from 'test-ids';

import FallbackView from './FallbackView';

export default function PageNotAuthorized({
  fullscreen = true,
}: {
  fullscreen?: boolean;
}) {
  const { t } = useTranslation(['Common']);

  return (
    <FallbackView
      fullscreen={fullscreen}
      image={IMG_404}
      heading={t('Common:pageNotAuthorized.title')}
      desc={t('Common:pageNotAuthorized.desc')}
      linkLabel={t('Common:pageNotAuthorized.link')}
      linkPath={APP_V1_BASEURL}
      dataTestId={TEST_IDS.fallback.pageNotAuthorized}
    />
  );
}
