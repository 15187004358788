import {
  appInitialization,
  authentication,
  initialize,
} from '@microsoft/teams-js';
import { useEffect, useState, useRef } from 'react';

export const INIT_TIMEOUT = 1000;
export const SRC_PARAM = 'msteams';

export const klueIsInTeams = (
  win: typeof window & { nativeInterface?: boolean } = window,
) => {
  const currentURL = new URL(win?.location.href);
  const redirectURL = new URL(
    `${currentURL?.origin}${currentURL?.searchParams.get('redirectTo') || '/'}`,
  );
  const currentSrc =
    currentURL?.searchParams.get('src') ??
    (redirectURL?.searchParams.get('src') || '');

  return Boolean(
    (win?.parent === win?.self && win?.nativeInterface) ||
      win?.name === 'embedded-page-container' ||
      win?.name === 'extension-tab-frame' ||
      win?.name?.startsWith('appAuthWindow_') ||
      currentSrc.startsWith(SRC_PARAM),
  );
};

export const klueIsInTeamsAuthPopup = (win: typeof window = window) => {
  return Boolean(win?.name?.startsWith('appAuthWindow_') && klueIsInTeams(win));
};

type UseMicrosoftTeamsArgs = {
  onSuccess?: () => void;
  onFailure?: (error: string) => void;
};
export const useMicrosoftTeams = ({
  onSuccess,
  onFailure,
}: UseMicrosoftTeamsArgs) => {
  const [teamsInitialized, setTeamsInitialized] = useState<boolean>();
  const initTimeoutRef = useRef<number | undefined>(undefined);
  const inTeams = klueIsInTeams();

  useEffect(() => {
    if (inTeams) {
      const initTimeout = window.setTimeout(() => {
        const error = 'useMicrosoftTeams failed to initialize teams-js';

        window.clearTimeout(initTimeout);
        setTeamsInitialized(false);
        typeof onFailure === 'function' && onFailure(error);
        appInitialization?.notifyFailure({
          reason: appInitialization.FailedReason?.Timeout,
        });
      }, INIT_TIMEOUT);

      initTimeoutRef.current = initTimeout;

      if (!teamsInitialized) {
        initialize(() => {
          window.clearTimeout(initTimeoutRef.current);
          setTeamsInitialized(true);
          typeof onSuccess === 'function' && onSuccess();
          appInitialization?.notifyAppLoaded();
          appInitialization?.notifySuccess();
        });
      }

      return () => {
        if (initTimeoutRef.current) {
          window.clearTimeout(initTimeoutRef.current);
        }
      };
    }
  }, [inTeams, onFailure, onSuccess, teamsInitialized]);

  return { teams: { isInitialized: teamsInitialized, authentication } };
};
