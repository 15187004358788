import {
  Box,
  Button,
  Heading,
  Image,
  Modal,
  PhosphorIcon,
  Select,
  Text,
  TextArea,
  theme,
  tokens,
  ThemeContext,
  useResponsive,
  Banner,
  type SelectItemProps,
} from '@kluein/klue-ui';
import sortBy from 'lodash/sortBy';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { StringParam, useQueryParams } from 'use-query-params';

import { createAlert } from 'api/endpoints/alerts';
import { Source } from 'api/endpoints/intel/intel.enums';
import SUBMIT_INTEL_IMG from 'assets/submit-intel.svg?inline';
import { useAuth } from 'contexts/auth';
import { sanitize } from 'klue-html';
import { searchString } from 'lib/stringUtils';
import store from 'store';
import TEST_IDS from 'test-ids';
import TRACKING_IDS from 'tracking-ids';

import type { RivalType } from 'api/api.types';

function normalizeSelectOptions(rivals: RivalType[], searchTerms?: string) {
  return rivals.reduce((acc, { id, name }) => {
    if (!searchTerms || searchString(name, searchTerms)) {
      acc.push({
        value: id.toString(),
        label: name,
      });
    }
    return acc;
  }, [] as SelectItemProps[]);
}

const StyledTextArea = styled(TextArea)`
  &:focus {
    box-shadow: ${tokens.color.neutral.mediumgrey.main} 0 0 2px 2px;
  }
`;

const QueryParamConfig = {
  action: StringParam,
};

const Status = {
  loading: 'loading',
  success: 'success',
  error: 'error',
  none: 'none',
} as const;

type Values<T> = T[keyof T];
type SubmitIntelProps = {
  isTestMode?: boolean;
  intelStatus?: Values<typeof Status>;
};

const size = theme.global?.size?.small;
const OTHER_SELECT_VALUE = '-1';

function SubmitIntel({
  isTestMode = false,
  intelStatus = Status.none,
}: SubmitIntelProps) {
  const [{ action: currentAction }, setQuery] =
    useQueryParams(QueryParamConfig);
  const { t } = useTranslation();
  const { isCurator, isAdmin, user } = useAuth();
  const [status, setStatus] = useState<Values<typeof Status>>(intelStatus);
  const [intelContent, setIntelContent] = useState('');
  const [value, setValue] = useState<string>();
  const [search, setSearch] = useState<string>();

  const { maxWidth } = useResponsive();

  const hasAccessToIntelAction = !isCurator && !isAdmin;
  const isSubmitIntelAction = currentAction && currentAction === 'shareIntel';

  const { rivals, currentRivalId } = useSelector(
    store.select(({ rivals: { all }, profiles: { currentRivalId } }) => ({
      rivals: all,
      currentRivalId,
    })),
  );

  const competitors = useMemo(
    () => [
      ...sortBy(normalizeSelectOptions(rivals, search), ['label']),
      {
        value: OTHER_SELECT_VALUE,
        label: t('Common:submitIntel.other'),
      },
    ],
    [rivals, search, t],
  );

  const loadSelectedOption = useCallback(() => {
    currentRivalId ? setValue(currentRivalId.toString()) : setValue('');
  }, [currentRivalId]);

  useEffect(() => {
    loadSelectedOption();
  }, [loadSelectedOption]);

  if ((!isSubmitIntelAction || !hasAccessToIntelAction) && !isTestMode) {
    return null;
  }

  const onClose = () => {
    setQuery({ action: undefined });
    setSearch(undefined);
    setStatus(Status.none);
    loadSelectedOption();
  };

  const onSubmit = async () => {
    setStatus(Status.loading);

    try {
      await createAlert({
        params: {
          item: {
            alert_type: Source.InApp,
            title: t('Common:submitIntel.sharedByName', { name: user?.name }),
            content: sanitize(intelContent),
          },
          rivals: value !== OTHER_SELECT_VALUE ? [Number(value)] : null,
        },
      });
      onSuccess();
    } catch (e) {
      return setStatus(Status.error);
    }
  };

  const onSuccess = () => {
    setStatus(Status.success);
    setTimeout(() => {
      onClose();
      setStatus(Status.none);
      setIntelContent('');
    }, 3500);
  };

  const width = {
    xsmall: 'undefined',
    success: 'xlarge',
  } as Record<string, string | undefined>;

  return (
    <ThemeContext.Extend value={{ layer: { responsiveBreakpoint: 'xsmall' } }}>
      <Modal
        showCloseButton={false}
        width={width[maxWidth.small ? 'xsmall' : status] || 'xxxlarge'}
        onClose={onClose}
        background={tokens.color.neutral.white.main}
        responsive
      >
        <Modal.Header background={tokens.color.primary.main}>
          <Box direction="row" gap="small" align="center">
            <PhosphorIcon.PaperPlaneTilt />
            <Heading
              size="medium"
              margin="none"
              level="2"
              color={tokens.color.primary.contrastmain}
              data-test-id={TEST_IDS.submitIntel.header}
            >
              {status === Status.success
                ? t('Common:submitIntel.success.header')
                : t('Common:submitIntel.header')}
            </Heading>
          </Box>
          <Button
            variant="plain"
            onClick={onClose}
            icon={<PhosphorIcon.X color={tokens.color.primary.contrastmain} />}
          />
        </Modal.Header>
        <Modal.Content>
          {status === Status.success ? (
            <Box align="center" margin="xxlarge" height={{ min: 'small' }}>
              <Image width={size} height={size} src={SUBMIT_INTEL_IMG} />
              <Heading
                level={4}
                size="small"
                margin={{ top: 'large' }}
                color={tokens.color.primary.dark}
              >
                {t('Common:submitIntel.success.title')}
              </Heading>
            </Box>
          ) : (
            <>
              <Text
                size="medium"
                color={tokens.color.neutral.black.main}
                margin={{ vertical: 'small' }}
              >
                {t('Common:submitIntel.selectCompetitorLabel')}
              </Text>
              <div data-test-id={TEST_IDS.submitIntel.select}>
                <Select
                  options={competitors}
                  onSelected={(item) =>
                    setValue((item as SelectItemProps).value)
                  }
                  onSearch={setSearch}
                  onClose={() => setSearch(undefined)}
                  value={value}
                  searchPlaceholder={t('Common:submitIntel.findCompetitor')}
                  a11yTitle={t('Common:submitIntel.selectCompetitor')}
                  placeholder={t('Common:submitIntel.selectCompetitor')}
                  trackingIds={{
                    select: TRACKING_IDS.global.submitIntel.select,
                    itemPrefix: TRACKING_IDS.global.submitIntel.selectItem,
                  }}
                />
              </div>
              <Box margin={{ vertical: 'large' }} fill="horizontal">
                <StyledTextArea
                  style={{
                    fontWeight: 'lighter',
                    height: '200px',
                    minHeight: 'calc(1.5rem + 14px)',
                  }}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setIntelContent(e.target.value);
                  }}
                  value={intelContent}
                  size="medium"
                  placeholder={t('Common:submitIntel.placeholder')}
                  resize={false}
                  focusIndicator={false}
                  data-test-id={TEST_IDS.submitIntel.textArea}
                />
              </Box>
              <Banner
                message={t('Common:submitIntel.error.msg')}
                variant="inline"
                status={Status.error}
                show={status === Status.error}
                size="small"
                fontWeight="normal"
              />
              <Box
                alignSelf="end"
                direction="row"
                gap="medium"
                pad={{ top: 'small', bottom: 'xxsmall' }}
                flex={{ shrink: 0 }}
              >
                <Button
                  variant="flat-form"
                  label={t('Common:actions.cancel')}
                  disabled={status === Status.loading}
                  onClick={onClose}
                  data-tracking-id={
                    TRACKING_IDS.global.submitIntel.cancelButton
                  }
                />
                <Button
                  variant="highlight-form"
                  label={
                    status === Status.error
                      ? t('Common:tryAgain')
                      : t('Common:submitIntel.submit')
                  }
                  loading={status === Status.loading}
                  disabled={!intelContent?.length || status === Status.loading}
                  onClick={onSubmit}
                  data-test-id={TEST_IDS.submitIntel.submitButton}
                  data-tracking-id={
                    TRACKING_IDS.global.submitIntel.submitButton
                  }
                />
              </Box>
            </>
          )}
        </Modal.Content>
      </Modal>
    </ThemeContext.Extend>
  );
}

export default memo(SubmitIntel);
