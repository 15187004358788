import { useContext } from 'react';

import authContext from './authContext';

const useAuth = () => {
  if (authContext === null) {
    throw new Error('useAuth must be used within a ProviderAuth tag');
  }
  return useContext(authContext);
};

export default useAuth;
