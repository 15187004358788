import { Box, Button, Text, Logo, tokens } from '@kluein/klue-ui';
import truncate from 'lodash/truncate';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useAuth } from 'contexts/auth';
import { APP_V1_BASEURL } from 'lib/urls';
import TEST_IDS from 'test-ids';

import type { RivalGroupRivalItemType } from 'api/api.types';

const StyledProfileBox = styled(Box)`
  &:hover {
    background-color: ${tokens.color.highlight.main};
  }
`;

export type RivalItemProps = RivalGroupRivalItemType & {
  plain?: boolean;
  isSuggestion?: boolean;
  currentProfileId?: number;
  profileButtonTestId?: string;
  battlecardButtonTestId?: string;
  onNavigate: VoidFunction;
};

const RivalItem = (props: RivalItemProps) => {
  const {
    name,
    iconUrl,
    profileId,
    isSuggestion = false,
    currentProfileId,
    profileButtonTestId = TEST_IDS.layout.rivalItem.rivalProfileButton,
    battlecardButtonTestId = TEST_IDS.layout.rivalItem.rivalBattlecardButton,
    onNavigate,
  } = props;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(['Common']);
  const { isCurator, companyLinkDefault } = useAuth();
  const navigate = useNavigate();
  const timeout = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);

  const goToProfile = (profileID: number) => {
    setLoading(true);
    navigate({
      pathname: `/profile/${profileID}`,
    });
    onNavigate();

    timeout.current = setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const goToBattlecards = (profileID: number) => {
    if (isCurator) {
      setLoading(true); // redirect may take a few seconds, lets indicate the action for users
      return (window.location.href = `${APP_V1_BASEURL}/profile/${profileID}/battlecard/view`);
    }
    navigate(`/profile/${profileID}/battlecard/view`);
    onNavigate();
  };

  const extraButtonProps = {};

  if (isSuggestion) {
    Object.assign(extraButtonProps, {
      as: 'div',
      role: 'button',
    });
  }

  const isSelected = currentProfileId === profileId;
  let backgroundColour = props.plain
    ? 'transparent'
    : tokens.color.neutral.rosegrey.main;

  if (isSelected) {
    backgroundColour = tokens.color.highlight.main;
  }

  return (
    <StyledProfileBox
      direction="row"
      align="center"
      justify="between"
      flex={{ shrink: 0 }}
      background={backgroundColour}
      pad={{ horizontal: 'xsmall' }}
      round="small"
    >
      <Box
        pad={{ vertical: 'xxsmall' }}
        flex="grow"
        border={
          props.plain || companyLinkDefault === 'battlecard'
            ? undefined
            : { side: 'right' }
        }
      >
        <Button
          fill
          variant="plain"
          size="small"
          onClick={() =>
            companyLinkDefault === 'battlecard'
              ? goToBattlecards(profileId)
              : goToProfile(profileId)
          }
          title={name}
          {...extraButtonProps}
          data-test-id={profileButtonTestId}
          leftSlot={
            <Logo
              a11yTitle={name}
              border
              data-test-id={TEST_IDS.layout.rivalItem.rivalLogo}
              size="large"
              src={iconUrl}
            />
          }
        >
          <Text as="label" size="small" fontWeight="semibold">
            <Box direction="row" align="center" gap="xsmall">
              {' '}
              {loading ? 'Loading...' : truncate(name, { length: 18 })}
            </Box>
          </Text>
        </Button>
      </Box>
      {companyLinkDefault !== 'battlecard' && (
        <Box pad={{ left: 'xsmall' }}>
          <Button
            size="small"
            variant="plain"
            title={t('Common:battlecards')}
            onClick={() => {
              goToBattlecards(profileId);
            }}
            {...extraButtonProps}
            data-test-id={battlecardButtonTestId}
          >
            <Text
              as="label"
              fontWeight="semibold"
              size="xsmall"
              color={
                isSelected
                  ? tokens.color.neutral.black.main
                  : tokens.color.neutral.darkgrey.main
              }
            >
              {t('Common:battlecards')}
            </Text>
          </Button>
        </Box>
      )}
    </StyledProfileBox>
  );
};

export default RivalItem;
