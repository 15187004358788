export enum State {
  Archived = 'archived',
  Deleted = 'deleted',
  Unprocessed = 'unprocessed',
  Working = 'working',
}

export enum Priority {
  Important = 'important',
  Interesting = 'interesting',
}

export enum ContentType {
  Conversation = 'conversation',
  Article = 'article',
  Review = 'review',
  Blog = 'blog',
  PressRelease = 'press_release',
  CaseStudy = 'case_study',
  SocialUpdate = 'social_update',
  Video = 'video',
  Webniar = 'webinar',
  DataSheet = 'data_sheet',
  Presentation = 'presentation',
  WhitePaper = 'white_paper',
  ReportStudy = 'report_study',
  RegulatoryFiling = 'regulatory_filing',
  Podcast = 'podcast',
  CompetitorWebsite = 'competitor_website',
  NewPage = 'new_page',
}

export enum Topic {
  Contract = 'contract',
  CorporateChallengesLawsuit = 'corporate_challenges__lawsuit',
  CorporateChallengesSecurityBreach = 'corporate_challenges__security_breach',
  EventConference = 'event__conference',
  EventPodcast = 'event__podcast',
  EventWebinar = 'event__webinar',
  LeadershipChangeJoins = 'leadership_change__joins',
  LeadershipChangeLeaves = 'leadership_change__leaves',
  FinancialEarningsReport = 'financials__earnings_report',
  FinancialsIpo = 'financials__ipo',
  FinancialsReceivesFunding = 'financials__receives_funding',
  FinancialsStockAnnouncement = 'financials__stock_announcement',
  GrowContractionClosesOffice = 'growth_contraction__closes_office',
  GrowContractionExpandGrowth = 'growth_contraction__expand_grow',
  GrowContractionLayoffs = 'growth_contraction__layoffs',
  GrowContractionRestructure = 'growth_contraction__restructure',
  MergersAcquisitionsAcquires = 'mergers_acquisitions__acquires',
  MergersAcquisitionsDivests = 'mergers_acquisitions__divests',
  MergersAcquisitionsMerges = 'mergers_acquisitions__merges',
  Partnership = 'partnership',
  ProductIntegration = 'product__integration',
  ProductNewProduct = 'product__new_product',
  ProductPricingChange = 'product__pricing_change',
  ProductProductComparison = 'product__product_comparison',
  ProductProductShutdown = 'product__product_shutdown',
  ProductProductUpdate = 'product__product_update',
  RecognitionAward = 'recognition_award',
}

export enum Source {
  Rss = 'rss',
  Slack = 'slack',
  Teams = 'teams',
  Extension = 'extension',
  Email = 'email',
  Kluebot = 'kluebot',
  InApp = 'in_app',
  NewPage = 'new_page',
}

export enum Sort {
  NEWEST = 'newest',
  OLDEST = 'oldest',
  RELEVANCE = 'relevance',
  RECOMMENDATION = 'recommendation',
}

export enum MenuItem {
  Search = 'search',
  Custom = 'custom',
  Settings = 'settings',
  MyNewIntel = 'myNewIntel',
  AllIntel = 'allIntel',
  Published = 'published',
  Insights = 'insights',
  Unprocessed = 'unprocessed',
  Working = 'working',
  Archived = 'archived',
  Deleted = 'deleted',
  Important = 'important',
  Interesting = 'interesting',
}

export enum ProcessedByFilters {
  Everyone = 'everyone',
  Me = 'me',
  Others = 'others',
}
