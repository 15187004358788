type ViewType = 'search' | 'battlecard' | 'board' | 'embed' | null;

export const calculateView = (
  path: string = window.location.pathname,
): ViewType => {
  const lowerPath = path.toLowerCase();

  switch (true) {
    case lowerPath.startsWith('/search'):
      return 'search';
    case lowerPath.includes('/battlecard'):
      return 'battlecard';
    case lowerPath.startsWith('/card/embed'):
      return 'embed';
    default:
      return 'board';
  }
};
