import { setEndpoint } from 'api/configure';
import errorInterceptor from 'api/interceptors/errorInterceptor';

import type {
  FavoritePostParamsType,
  FavoritePostPathType,
  FavoritePostResponseType,
  UnfavoritePostParamsType,
} from 'api/endpoints/posts/posts.types';

export const [favoritePost] = setEndpoint<
  FavoritePostPathType,
  FavoritePostParamsType,
  any,
  FavoritePostResponseType
>({
  path: '/posts/:postId/favorite.json',
  id: 'favoritePost',
  method: 'POST',
  namespace: 'posts',
  globals: {
    interceptor: {
      errorHandler: [(error) => errorInterceptor(error, {})],
    },
  },
});

export const [unfavoritePost] = setEndpoint<
  FavoritePostPathType,
  UnfavoritePostParamsType,
  any,
  FavoritePostResponseType[]
>({
  path: '/posts/:postId/unfavorite.json',
  id: 'unfavoritePost',
  method: 'POST',
  namespace: 'posts',
  globals: {
    interceptor: {
      errorHandler: [(error) => errorInterceptor(error, {})],
    },
  },
});
