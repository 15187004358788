import { useTranslation } from 'react-i18next';

import IMG_404 from 'assets/404.svg?inline';
import TEST_IDS from 'test-ids';

import FallbackView from './FallbackView';

export default function PageNotFound() {
  const { t } = useTranslation(['Common']);

  return (
    <FallbackView
      fullscreen={false}
      image={IMG_404}
      heading={t('Common:pageNotFound.title')}
      desc={t('Common:pageNotFound.desc')}
      linkLabel={t('Common:pageNotFound.link')}
      dataTestId={TEST_IDS.fallback.pageNotFound}
    />
  );
}
