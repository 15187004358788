/* eslint-disable import/no-named-as-default */
import i18n, { type InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { debugMode, detectionOptions } from './config';

const i18nInit = (i18nInitOptions?: InitOptions) => {
  return i18n
    .use(LanguageDetector)
    .use(backend)
    .use(initReactI18next)
    .init({
      ns: 'Common',
      defaultNS: 'Common',
      fallbackLng: 'en',
      load: 'languageOnly',
      debug: debugMode,
      detection: detectionOptions,
      compatibilityJSON: 'v3',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        format: function (value, format, _lng) {
          if (format === 'upper') return value.toUpperCase();
          if (format === 'lower') return value.toLowerCase();
          if (format === 'sentence')
            return Array.from(value).reduce((acc, curr, idx) => {
              const letter = curr as string;

              return (acc as string).concat(
                idx === 0 ? letter.toUpperCase() : letter,
              );
            }, '');
          return value;
        },
      },
      react: {
        useSuspense: true,
      },
      ...i18nInitOptions,
    });
};

export default i18nInit;
