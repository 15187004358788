import { type MutableRefObject, useEffect, useCallback } from 'react';

function useOnOutsideClick({
  ref,
  onOutsideClick,
}: {
  ref: MutableRefObject<HTMLElement | null>;
  onOutsideClick: () => void;
}) {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const node = event.target as Node;
      if (ref.current && !ref.current.contains(node)) {
        onOutsideClick();
      }
    },
    [ref, onOutsideClick],
  );

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handleClickOutside]);
}

export default useOnOutsideClick;
