import {
  Box,
  Lottie,
  KlueSpinner as KlueSpinnerAnimation,
  Text,
  tokens,
} from '@kluein/klue-ui';
import styled from 'styled-components';

import type { KlueSpinnerProps } from './KlueSpinner.types';

const color = ({ color }: { color?: string }) => {
  return color ?? tokens.color.primary.main;
};

const StyledBox = styled(Box)`
  svg,
  svg path {
    fill: ${color};
    stroke: ${color};
  }
`;

const KlueSpinner = ({
  width = 'medium',
  color,
  title,
  subtitle,
  size = 50,
}: KlueSpinnerProps) => (
  <StyledBox
    color={color}
    gap="small"
    align="center"
    width={width}
    className="chromatic-ignore"
  >
    <Lottie animationData={KlueSpinnerAnimation} width={size} height={size} />
    {title && (
      <Text textAlign="center" size="large" fontWeight="medium" color="black">
        {title}
      </Text>
    )}
    {subtitle && (
      <Text textAlign="center" size="large">
        {subtitle}
      </Text>
    )}
  </StyledBox>
);

export default KlueSpinner;
