import { setEndpoint } from 'api/configure';
import errorInterceptor from 'api/interceptors/errorInterceptor';

import type {
  BookmarksQueryParamsType,
  CreateBookmarksPayloadType,
  UpdateBookmarksPayloadType,
  DeleteBookmarksPayloadType,
  BookmarkType,
  BookmarkCountParamsType,
  BookmarkCountReturnType,
} from './bookmarks.types';

export const [fetchBookmarks] = setEndpoint<
  any,
  any,
  BookmarksQueryParamsType,
  BookmarkType[]
>({
  path: '/bookmarks.json',
  id: 'fetchBookmarks',
  method: 'GET',
  namespace: 'bookmarks',
  globals: {
    interceptor: {
      errorHandler: [(error) => errorInterceptor(error, {})],
    },
  },
});

export const [updateBookmark] = setEndpoint<
  any,
  UpdateBookmarksPayloadType,
  any,
  BookmarkType
>({
  path: '/bookmarks/:id',
  id: 'updateBookmark',
  method: 'PUT',
  namespace: 'bookmarks',
  globals: {
    interceptor: {
      errorHandler: [(error) => errorInterceptor(error, {})],
    },
  },
});

export const [createBookmark] = setEndpoint<
  any,
  CreateBookmarksPayloadType,
  any,
  BookmarkType
>({
  path: '/bookmarks.json',
  id: 'createBookmark',
  method: 'POST',
  namespace: 'bookmarks',
  globals: {
    interceptor: {
      errorHandler: [(error) => errorInterceptor(error, {})],
    },
  },
});

export const [deleteBookmark] = setEndpoint<
  DeleteBookmarksPayloadType,
  any,
  any,
  any
>({
  path: '/bookmarks/:id',
  id: 'deleteBookmark',
  method: 'DELETE',
  namespace: 'bookmarks',
  globals: {
    interceptor: {
      errorHandler: [(error) => errorInterceptor(error, {})],
    },
  },
});

export const [fetchBookmarksCount] = setEndpoint<
  any,
  BookmarkCountParamsType,
  any,
  BookmarkCountReturnType
>({
  path: '/intel/v1/batch',
  id: 'fetchBookmarksCount',
  method: 'POST',
  namespace: 'bookmarks',
  globals: {
    interceptor: {
      errorHandler: [(error) => errorInterceptor(error, {})],
    },
  },
});
