import { createModel } from '@rematch/core';
import omit from 'lodash/omit';

import {
  fetchContifyData,
  fetchKlueData,
  fetchSimilarwebData,
  fetchCraftData,
  fetchPdlData,
  fetchSalesforceOpportunities,
  fetchPredictLeads,
} from 'api/endpoints/dynamic-data';
import { getFormulaKeyType } from 'klue-html/formulas';
import { mergeMapValues } from 'store/utils';

import {
  type FetchProviderDataParamsType,
  type FetchSalesforceDataType,
  type PopulatePayloadType,
  ProvidersEnum,
  type SalesforceInstanceOpportunitiesType,
  type SalesforceInstanceOpportunityValueType,
  type SalesforceMetadataType,
  type SALES_FORCE_ERROR,
} from './dynamic-data.model.types';

import type {
  ContifyReturnType,
  CraftReturnType,
  KlueReturnType,
  SalesforceOpportunitiesReturnType,
  SimilarwebReturnType,
  PredictLeadsReturnType,
  SalesforceOpportunity,
  PdlDataReturnType,
} from 'api/endpoints/dynamic-data.types';
import type { RootModel } from 'store/models';

export const providersFetchMethodMap = {
  [ProvidersEnum.craft]: fetchCraftData,
  [ProvidersEnum.similarweb]: fetchSimilarwebData,
  [ProvidersEnum.klue]: fetchKlueData,
  [ProvidersEnum.contify]: fetchContifyData,
  [ProvidersEnum.predictLeads]: fetchPredictLeads,
  [ProvidersEnum.pdl]: fetchPdlData,
};

const PROVIDERS_TO_SKIP = [ProvidersEnum.Salesforce];

export type StateType = {
  byId: Map<
    string,
    {
      [ProvidersEnum.craft]?: CraftReturnType;
      [ProvidersEnum.similarweb]?: SimilarwebReturnType;
      [ProvidersEnum.klue]?: KlueReturnType;
      [ProvidersEnum.contify]?: ContifyReturnType;
      [ProvidersEnum.Salesforce]?: {
        opportunities?: SalesforceOpportunitiesReturnType;
      };
      [ProvidersEnum.predictLeads]: PredictLeadsReturnType;
      [ProvidersEnum.pdl]?: PdlDataReturnType;
    }
  >;
  loadedKeys: Set<string>;
  SalesforceMetadata: SalesforceMetadataType;
};

export const initialState = {
  byId: new Map(),
  loadedKeys: new Set(),
  SalesforceMetadata: {
    status: undefined,
    instanceOpportunities: {},
    notesTitle: undefined,
    notesField: undefined,
    opportunityValue: {},
    competitorsField: undefined,
  },
} as StateType;

export const dynamicData = createModel<RootModel>()({
  state: initialState,
  reducers: {
    reset: () => initialState,
    populate: (
      state: StateType,
      { provider, data, key }: PopulatePayloadType,
    ) => {
      const currentData = state.byId.get(key)?.[provider] ?? {};

      return {
        ...state,
        byId: mergeMapValues(state.byId, {
          [key]: {
            [provider.toString()]: {
              ...currentData,
              ...data,
            },
          },
        }),
      };
    },
    addLoadingKey: (state: StateType, key: string) => {
      return {
        ...state,
        loadedKeys: state.loadedKeys.add(key),
      };
    },
    removeLoadingKey: (state: StateType, key: string) => {
      const newLoadedKeys = new Set(state.loadedKeys);
      newLoadedKeys.delete(key);

      return {
        ...state,
        loadedKeys: newLoadedKeys,
      };
    },
    setSalesforceError: (state: StateType, error: SALES_FORCE_ERROR) => {
      return {
        ...state,
        SalesforceMetadata: {
          ...state.SalesforceMetadata,
          status: 'error' as const,
          error,
        },
      };
    },
    setSalesforceInstance: (
      state: StateType,
      {
        instance,
        opportunities,
        notesTitle,
        notesField,
        opportunityValue,
        competitorsField,
      }: {
        instance: string;
        opportunities: SalesforceInstanceOpportunitiesType;
        notesTitle?: string;
        notesField?: keyof SalesforceOpportunity;
        opportunityValue?: SalesforceInstanceOpportunityValueType;
        competitorsField?: string[];
      },
    ) => {
      return {
        ...state,
        SalesforceMetadata: {
          ...state.SalesforceMetadata,
          instance,
          status: 'hasInstance' as const,
          instanceOpportunities: opportunities,
          notesTitle,
          notesField,
          opportunityValue,
          competitorsField,
        },
      };
    },
  },
  selectors: (slice, createSelector) => ({
    getInstanceOpportunities() {
      return slice(
        ({ SalesforceMetadata }) => SalesforceMetadata.instanceOpportunities,
      );
    },
    allFetchesCompleted() {
      return slice(({ loadedKeys }) => {
        return loadedKeys.size === 0;
      });
    },
  }),
  effects: (dispatch) => ({
    async fetchSalesforceData(
      { query, cardId, extra, formulaType }: FetchSalesforceDataType,
      rootState,
    ) {
      const { dynamicData, rivals } = rootState;
      const { sfdcQueryType = '', profileId } = extra;

      const associetedRivalId = rivals.byProfileId.get(profileId.toString());

      if (!associetedRivalId) {
        return this.setSalesforceError();
      }

      const linkedRival = rivals.byId.get(associetedRivalId);

      if (!linkedRival) {
        return this.setSalesforceError();
      }

      const queryParams = { query: linkedRival.name };

      if (query.key === 'currentRival') {
        query.key = associetedRivalId.toString();
      }

      const key = JSON.stringify({
        key: query.key,
        type: formulaType,
        sfdcQueryType,
      });

      const { instanceOpportunities } = dynamicData.SalesforceMetadata;

      if (dynamicData.loadedKeys.has(key)) {
        return false;
      } else {
        this.addLoadingKey(key);
      }

      // When salesforce formula contains additional query paramater
      // we need to identify and match it using sfdc values in company data.
      // then we get the correct query value and send this to the api request
      if (sfdcQueryType && !!instanceOpportunities) {
        const matchKey = instanceOpportunities[sfdcQueryType];

        if (matchKey?.name) {
          Object.assign(queryParams, {
            conditionName: matchKey.name,
          });
        }
      }

      const res = await fetchSalesforceOpportunities({
        query: queryParams,
      });

      const { data: opportunities } = res;

      this.populate({
        provider: ProvidersEnum.Salesforce,
        data: opportunities as SalesforceOpportunity[],
        key,
      });
    },
    async getQueryParams(
      { cardId, query, extra }: FetchProviderDataParamsType,
      rootState,
    ): Promise<any> {
      const type = getFormulaKeyType(query.key.toString());
      const isDynamicKeyValue = type === 'dynamic';
      const extraParams = omit(query, 'key');

      if (!cardId) return {};

      if (type === 'domain') {
        return { domain: query.key, ...extraParams };
      }

      // currentCompany or currentRival
      if (isDynamicKeyValue) {
        if (query.key === 'currentRival') {
          const currentRivalId = rootState.profiles.currentRivalId;
          const currentProfileId = rootState.profiles.currentProfileId;

          if (!extra.profileId) {
            return null;
          }

          if (extra.profileId === currentProfileId && currentRivalId) {
            return { rivalId: currentRivalId, ...extraParams };
          }

          const cardRelatedRival =
            await dispatch.rivals.loadOrFetchRivalByProfileId(extra.profileId);

          if (cardRelatedRival?.id) {
            return { rivalId: cardRelatedRival.id, ...extraParams };
          }

          return null;
        }

        if (query.key === 'currentCompany') {
          return { rivalId: 0, ...extraParams };
        }
      }

      return { rivalId: query.key, ...extraParams };
    },
    async fetchProviderData(
      { provider, query, cardId, extra }: FetchProviderDataParamsType,
      rootState,
    ) {
      if (PROVIDERS_TO_SKIP.includes(provider)) return;

      // TODO: [Tech-Debt] - TS Bug when using "this", can't detect response type.
      const queryParams: any = await this.getQueryParams({
        query,
        provider,
        cardId,
        extra,
      });

      const fetchMethod = providersFetchMethodMap[provider];

      if (query.key === 'currentRival' && queryParams?.rivalId) {
        query.key = queryParams.rivalId.toString();
      }

      const key = JSON.stringify(query);
      const uniqueTypeKey = `${provider}-${key}`;

      if (rootState.dynamicData.loadedKeys.has(uniqueTypeKey)) {
        return false;
      } else {
        this.addLoadingKey(uniqueTypeKey);
      }

      try {
        const { data } = await fetchMethod({
          query: queryParams,
        } as any);

        this.populate({
          key,
          provider,
          data,
        });

        return data;
      } catch (error) {
        return { error: true };
      } finally {
        await this.removeLoadingKey(uniqueTypeKey);
      }
    },
  }),
});
