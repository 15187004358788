import { Box, Text, TextInput } from '@kluein/klue-ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import TEST_IDS from 'test-ids';

import RivalItem from './RivalItem';

import type { RivalGroupRivalItemType } from 'api/api.types';
import type { Dispatch, RootState } from 'store/store.types';

type SuggestionOptionType = {
  label: React.ReactNode;
  name: string;
  value: number;
  profileId?: number;
};
type SelectSuggestionType = {
  target: React.RefObject<HTMLElement>['current'];
  suggestion: SuggestionOptionType;
};

type QuickFinderProps = {
  currentProfileId: number;
  onNavigate: VoidFunction;
};

export default function QuickFinder({
  currentProfileId,
  onNavigate,
}: QuickFinderProps) {
  const [filterInput, setFilterInput] = useState('');
  const [suggestions, setSuggestions] = useState<Array<SuggestionOptionType>>(
    [],
  );
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { t } = useTranslation(['Common']);

  const rivalsMap = useSelector<
    RootState,
    Map<number, RivalGroupRivalItemType>
  >((state) => {
    return (state as any).rivals.byId;
  });

  const clearSuggestions = () => {
    setSuggestions([
      {
        name: 'no-results',
        value: 0,
        label: (
          <Box pad="xsmall">
            <Text fontWeight="semibold" size="small">
              {t('Common:search.noResults')}
            </Text>
          </Box>
        ),
      },
    ]);
  };

  useEffect(() => {
    if (filterInput.length > 2) {
      const filteredSuggestions: Array<SuggestionOptionType> = [];
      Array.from(rivalsMap).forEach(([, rival]) => {
        if (rival.name.toLowerCase().includes(filterInput.toLowerCase())) {
          filteredSuggestions.push({
            label: (
              <Box
                pad="xsmall"
                data-test-id={
                  TEST_IDS.layout.quickSwitcher.quickFinderSuggestionItem
                }
              >
                <RivalItem
                  plain
                  {...rival}
                  isSuggestion
                  currentProfileId={currentProfileId}
                  onNavigate={onNavigate}
                />
              </Box>
            ),
            name: rival.name,
            value: rival.id,
            profileId: rival.profileId,
          });
        }
      });

      if (!filteredSuggestions.length) {
        clearSuggestions();
        return;
      }

      setSuggestions(filteredSuggestions);
      return;
    }

    setSuggestions([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rivalsMap, filterInput]);

  const selectSuggestion = (option: SelectSuggestionType) => {
    const { profileId, value, name } = option.suggestion;

    setFilterInput(name);
    dispatch.profiles.setCurrentRivalId(value);
    navigate({
      pathname: `/profile/${profileId}`,
    });
  };

  const onFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterInput(e.target.value);
  };

  return (
    <TextInput
      data-test-id={TEST_IDS.layout.quickSwitcher.quickFinderInput}
      size="small"
      value={filterInput}
      placeholder={t('Common:quickSwitcher.filterPlaceholder')}
      onChange={onFilterInputChange}
      onSuggestionSelect={selectSuggestion}
      reverse
      type="search"
      title={t('Common:quickSwitcher.filterPlaceholder')}
      suggestions={suggestions
        .slice(0, 10)
        .sort((a, b) => a.name.localeCompare(b.name))}
    />
  );
}
