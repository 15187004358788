import { Banner } from '@kluein/klue-ui';
import { useTranslation } from 'react-i18next';

import TEST_IDS from 'test-ids';

import OptionLayout from '../components/OptionLayout';

export default function CardDelete() {
  const { t } = useTranslation(['Card']);

  return (
    <OptionLayout
      testId={TEST_IDS.bulkEdit.cardDelete.container}
      title={t('Card:delete.title')}
      body={
        <Banner
          status="warning"
          show
          showIcon
          message={t('Card:delete.warning')}
          fontWeight="normal"
        />
      }
    />
  );
}
