import DOMPurify from 'dompurify';

/**
 *
 * @example
 * input: <img src=x onerror=alert(1)//>
 * output: <img src="x">
 * @param textHtml string html content
 * @param config object of DOMPurify library configs. see https://github.com/cure53/DOMPurify#can-i-configure-dompurify
 * @return string sanitized html content
 */

// TODO: here we can set some default Tags and Attributes based on our needs later. e.g: DOMPurify.setConfig({ALLOWED_TAGS: ['iframe', '#text'], ALLOWED_ATTR: ['style']});

DOMPurify.addHook('uponSanitizeElement', function (currentNode, { tagName }) {
  if (tagName === 'iframe') {
    currentNode.textContent = null;
    if (currentNode.hasAttribute('allowfullscreen')) {
      // DOMPurify omits allowfullscreen="“”" from the output
      // but this works around that...
      currentNode.setAttribute('allowfullscreen', '');
    }
  }
});

export default function sanitize(
  textHtml: string,
  config?: DOMPurify.Config,
): string {
  if (config) return DOMPurify.sanitize(textHtml, config) as string;
  return DOMPurify.sanitize(textHtml);
}
