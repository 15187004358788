import { updateCards, deleteCards } from 'api/endpoints/card';

import type { CardType, UpdateCardsType } from 'api/api.types';

export enum CardUpdaterStatus {
  success = 1,
  error = 2,
}

export type OnCardUpdatedFunction = (card: CardType) => void;
export type CardParamsType = {
  cardParams: Array<Partial<CardType>>;
  onCardUpdated?: OnCardUpdatedFunction;
};

export type OnCardDeletedFunction = (card: CardType) => void;
export type CardDeleteParamsType = {
  cardIds: Array<number>;
  onCardDeleted?: () => void;
};

export const deleteCardsWithParams = ({
  cardIds,
  onCardDeleted,
}: CardDeleteParamsType): Promise<{ status: CardUpdaterStatus }> =>
  new Promise(async (resolve, reject) => {
    const { status } = await deleteCards({ params: { cardIds } });

    if (status === 200) {
      onCardDeleted && onCardDeleted();

      resolve({
        status: CardUpdaterStatus.success,
      });
    } else {
      reject({
        status: CardUpdaterStatus.error,
      });
    }
  });

export const updateCardsWithParams = ({
  cardParams,
  onCardUpdated,
}: CardParamsType): Promise<{ status: CardUpdaterStatus }> =>
  new Promise(async (resolve, reject) => {
    const total = cardParams.length;
    // const results: Array<CardType> = [];
    for (let i = 0; i < total; i++) {
      try {
        const cardData = cardParams[i];
        const params: UpdateCardsType = {};
        if (!cardData.id) {
          continue;
        }
        params.cardIds = [cardData.id];
        params.isDraft = cardData.isDraft;
        params.allAccess = cardData.allAccess;
        params.keepUpdatedAt = true;
        if (cardData.visibilityGroups) {
          params.visibilityGroups = cardData.visibilityGroups.map(
            ({ id }) => id,
          );
        }
        const { data } = await updateCards({ params });
        if (!data) {
          throw new Error('useCardUpdater received invalid card data.');
        }
        // results.push(data);
        const finished = i === total - 1;

        onCardUpdated && onCardUpdated(data[0]);

        if (finished) {
          resolve({
            // results,
            status: CardUpdaterStatus.success,
          });
        }
      } catch {
        reject({
          status: CardUpdaterStatus.error,
          // results,
        });
        break;
      }
    }
  });
