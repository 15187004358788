import { useEffect, useState } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';

import { useAuth } from 'contexts/auth';
import { checkImpersonatingOrStaffAccess } from 'contexts/auth/auth.utils';
import { useEmbedded } from 'contexts/ui/embedded-context';
import { Env, getEnv } from 'lib/utils';

import loadFullStory from './loadFullStory';

import type { CompanyType, UserType } from 'api/api.types';

type FullStoryUserVars = {
  displayName: string;
  email: string;
  userId_int: number;
  createdAt_date: string;
  lastSeenAt_date: string;
  isCurator_bool: boolean;
  companyId_int: number;
  companyName_str: string;
};
type FullStoryType = {
  identify: (id: string, vars: FullStoryUserVars) => void;
  restart: () => void;
  shutdown: () => void;
};
type FullStoryInstalled = {
  FS?: FullStoryType;
};

function displayNameForEnvironment(name: string) {
  if (getEnv() !== Env.Production) {
    return name + ' [TEST]';
  }
  return name;
}

function FullStoryEnabled({
  fullstory,
  user,
  company,
  isCurator,
}: {
  fullstory: FullStoryType;
  user: UserType;
  company: CompanyType;
  isCurator: boolean;
}) {
  useEffect(() => {
    const {
      name,
      email,
      id: userId_int,
      createdAt: createdAt_date,
      lastSeenAt: lastSeenAt_date,
    } = user;
    const { id: companyId_int, name: companyName_str } = company;
    const displayName = displayNameForEnvironment(name);
    const vars = {
      displayName,
      email,
      userId_int,
      createdAt_date,
      lastSeenAt_date,
      isCurator_bool: isCurator,
      companyId_int,
      companyName_str,
    };

    fullstory.identify(user.id.toString(), vars);
  }, [fullstory, user, company, isCurator]);
  return null;
}

export const ENABLED_ROUTES = [
  { path: '/composer/*' },
  { path: '/settings/company-profile' },
];

export default function FullStory({ orgId }: { orgId: string }) {
  const { user, company, isCurator } = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFullStoryStarted, setIsFullStoryStarted] = useState(false);
  const isEmbedded = useEmbedded();
  const { pathname } = useLocation();
  const fullstory = (window as FullStoryInstalled).FS;
  const isEnabledForAccess =
    !checkImpersonatingOrStaffAccess(user) &&
    matchRoutes(ENABLED_ROUTES, pathname);

  useEffect(() => {
    if (fullstory) {
      if (!isEnabledForAccess) {
        fullstory.shutdown();
        setIsFullStoryStarted(false);
      } else if (!isFullStoryStarted) {
        fullstory.restart();
        setIsFullStoryStarted(true);
      }
    }
  }, [isEnabledForAccess, fullstory, isFullStoryStarted]);

  useEffect(() => {
    if (
      !isEmbedded &&
      !isLoaded &&
      user &&
      !user.isImpersonating &&
      company &&
      isEnabledForAccess
    ) {
      loadFullStory(orgId);
      setIsLoaded(true);
    }
  }, [isEmbedded, orgId, isLoaded, user, company, isEnabledForAccess]);

  if (isLoaded && fullstory && user && company && isEnabledForAccess) {
    return (
      <FullStoryEnabled
        fullstory={fullstory}
        user={user}
        company={company}
        isCurator={isCurator}
      />
    );
  }
  return null;
}
