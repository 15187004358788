import { permissionIds } from 'components/common/klueCard/partials/klue-card-visibility-groups/helpers';

import type {
  CardType,
  CardVisibilityGroupsType,
  VisibilityGroupType,
} from 'api/api.types';

export type CardPermissionsAssignmentProps = {
  visibleTo: number;
  selectedGroups: Set<number>;
  selectedCards: Set<number>;
  visibilityGroups: Array<VisibilityGroupType>;
};
export type GetCardPermissionCardsDataResult = Array<Partial<CardType>>;
export const getCardPermissionCardsData = ({
  visibleTo,
  selectedGroups,
  selectedCards,
  visibilityGroups,
}: CardPermissionsAssignmentProps): GetCardPermissionCardsDataResult => {
  const { curatorsAnd, curatorsOnly, everyone } = permissionIds;
  const isDraft = visibleTo === curatorsOnly;
  const allAccess = visibleTo === everyone;
  let cardVisibilityGroups: CardVisibilityGroupsType;
  if (visibleTo === curatorsAnd) {
    cardVisibilityGroups = [];
    selectedGroups.forEach((groupId) => {
      const { id, name } =
        visibilityGroups.find(
          (visibilityGroup) => groupId === visibilityGroup.id,
        ) || {};
      if (id && name) {
        cardVisibilityGroups.push({ id, name });
      }
    });
  }

  const cardUpdates: Array<Partial<CardType>> = [];
  selectedCards.forEach((id) => {
    const cardUpdate: Partial<CardType> = {
      id,
      isDraft,
      allAccess,
    };
    if (cardVisibilityGroups) {
      cardUpdate.visibilityGroups = cardVisibilityGroups;
    }
    cardUpdates.push(cardUpdate);
  });

  return cardUpdates;
};
