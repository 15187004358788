import { Notifier, type INotice } from '@airbrake/browser';
import { Paragraph, tokens } from '@kluein/klue-ui';
import {
  ErrorBoundary as ReactErrorBoundary,
  type ErrorBoundaryPropsWithComponent,
} from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import IMG_500 from 'assets/error.svg?inline';
import { useAuth } from 'contexts/auth';
import { Env, getEnv, getPackageValue } from 'lib/utils';

import { AIRBRAKE_IGNORED_ERRORS } from './constants';

import ErrorFeedback from '../components/fallback/FallbackView';

const hasAirbrakeKeys =
  import.meta.env.VITE_AIRBRAKE_PROJECT_ID &&
  import.meta.env.VITE_AIRBRAKE_PROJECT_KEY;

type ErrorBoundaryProps = Partial<ErrorBoundaryPropsWithComponent> & {
  children?: any;
  fullscreen?: boolean;
  image?: string;
  heading?: string;
  desc?: string | React.ReactNode;
};

const environment = getEnv();
let session: { user: number; company: number } | null = null;
const airbrake: Notifier | null =
  hasAirbrakeKeys && environment !== Env.Development
    ? new Notifier({
        projectId: Number(import.meta.env.VITE_AIRBRAKE_PROJECT_ID || 0),
        projectKey: String(
          import.meta.env.VITE_AIRBRAKE_PROJECT_KEY ||
            'UNDEFINED_VITE_AIRBRAKE_PROJECT_KEY',
        ),
      })
    : null;
if (airbrake) {
  airbrake.addFilter((notice: INotice) => {
    if (
      AIRBRAKE_IGNORED_ERRORS.includes(notice.errors?.[0].type || '') ||
      AIRBRAKE_IGNORED_ERRORS.includes(notice.errors?.[0].message || '')
    ) {
      return null;
    }

    notice.context = {
      ...notice.context,
      version: `${getPackageValue('name')} v${getPackageValue('version')}`,
      environment,
      url: window.location.toString(),
    };
    if (session) {
      notice.session = session;
    }

    return notice;
  });
}

const ErrorBoundary = (props: ErrorBoundaryProps) => {
  const { children, desc, fullscreen, image, heading } = props;
  const { t } = useTranslation(['Common']);
  const { user, company } = useAuth();
  const userId = user?.id;
  const companyId = company?.id;
  if (userId && companyId) {
    session = { user: userId, company: companyId };
  }
  let handleError = console.error; // eslint-disable-line no-console
  if (airbrake) {
    handleError = (error: Error, info: { componentStack: string }) => {
      airbrake?.notify({
        error,
        params: { info },
      });
    };
  }

  return (
    <ReactErrorBoundary
      fallbackRender={({ error }) => (
        <ErrorFeedback
          fullscreen={fullscreen ?? false}
          image={image ?? IMG_500}
          heading={heading ?? t('Common:fatalErrorFeedback.title')}
          desc={
            desc ?? (
              <>
                <Paragraph size="medium">
                  {t('Common:fatalErrorFeedback.desc')}
                </Paragraph>

                {environment !== Env.Production && (
                  <Paragraph
                    size="xsmall"
                    color={tokens.color.status.error.main}
                  >
                    {`${error.name}: ${error.message}`}
                  </Paragraph>
                )}
              </>
            )
          }
        />
      )}
      onError={handleError}
      onReset={props.onReset}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
export { airbrake };
