export const IMG_LINK_CLASS = 'klue-img-link';

export const CARD_CONTENT_ALLOWED_ATTR = ['target', 'allow', 'allowfullscreen'];

export const CARD_HEADER_ALLOWED_TAGS = ['mark'];

export const CARD_CONTENT_ALLOWED_TAGS = [
  'a',
  'abbr',
  'address',
  'article',
  'aside',
  'b',
  'bdi',
  'bdo',
  'blockquote',
  'br',
  'caption',
  'cite',
  'code',
  'data',
  'del',
  'details',
  'dfn',
  'div',
  'dl',
  'em',
  'fieldset',
  'figure',
  'footer',
  'form',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'header',
  'hgroup',
  'hr',
  'i',
  'iframe',
  'img',
  'ins',
  'kbd',
  'label',
  'li',
  'main',
  'map',
  'mark',
  'math',
  'meter',
  'nav',
  'nl',
  'noscript',
  'ol',
  'output',
  'p',
  'picture',
  'pre',
  'progress',
  'q',
  'ruby',
  's',
  'samp',
  'section',
  'small',
  'span',
  'strike',
  'strong',
  'sub',
  'sup',
  'svg',
  'table',
  'tbody',
  'td',
  'tfoot',
  'th',
  'thead',
  'time',
  'tr',
  'u',
  'ul',
  'var',
  'video',
  'wbr',
];
