import toLower from 'lodash/toLower';
import upperFirst from 'lodash/upperFirst';
import isURL from 'validator/lib/isURL';

import packageJson from '../../package.json';

type onVisibilityChangeType = {
  onHidden: VoidFunction;
  onActive: VoidFunction;
};
export function onVisibilityChange(callbacks: onVisibilityChangeType) {
  function handleVisibilityChange() {
    if (document.hidden) {
      callbacks.onHidden();
    } else {
      callbacks.onActive();
    }
  }

  document.addEventListener('visibilitychange', handleVisibilityChange, false);

  return () =>
    document.removeEventListener(
      'visibilitychange',
      handleVisibilityChange,
      false,
    );
}

export const pipe =
  <T>(functions: Array<(options: T) => T>) =>
  (args: T) =>
    functions.reduce((arg, fn) => fn(arg), args);

/**
 * Convert a long number into abbreviated string
 * @param n number
 */
export const formatCash = (num: number) => {
  const frmt = (n: number, d: number) => Math.sign(num) * +(n / d).toFixed(1);
  const n = Math.abs(num);
  if (n >= 1e3 && n < 1e6) return `${frmt(n, 1e3)}K`;
  if (n >= 1e6 && n < 1e9) return `${frmt(n, 1e6)}M`;
  if (n >= 1e9 && n < 1e12) return `${frmt(n, 1e9)}B`;
  if (n >= 1e12) return `${frmt(n, 1e12)}T`;

  return num;
};

/**
 * Rounds a number to the nearest given multiple
 * @param n number
 * @param multiple number
 */
export const roundToNearest = (n: number, multiple = 10): number => {
  if (Number.isNaN(n) || multiple === 0) return 0;

  return n % multiple <= 0
    ? Math.floor(n / multiple) * multiple
    : Math.floor(n / multiple) * multiple + multiple;
};

export const startCase = (name: string | undefined): string =>
  name
    ?.split(' ')
    .map((word) => upperFirst(toLower(word)))
    .join(' ') || '';

/**
 * Detect valid IDs from api objects
 * @param id string
 * @param isZeroIdValid booleam
 */
export const isValidId = (id: string | number, isZeroIdValid = false) =>
  Boolean(
    String(id).length &&
      /^\d+$/.test(String(id)) &&
      Number(id) >= (isZeroIdValid ? 0 : 1),
  );

/**
 * Detect valid card links from a click event
 *
 * @param {object} target
 * @returns A valid cardId or null
 */
export const getCardIdFromTarget = (target: HTMLAnchorElement) => {
  const cardLinkRegex = /(?:\/profile\/\d+\/view)?\/card\/(\d+)/i;

  const isLinkElement = target.nodeName && target.nodeName === 'A';
  const parentLink = target.closest('a') as HTMLAnchorElement;

  // first check if target is a valid link DOM element
  // in some cases <a> links will contain child elements so we need to find the correct target href
  if (!isLinkElement && !parentLink) {
    return null;
  }

  const targetLinkHref =
    (isLinkElement && target.getAttribute('href')) ||
    parentLink.getAttribute('href') ||
    '';

  if (targetLinkHref && cardLinkRegex.test(targetLinkHref)) {
    const matches = targetLinkHref.match(cardLinkRegex);

    if (!matches?.length) return null;

    const cardId = Number(matches[1]);

    return isValidId(cardId) ? cardId : null;
  }

  return null;
};

export const pluralize = (str = '', count = 0) =>
  `${str}${count !== 1 ? 's' : ''}`;

export enum Env {
  Production = 'production',
  Staging = 'staging',
  Development = 'development',
}

export const getEnv = (
  isProductionBuild = import.meta.env.PROD,
  host = window.location.host,
) => {
  if (isProductionBuild) {
    if (/(app-staging|klue.io)/.test(host)) {
      return Env.Staging;
    }
    return Env.Production;
  }
  return Env.Development;
};

export const getPackageValue = (key = ''): any => {
  return (packageJson as any)[key];
};

/**
 * Moves an array item from one position in an array to another
 * @param array array
 * @param moveIndex number
 * @param toIndex number
 */
export const move = (array: Array<any>, moveIndex: number, toIndex: number) => {
  const item = array[moveIndex];
  const length = array.length;
  const diff = moveIndex - toIndex;

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length),
    ];
  } else if (diff < 0) {
    // move right
    const targetIndex = toIndex + 1;
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length),
    ];
  }
  return array;
};

/**
 * Formats names to First_Name and Last_Name Initial
 *
 * @param {string}fullName
 * @returns {string}
 */
export const formatName = (fullName: string): string => {
  const isMoreThanOneName = fullName.split(' ').length > 1;
  const lastInitialCharacter = isMoreThanOneName
    ? `${fullName?.split(' ')?.pop()?.charAt(0)}.`
    : '';
  const [first, second] = fullName.split(' ');
  const name = fullName.split(' ').length > 2 ? `${first} ${second}` : first;
  return `${name} ${lastInitialCharacter}`.trim();
};

/**
 * Check if a hostname is klue hostname
 * @param hostname string
 * @returns boolean
 */
export const isInternalLink = (hostname: string) => {
  const internalHosts = getInternalHostnames();
  return internalHosts.some((part) => hostname.startsWith(part));
};

/**
 * Return klues hostnames from env vars
 * @returns array
 */
export const getInternalHostnames = () => {
  try {
    const klueHostname = new URL(import.meta.env.VITE_KLUE_V1_BASEURL || '')
      .hostname;
    // const internalHosts = [klueHostname, 'v2.' + klueHostname];
    const subdomains = ['v2', 'help'];
    const internalHosts = [
      klueHostname,
      ...subdomains.map((subdomain) => `${subdomain}.${klueHostname}`),
    ];
    return internalHosts;
  } catch (error) {
    return [];
  }
};

/**
 * Check if a url is klue profile url or search url
 * @param url string
 * @returns boolean
 */
export const isInternalUrl = (urlString: string) => {
  const internalRegex = /\b(?:profile|search)\b/gi;
  try {
    const url = new URL(urlString);
    return isInternalLink(url.hostname) && internalRegex.test(url.pathname);
  } catch (error) {
    return false;
  }
};

export const getBrowserTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * Return extension of a URL/file path
 */
export const getAssetExtension = (url: string) => {
  if (!url) return '';
  const extension = url.split('.').pop();
  return extension || '';
};

/**
 * Check whether a string is a valid URL
 */
export const isValidURL = (url: string) =>
  isURL(url, {
    protocols: ['http', 'https'],
    require_protocol: true,
    require_valid_protocol: true,
  });
