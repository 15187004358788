import { useCallback } from 'react';
import { StringParam, NumberParam, useQueryParams } from 'use-query-params';

import { useCard } from 'hooks/use-card';

const QueryParamConfig = {
  nav: StringParam,
  viewCard: NumberParam,
  rank: NumberParam,
  section: StringParam,
};

export default function useCardViewer() {
  const [{ viewCard: currentCardId, rank: currentCardRank }, setQuery] =
    useQueryParams(QueryParamConfig);

  const { card, profile, loadCard, failed } = useCard(
    currentCardId ? String(currentCardId) : undefined,
  );

  const open = useCallback(
    (
      cardId: number,
      options?: {
        rank?: number | null;
        scrollToSources?: boolean;
      },
    ) => {
      const sourceParam = options?.scrollToSources
        ? { section: 'sources' }
        : {};
      setQuery({ viewCard: cardId, rank: options?.rank, ...sourceParam });
    },
    [setQuery],
  );

  const close = useCallback(() => {
    setQuery({ viewCard: undefined, rank: undefined, section: undefined });
  }, [setQuery]);

  return {
    currentCardId,
    targetCard: card,
    targetProfile: profile,
    close,
    open,
    failed,
    loadCard,
    currentCardRank,
  };
}
