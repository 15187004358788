import { Box, tokens } from '@kluein/klue-ui';
import { memo } from 'react';

import TEST_IDS from 'test-ids';

import CardSelectorCheck from './CardSelectorCheck';

export type CardSelectorProps = {
  id?: number;
  format: string;
  isSelected?: boolean;
  onToggleSelected?: (id: number) => void;
};

const CardSelector = memo(
  ({
    id,
    isSelected = false,
    format = 'card',
    onToggleSelected,
  }: CardSelectorProps) => {
    if (!id || !onToggleSelected) {
      return null;
    }
    const isList = format === 'list';
    const borderStyles = () => {
      return {
        size: isList ? 'xsmall' : 'medium',
        ...(isSelected
          ? { color: tokens.color.highlight.main }
          : { color: 'transparent' }),
      };
    };
    const padding = () => {
      return isList
        ? { left: 'large', vertical: 'xsmall', right: 'small' }
        : 'small';
    };

    return (
      <Box
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
        align={isList ? 'start' : 'end'}
        justify="start"
        pad={padding()}
        border={borderStyles()}
        focusIndicator={false}
        background={isSelected ? 'transparent' : 'white-60'}
        data-test-id={TEST_IDS.klueCard.cardSelector.container}
        onClick={() => onToggleSelected(id)}
      >
        <CardSelectorCheck checked={isSelected} />
      </Box>
    );
  },
);

export default CardSelector;
