import { useDispatch } from 'react-redux';

import { permissionIds } from 'components/common/klueCard/partials/klue-card-visibility-groups/helpers';
import useVisibilityGroups from 'hooks/use-visibility-groups/useVisibilityGroups';

import { updateCardsWithParams, CardUpdaterStatus } from '../BulkEdit.utils';

import type { Dispatch } from 'store/store.types';

const useBulkEditPermissions = (selectedCards: Set<number>) => {
  const {
    selectedGroups,
    visibleTo,
    visibilityGroups,
    deselectAllGroups,
    setVisibleTo,
    getCardPermissionCardsData,
  } = useVisibilityGroups();
  const dispatch = useDispatch<Dispatch>();

  return {
    handleApply: async () => {
      const cardParams = getCardPermissionCardsData({
        visibleTo,
        selectedGroups,
        selectedCards,
        visibilityGroups,
      });

      const { status } = await updateCardsWithParams({
        cardParams,
        onCardUpdated: (card) =>
          dispatch.cards.updateAndParseCardsData({ updatedCards: [card] }),
      });

      if (status !== CardUpdaterStatus.success) {
        throw new Error(`Unexpected card updater status: ${status}`);
      }
    },
    disabled: false,
    reset: () => {
      deselectAllGroups();
      setVisibleTo(permissionIds.curatorsOnly);
    },
  };
};

export default useBulkEditPermissions;
