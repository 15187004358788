import { setEndpoint } from 'api/configure';

import type { SuggestionsType, SuggestionsQueryType } from './suggest.types';

export const [fetchSuggestions] = setEndpoint<
  any,
  any,
  SuggestionsQueryType,
  SuggestionsType
>({
  path: '/suggest.json',
  id: 'fetchSearchSuggestions',
  method: 'GET',
  namespace: 'suggest',
});
