import { Box, tokens } from '@kluein/klue-ui';
import { AnimatePresence, motion } from 'framer-motion';
import { Suspense, lazy, memo } from 'react';
import styled from 'styled-components';
import { useQueryParams, StringParam } from 'use-query-params';

import { useBulkEdit } from 'contexts/ui/bulk-edit-context';
import TEST_IDS from 'test-ids';

import { SideActionContentTypes } from './AppSideAction.enums';

const MotionBox = motion(Box);

const contentByType: Record<string, any> = {
  [SideActionContentTypes.bulkEdit]: lazy(
    () => import('pages/profile/partials/sideAction/bulkEdit/BulkEdit'),
  ),
};

const StyledSideActionBox = styled(Box)`
  z-index: 1;
`;

const QueryParamConfig = {
  action: StringParam,
};

function AppSideAction() {
  const [{ action: currentAction }, setQuery] =
    useQueryParams(QueryParamConfig);
  const { isActive: isBulkEditingActive } = useBulkEdit();
  const hasAccess = () =>
    currentAction === SideActionContentTypes.bulkEdit && isBulkEditingActive;

  if (!currentAction || !hasAccess()) {
    return null;
  }

  const Component = (contentByType as any)[currentAction];

  if (!Component) {
    setQuery({ action: undefined });
    return null;
  }

  return (
    <StyledSideActionBox
      width={{ min: '360px', max: '360px' }}
      fill="vertical"
      background="white"
      elevation="xlarge"
      border={{
        side: 'left',
        color: tokens.color.neutral.rosegrey.main,
        size: 'xsmall',
      }}
      overflow={{ vertical: 'auto' }}
      data-test-id={TEST_IDS.layout.appSideAction.container}
    >
      <AnimatePresence>
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          fill
          overflow="hidden"
          transition={{ duration: 0.6, ease: 'easeInOut' }}
        >
          <Suspense fallback={<div />}>
            <Component />
          </Suspense>
        </MotionBox>
      </AnimatePresence>
    </StyledSideActionBox>
  );
}

export default memo(AppSideAction);
