import { Box, Tag, getEdgeSizeFromTheme, tokens } from '@kluein/klue-ui';
import { useCallback } from 'react';

import { Priority } from 'api/endpoints/intel/intel.enums';
import { useSearch } from 'contexts/ui/search-context';
import { AnalyticsAction, useAnalytics } from 'lib/analytics';
import TEST_IDS from 'test-ids';

import { useCardInteractionLog } from '../../hooks';

import type { KlueCardTagsPropsType } from './KlueCardTags.types';
import type { KlueCardTagDataType } from 'api/api.types';
import type { TFunction } from 'i18next';

const getCardViewerStyles = ({
  isCardViewerContent,
  isCardEditorContent,
}: {
  isCardViewerContent: boolean;
  isCardEditorContent: boolean;
}) => {
  if (isCardViewerContent) {
    return {
      margin: { bottom: 'large' },
    };
  }

  if (isCardEditorContent) {
    return {
      pad: { horizontal: 'large' },
    };
  }

  return {
    pad: {
      horizontal: 'large',
      top: 'small',
    },
    border: false,
    margin: { bottom: 'medium' },
  };
};

function KlueCardTags({
  tags = [],
  analytics: { category, action = AnalyticsAction.tagClick },
  onTagRemove,
  t = ((key: string) => key) as TFunction<'translation'>,
  isCardViewerContent = false,
  isCardEditorContent = false,
  cardId,
  shouldOpenInNewTab = false,
  rank,
}: KlueCardTagsPropsType) {
  const { logEvent } = useAnalytics();
  const { search } = useSearch();
  const logCardInteraction = useCardInteractionLog();
  const cardViewerStyles = getCardViewerStyles({
    isCardViewerContent,
    isCardEditorContent,
  });

  const handleTagClick = ({ id: tagId, name }: KlueCardTagDataType) => {
    logEvent({
      event: {
        category,
        action,
        label: name,
      },
    });

    if (cardId) {
      logCardInteraction({
        action: AnalyticsAction.tag,
        cardId,
        label: null,
        rank,
      });
    }

    search({ tagId, shouldOpenInNewTab });
  };

  const handleRemoveTagEvent = useCallback(
    (tag: KlueCardTagDataType) => onTagRemove && onTagRemove(tag),
    [onTagRemove],
  );

  const tagData = Object.entries(tags);
  return tagData.length ? (
    <Box
      data-test-id={TEST_IDS.klueCard.klueCardTags.container}
      alignContent="start"
      background={tokens.color.neutral.white.main}
      direction="row"
      width={{ max: 'unset', width: 'auto' }}
      height={{ min: 'auto' }}
      wrap={true}
      {...cardViewerStyles}
    >
      {tags.map((tag) => {
        return (
          <Tag
            style={{
              marginBottom: getEdgeSizeFromTheme('xxsmall'),
              marginRight: getEdgeSizeFromTheme('xsmall'),
            }}
            key={tag.id}
            label={tag.name}
            onClick={
              isCardEditorContent ? undefined : () => handleTagClick(tag)
            }
            onRemove={onTagRemove ? () => handleRemoveTagEvent(tag) : undefined}
            removeLabel={onTagRemove && t('Card:tags.remove')}
            variant={tag.highlight ? Priority.Important : 'primary'}
          />
        );
      })}
    </Box>
  ) : null;
}

export default KlueCardTags;
