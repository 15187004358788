import {
  NotifierType,
  PhosphorIcon,
  tokens,
  useNotifier,
} from '@kluein/klue-ui';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useCopyToClipboard } from 'hooks/use-copy-to-clipboard';
import { APP_BASEURL } from 'lib/urls';
import store from 'store';
import { useCreateCardMutation, useUpdateCardMutation } from 'store/api/cards';

import { calculateView } from '../utils';

import type { CardType } from 'api/api.types';
import type { RootState, Dispatch } from 'store/store.types';

function useKlueCardMenu({
  cardId,
  profileId,
}: {
  cardId: number;
  profileId?: number;
}) {
  const { t } = useTranslation();
  const [copyToClipboard] = useCopyToClipboard();
  const { notify } = useNotifier();
  const [createCard] = useCreateCardMutation();
  const [updateCard] = useUpdateCardMutation();
  const dispatch = useDispatch<Dispatch>();
  const view = calculateView();
  const card = useSelector<RootState, CardType | undefined>((state) =>
    state.cards.byId.get(String(cardId)),
  );

  const sortedCards = useSelector((state: RootState) =>
    store.select.cards.sortedCardsByBoardId(state, card?.board.id || 0),
  );

  const handleCopyEmbed = () => {
    copyToClipboard(
      `<iframe style="width: 100%; height: auto; aspect-ratio: 16 / 9; border: none;" src="${APP_BASEURL}/card/embed/${cardId}"></iframe>`,
    );
    notify({
      type: NotifierType.SUCCESS,
      message: t('Card:actions.copyEmbed.success'),
      icon: <PhosphorIcon.Check size={tokens.iconSize.small} />,
    });
  };
  const handleCopyLink = () => {
    copyToClipboard(
      `${APP_BASEURL}/profile/${profileId}?viewCard=${cardId}&utm_source=card&utm_medium=web&utm_content=card-body`,
    );
    notify({
      type: NotifierType.SUCCESS,
      message: t('Card:actions.copyLink.success'),
      icon: <PhosphorIcon.Check size={tokens.iconSize.small} />,
    });
  };
  const handleDuplicateCard = async () => {
    const handleError = () => {
      notify({
        type: NotifierType.ERROR,
        message: t('Card:actions.duplicateCard.error'),
        icon: <PhosphorIcon.WarningCircle size={tokens.iconSize.small} />,
      });
    };

    if (!card) {
      handleError();
      return;
    }
    const viewOrder = sortedCards.reduce((acc, curr, idx) => {
      if (curr.id === card.id && idx < sortedCards.length - 1) {
        const nextCard = sortedCards[idx + 1];
        return (
          (parseFloat(curr.viewOrder) + parseFloat(nextCard.viewOrder)) / 2
        );
      }
      return acc;
    }, parseFloat(card.viewOrder) + 0.5);

    try {
      const newCard = await createCard({
        data: {
          ...card.data,
          name: `${card.data.name} - ${t('Common:actions.copy')}`,
        },
        viewOrder,
        boardId: card.board.id,
        author: card.author?.id || 0,
        reviewerId: card?.reviewer?.id,
        templateName: card?.templateName,
        allAccess: card?.allAccess,
        visibilityGroups: card?.visibilityGroups.map((group) => group.id),
        isDraft: card?.isDraft,
        tags: card?.tags.map((tag) => tag.id),
      }).unwrap();

      if (!newCard) {
        handleError();
      }

      dispatch.cards
        .addAndParseNewCard({
          newCard,
          boardCards: sortedCards,
        })
        .then(() => {
          notify({
            type: NotifierType.SUCCESS,
            message: t('Card:actions.duplicateCard.success'),
            icon: <PhosphorIcon.Check size={tokens.iconSize.small} />,
          });

          setTimeout(() => {
            document
              .querySelector(`[data-card-id="${newCard.id}"]`)
              ?.parentElement?.scrollIntoView({
                behavior: 'smooth',
              });
          }, 100);
        });
    } catch (error) {
      handleError();
    }
  };
  const handleMoveCard = async ({ boardId }: { boardId: number }) => {
    try {
      if (!card) {
        throw new Error('Card not found');
      }

      const [updatedCard] = await updateCard({
        cardIds: [card.id],
        viewOrder: 0.0, // TODO: Give users option to move to beginning or end of lane?
        boardId: boardId,
      }).unwrap();

      if (!updatedCard) {
        throw new Error('Failed to update card');
      }

      dispatch.cards
        .updateAndParseCardsData({ updatedCards: [updatedCard] })
        .then((cards) => {
          dispatch.boards.moveCardToBoard({
            cardId: card.id,
            toBoardId: boardId,
          });
          notify({
            type: NotifierType.SUCCESS,
            message: t('Card:actions.moveCard.success'),
            icon: <PhosphorIcon.Warning size={tokens.iconSize.small} />,
          });
          setTimeout(() => {
            document
              .querySelector(`[data-card-id="${updatedCard.id}"]`)
              ?.parentElement?.scrollIntoView({
                behavior: 'smooth',
              });
          }, 100);
        });
    } catch (error) {
      notify({
        type: NotifierType.ERROR,
        message: t('Card:actions.moveCard.error'),
        icon: <PhosphorIcon.X size={tokens.iconSize.small} />,
      });
    }
  };

  return {
    view,
    card,
    handleCopyEmbed,
    handleCopyLink,
    handleDuplicateCard,
    handleMoveCard,
    sortedCards,
  };
}

export default useKlueCardMenu;
