import { parseTextHtmlToJson } from 'klue-html/formulas';
import {
  applyDecorators,
  transformLinks,
  transformYouTubeUrls,
} from 'klue-html/parser';

import type { CardType } from 'api/api.types';

/**
 * Parse card content
 * @param card CardType object
 */
export const parseCardContent = (
  card: CardType,
  searchMode = false,
): CardType => {
  const html =
    (searchMode && card.data?.textHtmlWithHighlight) || card.data?.textHtml;

  if (!html) return card;

  const parsed = applyDecorators(html, [transformYouTubeUrls, transformLinks]);

  // @todo parseTextHtmlToJson should be refactored to extract only formulas
  // json output should be managed by a separate function
  const toJson = parseTextHtmlToJson({ textHtml: parsed, card });

  return {
    ...card,
    data: {
      ...card.data,
      textJson: toJson,
    },
  };
};

/**
 * Receives a list of cards item and return same object but parsed
 * @param cards Cards list object
 */
export function parseCardsContent(
  cards: Record<number, CardType>,
): Record<number, CardType> {
  const res = Object.values(cards).reduce((ac: any, curr: any) => {
    ac[curr.id] = parseCardContent(curr);

    return ac;
  }, {});

  return res;
}

/**
 * Receives a list of cards item and return same object but parsed
 * @param cards Cards list array
 */
export function parseSearchCardsContent(
  cards: Array<CardType>,
): Array<CardType> {
  return cards.map((card) => parseCardContent(card, true));
}
