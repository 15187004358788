import {
  Box,
  Button,
  Layer,
  Text,
  ThemeContext,
  tokens,
} from '@kluein/klue-ui';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'contexts/auth';
import { useSearch } from 'contexts/ui/search-context';
import TEST_IDS from 'test-ids';
import TRACKING_IDS from 'tracking-ids';

import { LAYER_Z_INDEX } from '../../NavBarGlobalSearch';

import type { ContextualSearchType } from 'contexts/ui/search-context/searchContext.types';
import type { RefObject } from 'react';
import type { SuggestionType } from 'store/models/search-suggestions/searchSuggestions.model';

export type suggestionItems = {
  label: JSX.Element;
  value: SuggestionType;
};

type SearchSuggestionsProps = {
  inputRef: RefObject<HTMLInputElement>;
  submitInputRef: RefObject<HTMLInputElement>;
  onSuggestionsClose: (e?: any) => void;
  suggestionItems: suggestionItems[];
};

const SEARCH_CONTEXTS: ContextualSearchType[] = ['cards', 'alerts'];
const SUGGESTIONS_PAD_SIZE = 4;

const SearchSuggestions = ({
  inputRef,
  onSuggestionsClose,
  suggestionItems,
  submitInputRef,
}: SearchSuggestionsProps) => {
  const { context: searchContext, setContext: setSearchContext } = useSearch();
  const { t } = useTranslation();
  const { isCurator } = useAuth();
  const shouldShowSuggestions =
    searchContext === 'cards' && suggestionItems.length > 0;

  const toggleSearchContext = (context: ContextualSearchType) => {
    setSearchContext(context);
    inputRef.current?.focus();
  };

  const getSuggestionsWidth = () => {
    const inputWidth = inputRef?.current?.getBoundingClientRect().width || 0;
    const submitWidth =
      submitInputRef?.current?.getBoundingClientRect().width || 0;
    return `${inputWidth + submitWidth + SUGGESTIONS_PAD_SIZE * 2}px`;
  };

  if (!isCurator && !shouldShowSuggestions) {
    return null;
  }

  return (
    <ThemeContext.Extend
      value={{ layer: { zIndex: (Number(LAYER_Z_INDEX) - 1).toString() } }}
    >
      <Layer
        modal={false}
        position="top"
        onClickOutside={onSuggestionsClose}
        onEsc={onSuggestionsClose}
        target={inputRef.current!}
        animation="fadeIn"
        background="transparent"
        style={{
          cursor: 'default',
        }}
        data-test-id={TEST_IDS.appNavBar.globalSearch.suggestions.container}
      >
        <Box
          pad={{
            top: '40px',
            bottom: shouldShowSuggestions ? 'none' : 'small',
          }}
          background={tokens.color.neutral.white.main}
          round="xsmall"
          elevation="small"
          flex={{ shrink: 0 }}
          style={{
            position: 'relative',
            top: -SUGGESTIONS_PAD_SIZE,
            left: -SUGGESTIONS_PAD_SIZE,
            borderTopLeftRadius: 22,
            borderTopRightRadius: 22,
          }}
          width={{
            max: 'initial',
            width: getSuggestionsWidth(),
          }}
        >
          {isCurator && (
            <Box
              gap="xsmall"
              pad={{
                horizontal: 'small',
              }}
              margin={{
                top: 'xsmall',
              }}
              data-test-id={
                TEST_IDS.appNavBar.globalSearch.searchContext.container
              }
            >
              <Text size="small" color={tokens.color.neutral.darkgrey.main}>
                {t('Search:searching')}
              </Text>
              <Box direction="row" gap="xsmall">
                {SEARCH_CONTEXTS.map((context) => (
                  <Button
                    key={context}
                    variant={
                      searchContext === context
                        ? 'highlight-action'
                        : 'flat-outlined'
                    }
                    size="small"
                    label={t(`Search:searchContext.${context}`)}
                    onClick={() => toggleSearchContext(context)}
                    data-test-id={
                      TEST_IDS.appNavBar.globalSearch.searchContext.items[
                        context
                      ]
                    }
                    data-tracking-id={
                      TRACKING_IDS.global.appNavBar.globalSearch.context[
                        context
                      ]
                    }
                    style={{ boxShadow: 'none' }}
                  />
                ))}
              </Box>
            </Box>
          )}

          {shouldShowSuggestions && (
            <Box
              data-test-id={TEST_IDS.appNavBar.globalSearch.suggestions.list}
              height={{ max: 'large' }}
              overflow={{ vertical: 'auto' }}
            >
              {suggestionItems.map((suggestion) => suggestion.label)}
            </Box>
          )}
        </Box>
      </Layer>
    </ThemeContext.Extend>
  );
};

export default SearchSuggestions;
