import { schema } from 'normalizr';

const profileSchema = new schema.Entity('profiles');

const rivalGroupsSchema = new schema.Entity('rivalGroups');

const cardsSchema = new schema.Entity('cards');

const boardSchema = new schema.Entity(
  'boards',
  { profile: profileSchema },
  {
    processStrategy: (entity) => {
      // TODO: remove this support after migrating v1 -> v2
      if (entity.cards.length && typeof entity.cards[0] === 'number') {
        const backwardsCompatibileCardsData = entity.cards.map(
          (entry: number) => ({
            id: entry,
            height: null,
          }),
        );

        return {
          ...entity,
          cards: backwardsCompatibileCardsData,
        };
      }
      return entity;
    },
  },
);

const cardTitleSchema = new schema.Entity('cardTitles');

const profileRivalSchema = new schema.Entity('rivals');

const rivalSchema = new schema.Entity('rivals');

const battlecardSchema = new schema.Entity('battlecards', {
  profile: profileSchema,
  cardTitles: new schema.Array(cardTitleSchema),
});

const groupSchema = new schema.Entity(
  'groups',
  {
    rivals: [rivalSchema],
  },
  {
    idAttribute: 'id',
  },
);

/***************************************************
  CIRCULAR DEPENDENCIES
***************************************************/

profileRivalSchema.define({
  profile: profileSchema,
});

profileSchema.define({
  boards: new schema.Array(boardSchema),
  battlecards: new schema.Array(battlecardSchema),
});

cardTitleSchema.define({
  battlecard: battlecardSchema,
});

rivalGroupsSchema.define({
  rivals: new schema.Array(rivalSchema),
});

export {
  profileSchema,
  cardsSchema,
  profileRivalSchema,
  rivalSchema,
  rivalGroupsSchema,
  groupSchema,
};
