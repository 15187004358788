export enum KlueCardInfoTypeEnum {
  created = 'created',
  updated = 'updated',
}

export type AuthorType = {
  id?: number;
  name: string;
  image?: string;
};

export type ReviewerType = {
  id?: number;
  reviewedAt?: string;
};

export type KlueCardInfoPropsType = {
  author?: Pick<AuthorType, 'id' | 'name'> | null;
  updatedAt?: string;
  type?: KlueCardInfoTypeEnum;
  sourcesCount?: number;
  onCardSourcesClick?: VoidFunction;
  hasCardSourcesIcon?: boolean;
  shouldOpenInNewTab?: boolean;
  reviewer?: ReviewerType | null;
};
