import { Text, tokens, getEdgeSizeFromTheme } from '@kluein/klue-ui';
import styled, { css } from 'styled-components';

import TEST_IDS from 'test-ids';

import type { CountTextProps } from './CountText.types';

const StyledText = styled(Text)`
  ${({ theme, color = tokens.color.neutral.darkgrey.main }) => css`
    border-radius: ${theme.global.borderSize.medium};
    border: ${theme.global.borderSize.xsmall} solid
      ${tokens.color.neutral.mediumgrey.main};
    color: ${color};
    padding: ${getEdgeSizeFromTheme('xxsmall') / 2}px
      ${theme.global.edgeSize.xxsmall};
  `}
`;

function CountText({ children, truncate = false, ...rest }: CountTextProps) {
  const value =
    children && ['string', 'number'].includes(typeof children)
      ? Number(children).toLocaleString()
      : children;
  return (
    <StyledText data-test-id={TEST_IDS.countText} {...rest}>
      {truncate && children && typeof children === 'number'
        ? new Intl.NumberFormat('en-US', {
            maximumFractionDigits: 1,
            notation: 'compact',
          }).format(children)
        : value}
    </StyledText>
  );
}

export default CountText;
