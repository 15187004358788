import { createModel } from '@rematch/core';
import isEmpty from 'lodash/isEmpty';

import { fetchCardSources } from 'api/endpoints/card';
import { mergeMapValues } from 'store/utils';

import type { CardSourceType } from 'api/api.types';
import type { RootModel } from 'store/models';

export const initialState = {
  byId: new Map<string, Record<string, CardSourceType>>(),
  allIds: new Set<string>(),
  isLoading: false,
};

export type StateType = typeof initialState;

type PopulatePayload = { sources: Record<number, CardSourceType> };

export const cardsSources = createModel<RootModel>()({
  state: initialState,
  reducers: {
    populate: (state: StateType, { sources }: PopulatePayload) => {
      return {
        byId: mergeMapValues(state.byId, sources),
        allIds: new Set([...state.allIds, ...Object.keys(sources)]),
        isLoading: false,
      };
    },
    setIsLoading: (state: StateType, value: boolean) => {
      return {
        ...state,
        isLoading: value,
      };
    },
  },
  effects: (dispatch: any) => ({
    async loadOrFetchCardSources(id: number, rootState) {
      const existingSources = rootState.cardsSources.byId.get(id.toString());

      if (!isEmpty(existingSources)) {
        return;
      }

      dispatch.cardsSources.setIsLoading(true);

      const { data } = await fetchCardSources({
        path: {
          id,
        },
      });

      if (!data.length) {
        dispatch.cardsSources.setIsLoading(false);
        return;
      }

      dispatch.cardsSources.populate({ sources: { [id]: { ...data } } });
    },
  }),
});
