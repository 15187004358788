import { setEndpoint } from '../configure';

import type { VisibilityGroupType } from '../api.types';

export const [fetchVisibilityGroups] = setEndpoint<
  any,
  any,
  any,
  Array<VisibilityGroupType>
>({
  path: '/visibility_groups.json',
  id: 'fetchVisibilityGroups',
  method: 'GET',
  namespace: 'visibilityGroups',
});

export const [fetchVisibilityGroupById] = setEndpoint<{ id: number }, any>({
  path: '/visibility_groups/:id.json',
  id: 'fetchVisibilityGroupById',
  method: 'GET',
  namespace: 'visibilityGroups',
});
