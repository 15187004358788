import type { ErrorToastType } from 'api/endpoints/intel/intel.types';
import type { ApiErrorType } from 'lib/api';

const errorInterceptor = (
  error: ApiErrorType,
  { title, message, actionType, retryAction, errorNotifier }: ErrorToastType,
) => {
  if (errorNotifier) {
    return errorNotifier({ title, message, actionType });
  }

  return error;
};

export default errorInterceptor;
