import { shallowEqual, useSelector } from 'react-redux';

import type { RootState } from 'store/store.types';

type UseCardSelections = {
  boardsSelectedCount: number;
  totalSelectedCount: number;
  selectedCards?: Set<number> | undefined;
};

export default function useCardSelections(cards?: Array<number>) {
  const { boardsSelectedCount, totalSelectedCount, selectedCards } =
    useSelector<RootState, UseCardSelections>((state) => {
      if (!state) {
        return { boardsSelectedCount: 0, totalSelectedCount: 0 };
      }
      const {
        cards: { selectedCards },
      } = state || {};
      if (!selectedCards || !cards?.length) {
        return {
          boardsSelectedCount: 0,
          totalSelectedCount: selectedCards?.size ?? 0,
          selectedCards,
        };
      }
      const intersection = new Set(
        [...cards].filter((x) => selectedCards.has(x)),
      );
      return {
        boardsSelectedCount: intersection.size,
        totalSelectedCount: selectedCards.size,
        selectedCards,
      };
    }, shallowEqual);
  const mixed = Boolean(
    boardsSelectedCount && boardsSelectedCount !== cards?.length,
  );
  const checked =
    Boolean(boardsSelectedCount && boardsSelectedCount === cards?.length) ??
    false;

  return {
    boardsSelectedCount,
    mixed,
    checked,
    totalSelectedCount,
    selectedCards,
  };
}
