const formulaKeyMap: Record<string, string> = {
  'Opportunities Last 6 Months': 'SFDC_LATEST_OPPORTUNITIES',
  'Closed Opportunities Last 6 Months': 'SFDC_WINS',
};
const getFormualaKeyFromName = (name: string) =>
  formulaKeyMap?.[name] || name.toUpperCase();

// Map out instance keys in company data to compare with the key provided by the formula
export const normalizeCompanySFDCData = (opportunityCondition: any) =>
  Object.keys(opportunityCondition).reduce((acc, key) => {
    const { title } = opportunityCondition[key];

    acc[getFormualaKeyFromName(key)] = {
      name: key,
      title,
    };

    return acc;
  }, {} as Record<string, { name: string; title: string }>);
