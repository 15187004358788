import {
  Box,
  Button,
  Modal,
  Text,
  tokens,
  useResponsive,
  PhosphorIcon,
} from '@kluein/klue-ui';
import {
  type ReactNode,
  forwardRef,
  Suspense,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { KlueCard } from 'components/common/klueCard';
import { useCardInteractionLog } from 'components/common/klueCard/hooks';
import { useAuth } from 'contexts/auth';
import { useRedirect } from 'hooks/use-redirect';
import { AnalyticsAction } from 'lib/analytics';
import TEST_IDS from 'test-ids';
import TRACKING_IDS from 'tracking-ids';

import { KlueSpinner } from '../../../klue-spinner';
import { SingleCardViewerFooter } from '../single-card-viewer-footer';

import type { SingleCardViewerProps } from './SingleCardViewer.types';

const ModalContent = forwardRef(
  (
    {
      children,
    }: {
      children: ReactNode;
    },
    ref?: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { maxWidth } = useResponsive();

    return (
      <Modal.Content
        align="center"
        pad={{
          horizontal: 'xxxlarge',
          bottom: 'xxlarge',
        }}
        height={maxWidth.medium ? '100vh' : 'auto'}
        ref={ref}
        data-test-id={TEST_IDS.cardViewer.singleCardViewer.modal}
      >
        {children}
      </Modal.Content>
    );
  },
);

function ErrorContent({ onClose }: Pick<SingleCardViewerProps, 'onClose'>) {
  const { t } = useTranslation(['Common']);

  return (
    <ModalContent>
      <Box
        gap="medium"
        justify="center"
        align="center"
        height="large"
        data-test-id={TEST_IDS.cardViewer.singleCardViewer.error}
      >
        <Text size="large">{t(`Common:cardViewer.failed`)}</Text>

        <Button variant="flat-outlined" onClick={onClose} size="small">
          {t(`Common:actions.clickToClose`)}
        </Button>
      </Box>
    </ModalContent>
  );
}

function LoadingContent() {
  return (
    <ModalContent>
      <Box
        height={'large'}
        align="center"
        justify="center"
        data-test-id={TEST_IDS.cardViewer.singleCardViewer.loading}
      >
        <KlueSpinner />
      </Box>
    </ModalContent>
  );
}

export default function SingleCardViewer(props: SingleCardViewerProps) {
  const { maxWidth } = useResponsive();
  const { onClose, profile, card, loadFailed, rank } = props;

  const modalContentRef = useRef<HTMLDivElement | null>(null);
  const { isCurator } = useAuth();
  const { redirectToCardEditor } = useRedirect();
  const { t } = useTranslation();
  const logCardInteraction = useCardInteractionLog();

  const cardId = card?.id;
  const profileId = profile?.id;
  const isLoading = (!cardId || !profileId) && !loadFailed;
  const navigate = useNavigate();
  const previousCard = () => {
    // Assumes that the only location changes are from card to card
    // That works for now, but if any other ways for location to change are introduced
    // we'll need to revisit this (and the locationKeys solution below)
    navigate(-1);
  };

  const location = useLocation();
  const [locationKeys, setLocationKeys] = useState([] as string[]);
  useEffect(() => {
    modalContentRef.current?.scrollTo?.(0, 0);
    setLocationKeys((locationKeys) => {
      const index = locationKeys.indexOf(location.key);
      if (index !== -1) {
        return locationKeys.slice(0, index + 1);
      }
      return locationKeys.concat(location.key);
    });
  }, [location]);

  return (
    <Modal
      onClose={onClose}
      showCloseButton={false}
      width={maxWidth.medium ? 'auto' : 'xxxlarge'}
      testId={TEST_IDS.cardViewer.singleCardViewer.container}
      full={maxWidth.medium}
      background={tokens.color.neutral.white.main}
    >
      <Box elevation="xlarge" style={{ position: 'relative' }}>
        {loadFailed ? (
          <ErrorContent onClose={onClose} />
        ) : (
          <>
            {isLoading ? (
              <LoadingContent />
            ) : (
              <>
                <Box
                  pad={{ horizontal: 'large' }}
                  height="small"
                  justify="between"
                  align="center"
                  direction="row"
                  gap="large"
                  flex={{ shrink: 0 }}
                >
                  {locationKeys.length > 1 && (
                    <Box flex={false}>
                      <Button
                        variant="flat"
                        size="small"
                        leftSlot={
                          <PhosphorIcon.CaretLeft
                            size={tokens.iconSize.xxsmall}
                          />
                        }
                        label={t('Common:back')}
                        data-test-id={
                          TEST_IDS.cardViewer.singleCardViewer.backButton
                        }
                        data-tracking-id={
                          TRACKING_IDS.global.cardViewer.backButton
                        }
                        onClick={previousCard}
                      />
                    </Box>
                  )}
                  <Box
                    fill="horizontal"
                    justify="end"
                    direction="row"
                    gap="small"
                    align="center"
                  >
                    {isCurator && (
                      <Button
                        variant="flat"
                        size="small"
                        leftSlot={<PhosphorIcon.PencilSimple />}
                        label={t('Common:actions.edit')}
                        data-test-id={
                          TEST_IDS.cardViewer.singleCardViewer.editButton
                        }
                        data-tracking-id={
                          TRACKING_IDS.global.cardViewer.editButton
                        }
                        onClick={
                          profileId && cardId
                            ? () => {
                                logCardInteraction({
                                  cardId,
                                  action: AnalyticsAction.edit,
                                  label: 'toolbar',
                                  rank,
                                });
                                redirectToCardEditor({ profileId, cardId });
                              }
                            : undefined
                        }
                      />
                    )}
                    <Button
                      variant="flat-outlined"
                      size="small"
                      onClick={onClose}
                      icon={<PhosphorIcon.X />}
                      data-test-id={
                        TEST_IDS.cardViewer.singleCardViewer.closeButton
                      }
                      data-tracking-id={
                        TRACKING_IDS.global.cardViewer.closeButton
                      }
                    />
                  </Box>
                </Box>
                <ModalContent ref={modalContentRef}>
                  <Suspense fallback={<div />}>
                    <KlueCard
                      cardData={card}
                      rivalName={profile?.name}
                      boardName={card?.board.name}
                      isLoading={Boolean(card)}
                      isWide
                      isCardViewerContent
                      showCardSentiment
                      rank={rank}
                    />
                  </Suspense>
                </ModalContent>
                <Box
                  height="64px"
                  width="98%"
                  background="linear-gradient(to top, white, 90%, transparent)"
                  style={{
                    position: 'absolute',
                    bottom: '45px',
                    left: 0,
                    zIndex: 0,
                  }}
                />
                <SingleCardViewerFooter {...props} />
              </>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
}
