import { useClickListener } from '@kluein/klue-ui';
import { createRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from 'contexts/auth';
import { getCardIdFromTarget } from 'lib/utils';

type onClickParamType = (id: string) => void;

export default function useCardClick(onClick: onClickParamType) {
  const containerRef = createRef<HTMLDivElement>();
  const { isNewCardEditorEnabled } = useAuth();

  const location = useLocation();

  const isEditing =
    location.pathname.includes('/edit/card/') &&
    !location.pathname.endsWith('/new');
  const isCreating = location.pathname.endsWith('/edit/card/new');
  const isCardEditorActive =
    isNewCardEditorEnabled && (isEditing || isCreating);

  const handleCardLinks = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLAnchorElement;
      const id = getCardIdFromTarget(target);

      if (isCardEditorActive) {
        return;
      }

      if (id) {
        e.stopPropagation();
        e.preventDefault();

        onClick(id.toString());
      }
    },
    [isCardEditorActive, onClick],
  );

  useClickListener(null, [{ selector: 'a', onClick: handleCardLinks }]);

  return {
    ref: containerRef,
  };
}
