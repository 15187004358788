import { KlueUIProvider } from '@kluein/klue-ui';

import ProvideAuth from 'contexts/auth/ProvideAuth';
import { AppUIProvider } from 'contexts/ui';
import { BreadcrumbProvider } from 'contexts/ui/breadcrumb-context';
import { EmbeddedProvider } from 'contexts/ui/embedded-context';
import { AnalyticsProvider } from 'lib/analytics';

import { ReportLoadingProvider } from './reports/loadingContext';
import { BulkEditProvider } from './ui/bulk-edit-context';
import { SearchProvider } from './ui/search-context';

import type { PropsWithChildren, ElementType } from 'react';

const composeProviders = (...providers: ElementType[]): ElementType => {
  return providers.reduce(
    (AccumulatedProviders: ElementType, CurrentProvider: ElementType) =>
      (props: PropsWithChildren<object>) =>
        (
          <AccumulatedProviders {...props}>
            <CurrentProvider {...props}>{props.children}</CurrentProvider>
          </AccumulatedProviders>
        ),
    (props: PropsWithChildren<object>) => <>{props.children}</>,
  );
};

const providers = [
  KlueUIProvider,
  AppUIProvider,
  ProvideAuth,
  BreadcrumbProvider,
  AnalyticsProvider,
  BulkEditProvider,
  EmbeddedProvider,
  SearchProvider,
  ReportLoadingProvider,
];

export const AppContextProvider = composeProviders(...providers);
