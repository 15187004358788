import { setEndpoint } from 'api/configure';

import type { SearchReturnType, SearchQueryType } from './search.types';

export const [fetchSearch] = setEndpoint<
  any,
  any,
  SearchQueryType,
  SearchReturnType
>({
  path: '/search.json',
  id: 'fetchSearch',
  method: 'GET',
  namespace: 'search',
});
