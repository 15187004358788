export const SKIP_ERRORS = ['AbortError'];

const ErrorsDictionary = {
  500: {
    status: 500,
    title: 'Alerts:errors.genericError.title',
    actionType: 'reload',
    message: 'Alerts:errors.genericError.action',
  },
  default: {
    title: 'Alerts:errors.defaultError.title',
    actionType: 'reset',
    message: 'Alerts:errors.defaultError.action',
  },
};

export default ErrorsDictionary;
