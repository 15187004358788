import {
  type PropsWithChildren,
  createContext,
  useContext,
  type FC,
} from 'react';
import { useLocation } from 'react-router-dom';

const EMBED_PATHS = [
  /^\/profile\/\d+\/battlecard\/embed/,
  /^\/card\/embed\/\d+/,
];

export const EmbeddedContext = createContext<boolean | undefined>(undefined);

function checkEmbedded(pathname = window.location.pathname) {
  return EMBED_PATHS.some((ex) => ex.test(pathname));
}

export const EmbeddedProvider: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();
  const isEmbedded = checkEmbedded(pathname);
  return <EmbeddedContext.Provider value={isEmbedded} children={children} />;
};

export const useEmbedded = () => {
  const isEmbedded = useContext(EmbeddedContext);
  if (isEmbedded === undefined) {
    throw new Error('useEmbedded must be used within EmbeddedProvider');
  }
  return isEmbedded;
};
