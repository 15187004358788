import { normalize as minimalNormalize } from 'minimal-normalize';
import { normalize, type Schema } from 'normalizr';

import type { ComposedReturnType } from 'lib/api';

export const normalizeDataBySchema =
  (schema: Schema<any>) => (result: ComposedReturnType<any>) => {
    return {
      ...result,
      data: normalize(result.data, schema),
    };
  };

export const normalizeObject =
  (key?: string) => (result: ComposedReturnType<any>) => {
    return {
      ...result,
      data: minimalNormalize(key ? result.data[key] : result.data, 'id'),
    };
  };
