export type HeadersType = { [key: string]: string };

export type InitType = RequestInit | undefined;

export type FetchOptionsType = [RequestInfo, InitType?];

export type RetryStatus = Array<string | number>;

export type BeforeHookType = (options: FetchOptionsType) => FetchOptionsType;
export type AfterHookType<T> = (data: T) => any;
export type ErrorHandlerHookType = (e: ApiErrorType) => ApiErrorType;

export type GetApiLayerOptionsType<ReturnType = any> = {
  baseUrl: string;
  contentType: string;
  headers?: HeadersType;
  interceptor?: {
    before?: Array<BeforeHookType>;
    after?: Array<AfterHookType<ComposedReturnType<ReturnType>>>;
    errorHandler?: Array<ErrorHandlerHookType>;
  };
  retryTimes?: number;
  retryStatus?: RetryStatus;
};

export type GetApiLayerOptionsByEndPointType<ReturnType = any> = {
  baseUrl?: string;
  contentType?: string;
  headers?: HeadersType;
  interceptor?: {
    before?: Array<BeforeHookType>;
    after?: Array<AfterHookType<ComposedReturnType<ReturnType>>>;
    errorHandler?: Array<ErrorHandlerHookType>;
  };
  retryTimes?: number;
  retryStatus?: RetryStatus;
};

export type CustomFetchOptionsType<ReturnType = any> =
  GetApiLayerOptionsType<ReturnType> & {
    retryTimes: number;
    retryStatus: Array<string | number>;
  };

export enum ImportanceEnum {
  high = 'high',
  low = 'low',
  auto = 'auto',
}

export type SetEndPointOptionsType<ReturnType> = {
  namespace?: string;
  id: string;
  path: string;
  method: string;
  importance?: ImportanceEnum;
  ignoreGlobalInterceptors?: boolean;
  globals?: GetApiLayerOptionsByEndPointType<ReturnType>;
};

export type SetEndPointExtendedOptionsType<ReturnType> = {
  globals?: Omit<
    GetApiLayerOptionsByEndPointType<ReturnType>,
    'baseUrl' | 'contentType' | 'retryTimes' | 'retryStatus'
  >;
};

export type InterceptorsKeyType = 'after' | 'before' | 'errorHandler';

export type GetInterceptorsType<ReturnType> = {
  interceptorsKey: InterceptorsKeyType;
  globalOptions: CustomFetchOptionsType;
  endPointOptions: SetEndPointOptionsType<ReturnType>;
};

export type ComposedReturnType<ReturnType> = {
  data: ReturnType;
  headers: Headers;
  status: number;
};

export type EndPointType<PathType, ParamsType, QueryType, ReturnType> =
  (options?: {
    path?: PathType;
    params?: ParamsType;
    query?: QueryType;
  }) => Promise<ComposedReturnType<ReturnType>>;

export type ExtendedEndPointType<PathType, ParamsType, QueryType, ReturnType> =
  (
    extendedEndPointOptions: SetEndPointExtendedOptionsType<ReturnType>,
  ) => SetEndpointReturnType<PathType, ParamsType, QueryType, ReturnType>;

export type SetEndpointReturnType<PathType, ParamsType, QueryType, ReturnType> =
  [
    EndPointType<PathType, ParamsType, QueryType, ReturnType>,
    ExtendedEndPointType<PathType, ParamsType, QueryType, ReturnType>,
  ];

export type SetEndpointType<PathType, ParamsType, QueryType, ReturnType> = (
  endPointOptions: SetEndPointOptionsType<ReturnType>,
) => SetEndpointReturnType<PathType, ParamsType, QueryType, ReturnType>;

export type ApiErrorType = {
  status?: string | number;
  code?: string;
} & Record<string, unknown>;
