import {
  State,
  Topic,
  ContentType,
  Priority,
  Source,
  Sort,
  ProcessedByFilters,
} from 'api/endpoints/intel/intel.enums';

import type { IntelCountersType } from 'api/endpoints/intel/intel.types';

export enum DateRangeFilters {
  allTime = 'allTime',
  today = 'today',
  yesterday = 'yesterday',
  last2Days = 'last2Days',
  last7Days = 'last7Days',
  last30Days = 'last30Days',
  last90Days = 'last90Days',
  customDateRange = 'customDateRange',
}

export enum FilterSort {
  NAME = 'name',
  COUNT = 'count',
  LABEL = 'label',
}

export const TRIAGE = 'triage';

export const BULK_EDIT = 'bulkEdit';

export const CONTENT_TYPES: ContentType[] = Object.values(ContentType);

export const TOPICS_LIST: Topic[] = Object.values(Topic);

export const SOURCE_TYPES: Source[] = Object.values(Source);

export const SORT_FILTERS: Sort[] = Object.values(Sort);

export const DATE_RANGE_FILTERS = [
  DateRangeFilters.allTime,
  DateRangeFilters.today,
  DateRangeFilters.yesterday,
  DateRangeFilters.last2Days,
  DateRangeFilters.last7Days,
  DateRangeFilters.last30Days,
  DateRangeFilters.last90Days,
];

export const PRIORITIES_FILTER: Priority[] = Object.values(Priority);

export const STATE_LIST: State[] = Object.values(State);

export const DEFAULT_STATES_IF_EMPTY = [
  State.Unprocessed,
  State.Working,
  State.Archived,
];

export const STATES_ENUM = STATE_LIST.reduce(
  (acc, curr) => ({ ...acc, [curr]: curr }),
  {},
) as Record<State, State>;

export const PRIORITIES_LIST: Priority[] = Object.values(Priority);

export const PRIORITIES_ENUM = PRIORITIES_LIST.reduce(
  (acc, curr) => ({ ...acc, [curr]: curr }),
  {},
) as Record<Priority, Priority>;

export const ALL_INTEL_STATES = `${STATES_ENUM.unprocessed},${STATES_ENUM.working},${STATES_ENUM.archived}`;

export const PRESET_TIME_RANGE_MAP: Record<string, number> = {
  today: 0,
  yesterday: 1,
  last2Days: 1,
  last7Days: 7,
  last30Days: 30,
  last90Days: 90,
};

const normalizedTopics: Record<string, Topic | Topic[]> = {};
TOPICS_LIST.reduce((acc, current, currentIndex): Record<string, Topic> => {
  const [topicKey] = TOPICS_LIST[currentIndex].split('__');
  normalizedTopics[topicKey] = normalizedTopics[topicKey]?.length
    ? [TOPICS_LIST[currentIndex], normalizedTopics[topicKey]].flat()
    : current;

  acc = normalizedTopics;

  return acc;
}, {});

export const NORMALIZED_TOPICS_LIST = Object.values(normalizedTopics);

export const FETCH_PRESETS_COUNTERS_INTERVAL = 600000;

export const FILTERS_TO_COUNTER_KEY: Record<string, keyof IntelCountersType> = {
  states: 'stateCount',
  topics: 'topicsCount',
  sources: 'sourceCount',
  priorities: 'priorityCount',
  contentTypes: 'contentTypesCount',
  dateRange: 'createdAtCount',
  published: 'publishedCount',
  rivalIds: 'rivalCount',
};

export const ALL_TIME_PRESET_KEY = 'allTime';

export const TRIAGE_LIST_SLICE_LIMIT = 2;

export const dateRangeFilterKeys = [
  'now-90d/d',
  'now-30d/d',
  'now-7d/d',
  'now-2d/d',
  'now-1d/d',
  'now-0d/d',
];

export const filterTypes = {
  Priorities: 'Priorities',
  Sources: 'Sources',
  Topics: 'Topics',
  Types: 'Types',
};

export const PROCESSED_BY_FILTERS_TYPES: ProcessedByFilters[] =
  Object.values(ProcessedByFilters);
