import {
  Button,
  getEdgeSizeFromTheme,
  tokens,
  type ButtonProps,
  PhosphorIcon,
} from '@kluein/klue-ui';
import omit from 'lodash/omit';

import TEST_IDS from 'test-ids';

const paddingVertical = getEdgeSizeFromTheme('xxsmall');
const paddingHorizontal = getEdgeSizeFromTheme('xsmall');

const activeStyles = {
  backgroundColor: tokens.color.highlight.main,
  padding: `${paddingVertical}px ${paddingHorizontal}px`,
  margin: `-${paddingVertical}px 0`,
  borderRadius: paddingHorizontal,
  zIndex: 1,
};

type UserMenuLinkProps = ButtonProps & {
  isExternalLink?: boolean;
  active?: boolean;
};
const UserMenuLink = (props: UserMenuLinkProps) => {
  const { isExternalLink, active } = props;

  return (
    <Button
      size="large"
      variant="plain"
      style={active ? activeStyles : undefined}
      rightSlot={
        isExternalLink ? (
          <PhosphorIcon.ArrowSquareOut
            size={tokens.iconSize.xsmall}
            weight="bold"
          />
        ) : undefined
      }
      target={isExternalLink ? '_blank' : undefined}
      data-test-id={TEST_IDS.appNavBar.userMenu.actionButton}
      {...omit(props, 'isExternalLink')}
    />
  );
};

export default UserMenuLink;
