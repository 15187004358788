import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import omit from 'lodash/omit';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  useQueryParams,
  StringParam,
  NumberParam,
  BooleanParam,
} from 'use-query-params';

import { MenuItem } from 'api/endpoints/intel/intel.enums';
import { DEFAULT_STATES_IF_EMPTY } from 'pages/alerts/constants';
import { normalizeFiltersFromURL } from 'pages/alerts/utils';

import type { FiltersFromURLType } from './useFiltersFromURL.types';
import type { IntelQueryParamsType } from 'api/endpoints/intel/intel.types';
import type { Dispatch } from 'store/store.types';

const initialQuery = {
  action: StringParam,
  states: StringParam,
  priorities: StringParam,
  rivalIds: StringParam,
  boardGroups: StringParam,
  contentTypes: StringParam,
  topics: StringParam,
  sources: StringParam,
  q: StringParam,
  offset: NumberParam,
  size: NumberParam,
  sort: StringParam,
  createdAfter: StringParam,
  createdBefore: StringParam,
  dateRange: StringParam,
  aggregate: BooleanParam,
  published: StringParam,
  actionedByMe: StringParam,
  view: StringParam,
  listDensity: StringParam,
  intelIds: StringParam,
  actionedBy: StringParam,
};

const CHECKBOX_FILTERS = [
  'states',
  'priorities',
  'rivalIds',
  'boardGroups',
  'topics',
  'types',
  'contentTypes',
  'sources',
  'intelIds',
];
const FIELDS_TO_OMIT_FROM_API = ['dateRange', 'boardGroups', 'listDensity'];
// isRelatedAlertsEnabled feature flag is not required for the alerts page,
// removing it from the URL params but retaining it in the API params
const DEFAULT_RELATED_ALERTS_ENABLED = true;

const safeguardApiParams = (params: IntelQueryParamsType) => {
  const safecheckedParams = { ...params };

  CHECKBOX_FILTERS.forEach((key) => {
    isString((safecheckedParams as any)[key]) &&
      ((safecheckedParams as any)[key] = (safecheckedParams as any)[key]?.split(
        ',',
      ));
  });

  return safecheckedParams;
};

const QueryParamConfig = {
  ...initialQuery,
};

export const useFiltersFromURL = () => {
  const dispatch = useDispatch<Dispatch>();
  const [filtersFromURL, setQuery] = useQueryParams(QueryParamConfig) as [
    FiltersFromURLType,
    ReturnType<typeof useQueryParams>[1],
  ];

  const { view: currentView } = filtersFromURL;

  const setFiltersOnURL = useCallback(
    (
      newURLParams: {
        add?: FiltersFromURLType;
        remove?: FiltersFromURLType;
        forceRehydrate?: boolean;
      },
      options: {
        shouldFetch?: boolean;
        shouldRehydrate?: boolean;
        forceRehydrate?: boolean;
      } = {},
    ) => {
      const {
        shouldFetch = false,
        shouldRehydrate = false,
        forceRehydrate = false,
      } = options;

      const normalizedFiltersFromURL = normalizeFiltersFromURL(filtersFromURL);
      const normalizedNewURLParams: {
        add?: Partial<FiltersFromURLType>;
        remove?: Partial<FiltersFromURLType>;
      } = {
        add: normalizeFiltersFromURL(newURLParams.add || {}),
        remove: newURLParams.remove || {},
      };

      const newParams: Partial<FiltersFromURLType> = shouldRehydrate
        ? { ...normalizedNewURLParams.add }
        : { ...normalizedFiltersFromURL };

      const processParamsByAction = (action: 'add' | 'remove') => {
        const objectToProcess = normalizedNewURLParams[action];

        if (isEmpty(objectToProcess)) return;

        Object.keys(objectToProcess || {}).forEach((key) => {
          const valueIsCheckbox = CHECKBOX_FILTERS.includes(key);
          const valueToProcess = (normalizedNewURLParams[action] as any)[key];

          if (valueIsCheckbox) {
            const valuesFromURL = normalizedFiltersFromURL[key];
            const arrayFromURL = valuesFromURL
              ? String(valuesFromURL).split(',')
              : [];
            const arrayFromNewQuery = newURLParams
              ? String(valueToProcess).split(',')
              : [];

            const valuesSet = new Set(shouldRehydrate ? [] : arrayFromURL);

            const setAction = action === 'remove' ? 'delete' : action;
            arrayFromNewQuery.forEach((value: any) =>
              valuesSet[setAction](value),
            );

            const shouldUncheckBoardGroups =
              setAction === 'delete' && key === 'rivalIds';
            if (shouldUncheckBoardGroups) {
              newParams.boardGroups = undefined;
            }

            (newParams as any)[key] =
              valuesSet.size > 0 ? [...valuesSet] : undefined;
          } else {
            const isRemoving = action === 'remove';
            (newParams as any)[key] = isRemoving ? undefined : valueToProcess;
          }
        });
      };

      processParamsByAction('add');
      processParamsByAction('remove');

      const willEnableTriageMode =
        normalizedFiltersFromURL.action === 'triage' ||
        normalizedNewURLParams.add?.action === 'triage';

      if (!willEnableTriageMode) {
        // if we're not in triage mode
        // foreach of the checkbox filters, check if they are a string value in newParams,
        // and if so, coerce the value from a comma-separated string to an array
        CHECKBOX_FILTERS.forEach((key) => {
          isString((newParams as any)[key]) &&
            ((newParams as any)[key] = (newParams as any)[key]?.split(','));
        });
      }

      const apiFilters = safeguardApiParams(
        omit(
          {
            ...newParams,
            states: newParams.states ?? DEFAULT_STATES_IF_EMPTY,
            relatedAlerts: DEFAULT_RELATED_ALERTS_ENABLED,
          } as IntelQueryParamsType,
          FIELDS_TO_OMIT_FROM_API,
        ),
      );

      dispatch.intelFilters?.setFilters(apiFilters, shouldRehydrate);
      // To allow empty search to work (consistent with v1 behaviour), adding view param if q is present
      (!!newParams.q || newParams.q === '') &&
        Object.assign(newParams, { view: MenuItem.Search });

      setQuery(
        {
          ...newParams,
        },
        shouldRehydrate ? 'push' : 'pushIn',
      );

      if (shouldFetch && currentView) {
        dispatch.intelCards.fetchIntelList({
          filters: apiFilters,
          pagination: {
            offset: 0,
          },
          rehydrate: shouldRehydrate || forceRehydrate,
          isInTriageMode: willEnableTriageMode,
        });
      }
    },
    [
      filtersFromURL,
      dispatch.intelFilters,
      dispatch.intelCards,
      setQuery,
      currentView,
    ],
  );

  return { filtersFromURL, setFiltersOnURL };
};
