import { useClickListener } from '@kluein/klue-ui';
import { useNavigate } from 'react-router-dom';

import { isInternalUrl } from 'lib/utils';

export default function useInternalClick() {
  const navigate = useNavigate();
  const handleInternalLinks = (e: MouseEvent) => {
    const link = e.target as HTMLAnchorElement;
    try {
      const url = new URL(link.getAttribute('href') || '');
      if (isInternalUrl(url.toString())) {
        e.preventDefault();
        navigate({ pathname: url.pathname, search: url.search });
      }
    } catch (error) {
      return false;
    }
  };

  useClickListener(null, [{ selector: 'a', onClick: handleInternalLinks }]);
}
