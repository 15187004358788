import { setEndpoint } from 'api/configure';

import type {
  IntelDigestItemType,
  EmailDigestsResponseType,
  EmailDigestParamsType,
} from 'api/endpoints/intel-digest/intel-digest.types';

export const [fetchCurrentDigest] = setEndpoint<
  any,
  any,
  any,
  IntelDigestItemType
>({
  path: '/email_digests.json',
  id: 'fetchCurrentDigest',
  method: 'POST',
  namespace: 'intelDigest',
});

export const [fetchDigests] = setEndpoint<
  any,
  any,
  EmailDigestParamsType,
  EmailDigestsResponseType
>({
  path: '/email_digests.json',
  id: 'fetchEmailDigests',
  method: 'GET',
  namespace: 'intelDigest',
});
