import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';

import { fetchSearch } from 'api/endpoints/search';
import { fetchSearchRivals } from 'api/endpoints/search-rivals';
import { addVisibilityGroupIdToQuery } from 'store/utils';
import { parseSearchCardsContent } from 'worker';

import {
  type FilterType as ModelFilterType,
  type FiltersType,
  SEARCH_RESULTS_PAGE_SIZE,
  Sort,
  type DoSearchActions,
  type StateType,
  loadingResults,
} from './search.model';

import type {
  CardType,
  RivalType,
  SearchResultCardType,
  TagType,
} from 'api/api.types';
import type { SearchRivalsQueryType } from 'api/endpoints/search-rivals/search-rivals.types';
import type {
  FieldCountersType,
  SearchQueryType,
} from 'api/endpoints/search.types';
import type { RootState } from 'store/store.types';

const SEARCH_DEBOUNCE = 750;

function isSameSearch({
  search: {
    searchTerms,
    lastUpdatedFilter,
    boardFilters: { selected: selectedBoardFilters },
    tagFilters: { selected: selectedTagFilters },
    prevSearch,
  },
}: RootState) {
  if (!prevSearch) {
    return false;
  }
  const {
    searchTerms: prevSearchTerms,
    lastUpdatedFilter: prevLastUpdatedFilter,
    selectedBoardFilters: prevSelectedBoardFilters,
    selectedTagFilters: prevSelectedTagFilters,
  } = prevSearch;
  if (searchTerms !== prevSearchTerms) {
    return false;
  }
  if (lastUpdatedFilter !== prevLastUpdatedFilter) {
    return false;
  }
  if (!isEqual(selectedBoardFilters, prevSelectedBoardFilters)) {
    return false;
  }
  if (!isEqual(selectedTagFilters, prevSelectedTagFilters)) {
    return false;
  }
  return true;
}

function shouldPersistBoardFilters({
  search: {
    searchTerms,
    lastUpdatedFilter,
    tagFilters: { selected: selectedTagFilters },
    prevSearch,
  },
}: RootState) {
  if (!prevSearch) {
    return false;
  }
  const {
    searchTerms: prevSearchTerms,
    lastUpdatedFilter: prevLastUpdatedFilter,
    selectedTagFilters: prevSelectedTagFilters,
  } = prevSearch;
  return (
    searchTerms === prevSearchTerms &&
    lastUpdatedFilter === prevLastUpdatedFilter &&
    isEqual(selectedTagFilters, prevSelectedTagFilters)
  );
}

export function getSearchOptionsFromState(state: RootState) {
  const searchQuery: SearchQueryType = {
    index: 'cards',
    size: SEARCH_RESULTS_PAGE_SIZE,
  };

  const searchTerms = state.search.searchTerms;
  if (searchTerms) {
    searchQuery.query = [searchTerms];
  }

  const lastUpdated = state.search.lastUpdatedFilter;
  if (lastUpdated) {
    searchQuery.updated_start = lastUpdated;
  }

  const selectedTags = state.search.tagFilters.selected;
  if (selectedTags?.size) {
    searchQuery.allTags = [...selectedTags.keys()].join();
  }

  const selectedBoards = state.search.boardFilters.selected;
  if (selectedBoards?.size) {
    searchQuery.rivals = Array.from(selectedBoards.keys()).join();
  }

  addVisibilityGroupIdToQuery(state, searchQuery);

  const countersFor = [];
  let supportingQuery: SearchQueryType | undefined;
  const isNewSearch = !isSameSearch(state);
  if (isNewSearch) {
    // We don't need to get counters if we're just loading more results (both tag & board filters should persist)
    countersFor.push('tags.id');
    if (!shouldPersistBoardFilters(state)) {
      // We dont need to get counters if we are just toggling board filters
      if (searchQuery.rivals) {
        supportingQuery = { ...searchQuery };
        supportingQuery.offset = 0;
        supportingQuery.size = 0;
        supportingQuery.countersFor = ['board.rival_id'];
        delete supportingQuery.rivals;
      } else {
        countersFor.push('board.rival_id');
      }
    }
  } else {
    searchQuery.offset = state.search.results.length;
  }

  if (countersFor.length) {
    searchQuery.countersFor = countersFor;
  }

  let rivalsQuery: SearchRivalsQueryType | undefined;
  const shouldIncludeRivals = Boolean(
    !searchQuery.allTags?.length &&
      !searchQuery.updated_start &&
      !searchQuery.rivals?.length,
  );
  if (isNewSearch && shouldIncludeRivals && searchQuery.query?.length) {
    rivalsQuery = {
      query: searchQuery.query[0],
    };
    addVisibilityGroupIdToQuery(state, rivalsQuery);
  }

  return {
    query: searchQuery,
    rivalsQuery,
    shouldIncludeRivals,
    supportingQuery,
    isNewSearch,
  };
}

async function _doSearch(
  state: RootState,
  { setPrevSearch, populate, populateSearchRivals, setError }: DoSearchActions,
) {
  try {
    const {
      query,
      rivalsQuery,
      supportingQuery,
      isNewSearch,
      shouldIncludeRivals,
    } = getSearchOptionsFromState(state);
    setPrevSearch();
    const search = fetchSearch({ query });
    const supportingSearch = supportingQuery
      ? fetchSearch({ query: supportingQuery })
      : Promise.resolve({
          data: {
            results: { field_counters: undefined },
          },
        });

    const rivalsSearch = rivalsQuery
      ? fetchSearchRivals({
          query: rivalsQuery,
        })
      : Promise.resolve({ data: shouldIncludeRivals ? undefined : [] });

    await Promise.all([search, supportingSearch, rivalsSearch]);

    const {
      data: {
        results: {
          nr_results: count,
          results: rawCards,
          field_counters: fieldCounters,
        },
      },
    } = await search;

    const {
      data: {
        results: { field_counters: supportingFieldCounters },
      },
    } = await supportingSearch;

    const { data: searchRivals } = await rivalsSearch;

    let cards = rawCards;
    if (rawCards.length) {
      cards = parseSearchResultCards(rawCards);
    }

    const { tagFilterCounts, rivalFilterCounts } = parseFieldCounters(
      fieldCounters,
      supportingFieldCounters,
    );

    if (searchRivals) {
      populateSearchRivals({ searchRivals });
    }
    populate({
      query,
      count,
      cards,
      tagFilterCounts,
      boardFilterCounts: rivalFilterCounts,
      isNewSearch,
    });
  } catch (error) {
    setError(true);
  }
}
export const doSearch = debounce(_doSearch, SEARCH_DEBOUNCE);

export function parseSearchResultCards(rawCards: SearchResultCardType[]) {
  // TODO: React 18 leftovers, fix this mess
  return parseSearchCardsContent(
    rawCards as unknown as CardType[],
  ) as unknown as SearchResultCardType[];
}

function isNameMatchingSearchTerms(name: string, searchTerms: string) {
  return name.toLowerCase().includes(searchTerms.toLowerCase());
}

type FilterType = ModelFilterType & { name: string; isSelected: boolean };

function sortAscendingByName(
  { name: nameA }: FilterType,
  { name: nameB }: FilterType,
) {
  return nameA.localeCompare(nameB);
}

function sortDescendingByCount(
  { count: countA }: FilterType,
  { count: countB }: FilterType,
) {
  return countB - countA;
}

export function setFilters(
  state: StateType,
  newFilters: Set<number>,
  type: 'board' | 'tag',
) {
  const filters = new Set<string>(state.filters);
  for (const filter of filters) {
    const [, filterId] = new RegExp(`${type}-(\\d+)`).exec(filter) || [];
    if (filterId && !newFilters.has(+filterId)) {
      filters.delete(filter);
    }
  }
  newFilters.forEach((id) => {
    const filter = `${type}-${id}`;
    if (!filters.has(filter)) {
      filters.add(`${type}-${id}`);
    }
  });
  return {
    ...state,
    ...loadingResults,
    [`${type}Filters`]: {
      ...state[`${type}Filters`],
      selected: new Set(newFilters),
    },
    filters,
  };
}

export function getFilters<E extends TagType | RivalType>(
  { byId: filtersById, selected, searchTerms, sort }: FiltersType,
  allEntitities: E[],
) {
  return allEntitities
    .reduce((acc, { id, name }) => {
      if (isNameMatchingSearchTerms(name, searchTerms)) {
        acc.push({
          id,
          count: filtersById.get(id)?.count || 0,
          name,
          isSelected: selected.has(id),
        });
      }
      return acc;
    }, [] as FilterType[])
    .sort(sort === Sort.NAME ? sortAscendingByName : sortDescendingByCount);
}

function parseTagFieldCounter(fieldCounters: FieldCountersType) {
  const { 'tags.id': tagIds } = fieldCounters;
  if (tagIds) {
    const tags = tagIds.reduce((acc, { key, doc_count: count }) => {
      const id = Number(key);
      acc.set(id, { id, count });
      return acc;
    }, new Map());

    return tags;
  }
}

function parseRivalFieldCounter(
  fieldCounters: FieldCountersType,
  supportingFieldCounters?: FieldCountersType,
) {
  const { 'board.rival_id': rivalIds } =
    supportingFieldCounters || fieldCounters;

  if (rivalIds) {
    const rivals = rivalIds.reduce((acc, { key, doc_count: count }) => {
      const id = Number(key);
      acc.set(id, { id, count });
      return acc;
    }, new Map());

    return rivals;
  }
}

export function parseFieldCounters(
  fieldCounters: FieldCountersType,
  supportingFieldCounters?: FieldCountersType,
) {
  const tagFilterCounts = parseTagFieldCounter(fieldCounters);
  const rivalFilterCounts = parseRivalFieldCounter(
    fieldCounters,
    supportingFieldCounters,
  );
  return {
    tagFilterCounts,
    rivalFilterCounts,
  };
}

type MergeResultsTypes = {
  prevResults: SearchResultCardType[];
  results: SearchResultCardType[];
};

export function mergeResults({ prevResults, results }: MergeResultsTypes) {
  const prevResultsMapped = prevResults.reduce((acc, cur) => {
    acc.set(cur.id, cur);
    return acc;
  }, new Map());

  const newResultsMapped = results.reduce((acc, cur) => {
    acc.set(cur.id, cur);
    return acc;
  }, new Map());

  const removeDuplicates = new Map([...prevResultsMapped, ...newResultsMapped]);

  return [...removeDuplicates.values()];
}
