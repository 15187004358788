import {
  Anchor,
  Avatar,
  Box,
  Text,
  tokens,
  type AvatarProps,
} from '@kluein/klue-ui';
import { useEffect, type ReactNode } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { APP_V1_BASEURL } from 'lib/urls';
import store from 'store';
import TEST_IDS from 'test-ids';

import type { UserType } from 'api/api.types';
import type { Dispatch, RootState } from 'store/store.types';

type UserAvatarProps = {
  email?: UserType['email'];
  name?: UserType['name'];
  userId: UserType['id'];
  showName?: boolean;
  linkTo?: 'email' | 'profile';
} & AvatarProps;

const UserAvatar = ({
  email,
  linkTo,
  name,
  src,
  showName = false,
  userId,
  ...rest
}: UserAvatarProps) => {
  ({ email, name, src } = useSelector<
    RootState,
    Partial<Pick<UserType, 'email' | 'name'> & { src: UserType['image'] }>
  >((state) => {
    const userInStore = store.select.users.byId(state).get(userId?.toString());
    const {
      email: storeEmail,
      name: storeName,
      image: storeSrc,
    } = userInStore || {};
    return {
      email: email || storeEmail,
      name: name || storeName,
      src: src || storeSrc,
    };
  }, shallowEqual));
  const {
    users: { loadOrFetchUserById },
  } = useDispatch<Dispatch>();

  useEffect(() => {
    if (!(name && src)) {
      loadOrFetchUserById(userId);
    }
  }, [loadOrFetchUserById, name, src, userId]);

  if (!name || !src) {
    return null;
  }

  const userInitials = name
    .match(/(^\S\S?|\b\S)?/g)
    ?.join('')
    ?.match(/(^\S|\S$)?/g)
    ?.join('')
    ?.toUpperCase();

  const AvatarComponent = (
    <Avatar
      border={{ color: tokens.color.neutral.black.main, size: 'small' }}
      src={src}
      title={name}
      flex={false}
      data-test-id={TEST_IDS.userAvatar.avatar}
      {...rest}
    >
      <Text fontWeight="semibold">{userInitials}</Text>
    </Avatar>
  );

  const LinkWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
    switch (linkTo) {
      case 'profile':
        return (
          <Anchor
            data-test-id={TEST_IDS.userAvatar.anchor.profile}
            children={children}
            href={`${APP_V1_BASEURL}/users/${userId}`}
          />
        );
      case 'email':
        return (
          <Anchor
            data-test-id={TEST_IDS.userAvatar.anchor.email}
            children={children}
            href={`mailto:${email}`}
          />
        );
      default:
        return showName ? (
          <Text fontWeight="semibold">{name}</Text>
        ) : (
          <>{children}</>
        );
    }
  };

  return showName ? (
    <Box
      direction="row"
      align="center"
      flex={false}
      gap="xsmall"
      data-test-id={TEST_IDS.userAvatar.box}
    >
      {AvatarComponent}
      <LinkWrapper>{name}</LinkWrapper>
    </Box>
  ) : (
    <LinkWrapper>{AvatarComponent}</LinkWrapper>
  );
};

export default UserAvatar;
