import {
  Box,
  Grid,
  tokens,
  type GridProps,
  type BoxProps,
  useResponsive,
} from '@kluein/klue-ui';

import TEST_IDS from 'test-ids';

type NavBarProps = GridProps & Pick<BoxProps, 'background' | 'border'>;

const NavBar: React.FC<NavBarProps> = (props) => {
  const { maxWidth } = useResponsive();

  return (
    <Box
      background={
        props.background || { color: tokens.color.neutral.white.main }
      }
      border={props.border || { color: 'transparent' }}
      className="navbar"
      data-test-id={TEST_IDS.layout.navBar.container}
      flex={{ grow: 0, shrink: 0 }}
      id={TEST_IDS.layout.navBar.container}
    >
      <Grid
        align="stretch"
        aria-label="primary"
        areas={
          props.areas ?? [
            { name: 'col1', start: [0, 0], end: [0, 0] },
            { name: 'col2', start: [1, 0], end: [1, 0] },
            { name: 'col3', start: [2, 0], end: [2, 0] },
          ]
        }
        columns={[
          [
            maxWidth.medium ? 'flex' : 'auto',
            maxWidth.medium ? 'auto' : 'flex',
          ],
          ['large', 'flex'],
          ['large', 'flex'],
        ]}
        role="navigation"
        rows={props.rows ?? ['auto']}
      >
        {props.children}
      </Grid>
    </Box>
  );
};

export default NavBar;
