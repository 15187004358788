import { createModel } from '@rematch/core';
import compact from 'lodash/compact';

import { mergeArrayValues } from 'store/utils';

import type { SearchRivalType } from 'api/endpoints/search-rivals/search-rivals.types';
import type { RootModel } from 'store/models';

export type StateType = {
  byId: Map<number, SearchRivalType>;
  allIds: Set<number>;
};

export type PopulatePayload = { searchRivals: SearchRivalType[] };

export const initialState: StateType = {
  byId: new Map(),
  allIds: new Set(),
};

export const searchRivals = createModel<RootModel>()({
  state: initialState,
  reducers: {
    populate: (state, { searchRivals }: PopulatePayload) => {
      return {
        ...state,
        byId: mergeArrayValues(new Map(), searchRivals, 'id'),
        allIds: new Set(searchRivals.map(({ id }) => id)),
      };
    },
  },
  selectors: (slice, createSelector) => ({
    byId() {
      return slice(({ byId }) => byId);
    },
    allIds() {
      return slice(({ allIds }) => allIds);
    },
    all() {
      return createSelector(
        this.allIds as any,
        this.byId as any,
        (allIds: StateType['allIds'], byId: StateType['byId']) =>
          compact(Array.from(allIds).map((id) => byId.get(id))),
      );
    },
  }),
});
