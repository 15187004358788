import isEqual from 'lodash/isEqual';
import without from 'lodash/without';
import {
  decodeDelimitedNumericArray,
  encodeDelimitedNumericArray,
} from 'use-query-params';

export const NumericCommaSetParam = {
  encode: (set: Set<number> | null | undefined) =>
    encodeDelimitedNumericArray(set ? [...set?.keys()] : [], ',') || undefined,

  decode: (
    setStr: string | (string | null)[] | null | undefined,
  ): Set<number> =>
    new Set(
      without(decodeDelimitedNumericArray(setStr, ','), null) as number[],
    ),

  equals: (
    set1: Set<number> | null | undefined,
    set2: Set<number> | null | undefined,
  ) => isEqual(set1, set2),
};
