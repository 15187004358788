// API_STATUS
export enum HTTP_STATUS {
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

// EMBEDS
export const GA_KLUE_TRACKING_NAME = 'klueCustomersAccount';
export const GA_INSTANCE_TRACKING_NAME = 'instanceAccount';
export const OPTIMIZELY_UPDATE_INTERVAL = 60000;
export const OPTIMIZELY_TIMEOUT = 500;

// TIME
export const ONE_MINUTE_IN_SECONDS = 60;
export const ONE_MINUTE_IN_MS = 60000;
export const ONE_SECOND_IN_MS = 1000;
export const THREE_SECOND_IN_MS = 3000;
export const FIVE_SECOND_IN_MS = 5000;
export const SEARCH_DEBOUNCE_TIME = 500;
export const EDITOR_CHANGE_DEBOUNCE_TIME = 300;
export const AUTO_SAVE_DEBOUNCE_TIME = 1200;
export const RTK_PENULTIMATE_RETRY_TIME = 4800;
export const RTK_FINAL_RETRY_TIME = 9600;

export const SEARCH_DEFAULT_MAX_ITEMS = 12;
export const COMMAND_CENTER_DEFAULT_MAX_ITEMS = 12;

//SESSION
export const TIMEOUT_MINUTES_REMAINING_TO_SHOW_ALERT = 5;
export const TIMEOUT_MINIMUM_MINUTES_REMAINING_TO_START_SILENT_COUNTER = 10;

// GENERAL
export const KLUE_CARD_NO_ZOOM_IMG = 'no-zoom';
export const KLUE_CARD_ZOOM_IMG = 'zoom-image';

// SEARCH
export const LAST_UPDATED_FILTERS = {
  allTime: 'all',
  today: 'now-1d',
  pastWeek: 'now-1w',
  pastMonth: 'now-1M',
  past6Month: 'now-6M',
};

export const SOCIAL_URLS = {
  facebook: 'https://www.facebook.com/',
  twitter: 'https://twitter.com/',
  linkedin: 'https://www.linkedin.com/',
};

// AIRBRAKE IGNORED ERRORS
export const AIRBRAKE_IGNORED_ERRORS = [
  'AbortError',
  'You need to sign in or sign up before continuing',
];
