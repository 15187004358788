import {
  Box,
  Button,
  Tip,
  Toolbar,
  tokens,
  PhosphorIcon,
} from '@kluein/klue-ui';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { KlueCardSentimentEnum } from 'api/api.types';
import { AnimatedCardSentimentButton } from 'components/common/animated-card-sentiment-button';
import { useAuth } from 'contexts/auth';
import { useCardSentiment } from 'hooks/profile/use-card-sentiment';
import TEST_IDS from 'test-ids';
import TRACKING_IDS from 'tracking-ids';

import { KlueCardMenu } from '../klue-card-menu';

import type { KlueCardToolbarProps } from './KlueCardToolbar.types';

const WrapperButton = styled(Button)`
  border-radius: 50%;
  border: 1px solid;
  border-color: ${tokens.color.neutral.lightgrey.main};
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: ${tokens.color.secondary.dark};
    border-color: ${tokens.color.secondary.main};
  }
  &.active {
    color: ${tokens.color.neutral.white.main};
    background-color: ${tokens.color.primary.main};
    border-color: ${tokens.color.primary.main};
  }
`;

function KlueCardToolbar(
  props: KlueCardToolbarProps,
  ref?: React.ForwardedRef<HTMLDivElement>,
) {
  const { t } = useTranslation();
  const {
    isCardFeedbackDisabled,
    isCardSentimentEnabled,
    isCardSentimentAndFeedbackOnHoverDisabled,
    isCurator,
  } = useAuth();
  const {
    sourcesCount,
    cardId,
    profileId,
    rank,
    onFeedbackClick,
    onMouseEnter,
    onMouseLeave,
  } = props;
  const {
    isSentimentButtonLoading,
    isSentimentActive,
    onSentimentButtonClick,
  } = useCardSentiment({
    cardId,
    interactionLabel: 'toolbar',
    rank,
  });

  return (
    <Box
      gap="small"
      justify="center"
      alignContent="center"
      align="center"
      style={{ position: 'absolute', bottom: '20px', right: '12px' }}
      data-test-id={TEST_IDS.klueCard.toolbar.container}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isCardSentimentEnabled &&
        !isCardSentimentAndFeedbackOnHoverDisabled &&
        !isCurator && (
          <Box gap="small">
            <AnimatedCardSentimentButton
              tipContent={t('Card:sentiment.button.positive')}
              tipAlign={{ align: { right: 'left' } }}
              active={isSentimentActive(KlueCardSentimentEnum.POSITIVE)}
              onClick={() =>
                !isSentimentButtonLoading[KlueCardSentimentEnum.POSITIVE] &&
                onSentimentButtonClick(KlueCardSentimentEnum.POSITIVE)
              }
              icon={<PhosphorIcon.ThumbsUp size={tokens.iconSize.small} />}
              iconActive={
                <PhosphorIcon.ThumbsUp
                  weight="fill"
                  size={tokens.iconSize.small}
                />
              }
              a11ytitle={t('Card:sentiment.button.a11ytitlePositive')}
              data-tracking-id={TRACKING_IDS.global.card.sentiment.positive}
              data-test-id={TEST_IDS.klueCard.klueCardSentiment.button.positive}
            />
            <AnimatedCardSentimentButton
              tipContent={t('Card:sentiment.button.negative')}
              tipAlign={{ align: { right: 'left' } }}
              active={isSentimentActive(KlueCardSentimentEnum.NEGATIVE)}
              onClick={() =>
                !isSentimentButtonLoading[KlueCardSentimentEnum.NEGATIVE] &&
                onSentimentButtonClick(KlueCardSentimentEnum.NEGATIVE)
              }
              icon={<PhosphorIcon.ThumbsDown size={tokens.iconSize.small} />}
              iconActive={
                <PhosphorIcon.ThumbsDown
                  weight="fill"
                  size={tokens.iconSize.small}
                />
              }
              a11ytitle={t('Card:sentiment.button.a11ytitleNegative')}
              data-tracking-id={TRACKING_IDS.global.card.sentiment.negative}
              data-test-id={TEST_IDS.klueCard.klueCardSentiment.button.negative}
            />
          </Box>
        )}
      {!isCardFeedbackDisabled &&
        !isCardSentimentAndFeedbackOnHoverDisabled &&
        !isCurator && (
          <Tip
            content={t('Card:sentiment.button.shareFeedback')}
            dropProps={{ align: { right: 'left' } }}
            color="dark"
            variant="small"
          >
            <Box
              gap="xsmall"
              align="center"
              justify="center"
              alignContent="center"
            >
              <WrapperButton
                variant="flat-outlined"
                onClick={() => onFeedbackClick?.(true)}
                icon={<PhosphorIcon.UserSound size={tokens.iconSize.small} />}
                a11yTitle={t('Card:sentiment.button.a11ytitleFeedback')}
                data-test-id={
                  TEST_IDS.klueCard.klueCardSentiment.button.shareFeedback
                }
                data-tracking-id={
                  TRACKING_IDS.global.card.shareFeedback.openModal
                }
              />
            </Box>
          </Tip>
        )}
      <KlueCardMenu
        sourcesCount={sourcesCount}
        cardId={cardId}
        profileId={profileId}
        rank={rank}
        trackingIds={{
          copyEmbed: TRACKING_IDS.global.card.toolbar.copyEmbed,
          duplicateCard: TRACKING_IDS.global.card.toolbar.duplicateCard,
          copyLink: TRACKING_IDS.global.card.toolbar.copyLink,
          openCard: TRACKING_IDS.global.card.toolbar.openCard,
          sources: TRACKING_IDS.global.card.toolbar.sources,
        }}
      >
        {({ menuItems, handleClickMenuItem }) => {
          return (
            <Toolbar
              data={menuItems}
              a11yTitle={t('Card:header.menu.title')}
              onClickItem={(item) => handleClickMenuItem(item, 'toolbar_menu')}
            />
          );
        }}
      </KlueCardMenu>
    </Box>
  );
}

export default forwardRef(KlueCardToolbar);
