import { Image, Box, theme, tokens } from '@kluein/klue-ui';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useQueryParams, NumberParam } from 'use-query-params';

import IMG_LOGO from 'assets/klue-k-transparent.svg?inline';
import store from 'store';

import AuthContext from './authContext';
import useAuth from './useAuth';

export type ProviderAuthProps = {
  children: React.ReactElement;
};

const MotionBox = motion(Box);

const QueryParamConfig = {
  previewing: NumberParam,
};

function usePreviewingVisibilityGroup() {
  const visibilityGroupId = useSelector(store.select.auth.visibilityGroupId);
  const [{ previewing }, setQuery] = useQueryParams(QueryParamConfig);
  useEffect(() => {
    if (visibilityGroupId !== null) {
      setQuery({ previewing: visibilityGroupId }, 'replaceIn');
    }
  }, [previewing, setQuery, visibilityGroupId]);
}

function ProvideAuth(props: ProviderAuthProps) {
  const auth = useAuth();

  const size = theme.global?.size?.small;
  const { t } = useTranslation(['Common']);

  useEffect(() => {
    auth.fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  usePreviewingVisibilityGroup();

  if (auth.loading) {
    return (
      <AnimatePresence exitBeforeEnter>
        <MotionBox
          key="lock-layer"
          background={tokens.color.neutral.rosegrey.main}
          height="100vh"
          align="center"
          justify="center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Image
            width={size}
            height={size}
            src={IMG_LOGO}
            alt={t('Common:logo', { company: 'Klue' })}
          />
        </MotionBox>
      </AnimatePresence>
    );
  }

  return (
    <AuthContext.Provider key="auth-provider" value={auth}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default ProvideAuth;
