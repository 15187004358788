import { pipe } from 'lib/utils';

import { ImportanceEnum } from './getApiLayer.types';
import {
  mergeArrays,
  appendUrlQueryString,
  replaceVariables,
  checkEndPointId,
  mergeEndPointOptions,
} from './utils';

import type {
  InitType,
  GetInterceptorsType,
  CustomFetchOptionsType,
  SetEndPointOptionsType,
  GetApiLayerOptionsType,
  InterceptorsKeyType,
  ApiErrorType,
  ComposedReturnType,
  SetEndPointExtendedOptionsType,
  SetEndpointReturnType,
} from './';

const DEFAULT_GLOBAL_OPTIONS = {
  retryTimes: 0,
  retryStatus: [],
};

const DEFAULT_ENDPOINT_OPTIONS = {
  ignoreGlobalInterceptors: false,
  importance: ImportanceEnum.high,
};

const fetchWrapper =
  <ReturnType>(
    beforeHooks: (args: [RequestInfo, InitType?]) => [RequestInfo, InitType?],
    afterHooks: (
      args: ComposedReturnType<ReturnType>,
    ) => ComposedReturnType<ReturnType>,
  ) =>
  (
    ...options: [RequestInfo, InitType?]
  ): Promise<ComposedReturnType<ReturnType>> => {
    return fetch
      .apply(globalThis, beforeHooks(options))
      .then(async (response) => {
        const { headers, status, ok } = response;

        if (status === 204) {
          return {
            data: {},
            ok,
            headers,
            status,
          };
        }

        try {
          if (headers.get('content-type')?.includes('text/html')) {
            const data = await response.text().catch((error) => {
              // eslint-disable-next-line no-console
              console.error(error);
            });
            return {
              data,
              ok,
              headers,
              status,
            };
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }

        const data = await response.json().catch((error) => {
          // TODO: Revert this change after migrating all async functions to trycatch block
          // eslint-disable-next-line no-console
          console.error(error);
          // throw new Error(error);
        });

        return {
          data,
          ok,
          headers,
          status,
        };
      })
      .then((data) => afterHooks(data));
  };

const getInterceptors = <ReturnType>({
  endPointOptions,
  globalOptions,
  interceptorsKey,
}: GetInterceptorsType<ReturnType>) => {
  return endPointOptions.ignoreGlobalInterceptors
    ? endPointOptions.globals?.interceptor?.[interceptorsKey] ?? []
    : mergeArrays(
        globalOptions.interceptor?.[interceptorsKey],
        endPointOptions.globals?.interceptor?.[interceptorsKey],
      );
};

const customFetch = <PathType, ParamsType, QueryType, ReturnType>(
  globalOptions: CustomFetchOptionsType,
  endPointOptions: SetEndPointOptionsType<ReturnType>,
  abortController: AbortController,
  path?: PathType,
  params?: ParamsType,
  query?: QueryType,
): Promise<ComposedReturnType<ReturnType>> => {
  const getHook = (interceptorsKey: InterceptorsKeyType) =>
    getInterceptors({
      interceptorsKey,
      endPointOptions,
      globalOptions,
    });

  const beforeHooks = pipe<[RequestInfo, InitType?]>(getHook('before'));
  const afterHooks = pipe<ComposedReturnType<ReturnType>>(getHook('after'));
  const errorHandlerHooks = pipe<ApiErrorType>(getHook('errorHandler'));

  const fetchOptions = {
    headers: {
      ...globalOptions.headers,
      ...endPointOptions.globals?.headers,
    },
    method: endPointOptions.method,
    importance: endPointOptions.importance,
    'content-type':
      endPointOptions.globals?.contentType ?? globalOptions.contentType,
    body: JSON.stringify(params),
    signal: abortController.signal,
  } as unknown as InitType;

  if (endPointOptions.method === 'GET') delete fetchOptions?.body;

  return new Promise((resolve, reject) => {
    let retryAttempts = 1;
    const retryTimes =
      endPointOptions.globals?.retryTimes ?? globalOptions.retryTimes;
    const retryStatus =
      endPointOptions.globals?.retryStatus ?? globalOptions.retryStatus;

    const handleFetchError = (e: ApiErrorType) => {
      const runErrorHandlerHooksAndReject = (e: ApiErrorType) => {
        errorHandlerHooks(e);
        return reject(e);
      };

      if (retryAttempts >= retryTimes) {
        return runErrorHandlerHooksAndReject(e);
      }

      const isEmptyRetryStatus = !retryStatus.length;
      const hasErrorStatusOrErrorCode =
        retryStatus.includes(e.status ?? '') ||
        retryStatus.includes(e.code ?? '');

      if (hasErrorStatusOrErrorCode || isEmptyRetryStatus) {
        retryAttempts++;
        return runFetch();
      }

      return runErrorHandlerHooksAndReject(e);
    };

    const runFetch = async () => {
      const url =
        (endPointOptions.globals?.baseUrl ?? globalOptions.baseUrl) +
        endPointOptions.path;

      const urlToFetch = pipe<string>([
        (url) =>
          // TODO: Fix issue with QueryType
          appendUrlQueryString(url, query as unknown as Record<string, string>),
        (url) =>
          replaceVariables(url, path as unknown as Record<string, string>),
      ])(url);

      await fetchWrapper<ReturnType>(beforeHooks, afterHooks)(
        urlToFetch,
        fetchOptions,
      )
        .then(resolve)
        .catch(handleFetchError);
    };

    runFetch().catch((e) => reject(e));
  });
};

const getApiLayer = (globalOptions: GetApiLayerOptionsType) => {
  const abortControllerMap = new Map();

  const setEndpoint = <PathType, ParamsType, QueryType = any, ReturnType = any>(
    endPointOptions: SetEndPointOptionsType<ReturnType>,
  ): SetEndpointReturnType<PathType, ParamsType, QueryType, ReturnType> => {
    const { id, namespace } = endPointOptions;
    const abortController = new AbortController();
    const globalOptionsData = { ...DEFAULT_GLOBAL_OPTIONS, ...globalOptions };
    const endpointOptionsData = {
      ...DEFAULT_ENDPOINT_OPTIONS,
      ...endPointOptions,
    };

    abortControllerMap.set(id, abortController);

    if (namespace) {
      const allIdsInNamespace = abortControllerMap.get(namespace) ?? [];
      const namespaceIds = [...Array.from(allIdsInNamespace), id];
      abortControllerMap.set(namespace, namespaceIds);
    }

    const endpoint = (options?: {
      path?: PathType;
      params?: ParamsType;
      query?: QueryType;
    }) =>
      customFetch<PathType, ParamsType, QueryType, ReturnType>(
        globalOptionsData,
        endpointOptionsData,
        abortControllerMap.get(id),
        options?.path,
        options?.params,
        options?.query,
      );

    const extendEndpoint = <
      ExtendedPathType = PathType,
      ExtendedParamsType = ParamsType,
      ExtendedQueryType = QueryType,
      ExtendedReturnType = ReturnType,
    >(
      extendedEndPointOptions: SetEndPointExtendedOptionsType<ExtendedReturnType>,
    ) => {
      return setEndpoint<
        ExtendedPathType,
        ExtendedParamsType,
        ExtendedQueryType,
        ExtendedReturnType
      >(
        mergeEndPointOptions<ExtendedReturnType, ReturnType>(
          endPointOptions,
          extendedEndPointOptions,
        ),
      );
    };

    return [endpoint, extendEndpoint];
  };

  const abort = (id: string) => {
    const abortController = abortControllerMap.get(id);

    const resetAbortController = (controllerId: string) =>
      abortControllerMap.set(controllerId, new AbortController());

    if (Array.isArray(abortController)) {
      abortController.forEach((abortId) => {
        abortControllerMap.get(abortId).abort();
        resetAbortController(abortId);
      });
    } else {
      abortController.abort();
      resetAbortController(id);
    }
  };

  return {
    setEndpoint: checkEndPointId(setEndpoint, () => abortControllerMap),
    abort,
  };
};

export default getApiLayer;
