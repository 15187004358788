import Intercom from 'lib/intercom/Intercom';
import MsTeamsNotifier from 'lib/microsoft-teams/MsTeamsNotifier';
import { klueIsInTeamsAuthPopup } from 'lib/microsoft-teams/useMicrosoftTeams';
import Pendo from 'lib/pendo/Pendo';

import FullStory from './fullstory/FullStory';

const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID;
const PENDO_API_KEY = import.meta.env.VITE_PENDO_API_KEY;
const FULLSTORY_ORG_ID = import.meta.env.VITE_FULLSTORY_ORG_ID;

const disableMonitorTools =
  import.meta.env.VITE_DISABLE_MONITOR_TOOLS === 'true';

const Embeds = () => {
  if (disableMonitorTools) {
    return null;
  }

  return (
    <>
      {INTERCOM_APP_ID && <Intercom appId={INTERCOM_APP_ID} />}
      {PENDO_API_KEY && <Pendo apiKey={PENDO_API_KEY} />}
      {FULLSTORY_ORG_ID && <FullStory orgId={FULLSTORY_ORG_ID} />}
      {klueIsInTeamsAuthPopup() && <MsTeamsNotifier />}
    </>
  );
};

export default Embeds;
