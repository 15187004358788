import { Box, Heading } from '@kluein/klue-ui';

import type { ReactNode } from 'react';

type OptionLayoutProps = {
  testId: string;
  title: string;
  body: ReactNode;
};

export default function OptionLayout({
  testId,
  title,
  body,
}: OptionLayoutProps) {
  return (
    <Box data-test-id={testId} overflow="hidden" margin={{ top: 'small' }}>
      {title && (
        <Heading level={4} size="small">
          {title}
        </Heading>
      )}
      {body}
    </Box>
  );
}
