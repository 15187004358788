export type BrowserType = {
  [key: string]: boolean;
};

const browsers: BrowserType = {
  Firefox: false,
  SamsungBrowser: false,
  OPR: false,
  Op: false,
  Opera: false,
  Trident: false,
  Edg: false,
  Chrome: false,
  Safari: false,
};

export const getBrowsers = () => {
  const browserUserAgent = navigator.userAgent;
  Object.keys(browsers).some((item) => {
    if (browserUserAgent.includes(item)) {
      browsers[item] = true;
      return true;
    }
    return false;
  });
  const {
    Firefox,
    SamsungBrowser,
    Opera,
    Op,
    OPR,
    Trident,
    Edg,
    Chrome,
    Safari,
  } = browsers;
  return {
    Firefox,
    SamsungBrowser,
    Opera: OPR || Op || Opera,
    IE: Trident,
    Edge: Edg,
    Chrome,
    Safari,
  } as BrowserType;
};
