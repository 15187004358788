import {
  format,
  startOfDay,
  endOfDay,
  isValid,
  endOfYesterday,
  startOfYesterday,
  subDays,
} from 'date-fns';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { matchPath } from 'react-router-dom';

import { MenuItem, Sort, Source, State } from 'api/endpoints/intel/intel.enums';
import store from 'store';

import {
  PRESET_TIME_RANGE_MAP,
  DateRangeFilters,
  TRIAGE,
  BULK_EDIT,
} from './constants';

import type {
  IntelQueryParamsType,
  SourceShapeType,
} from 'api/endpoints/intel/intel.types';
import type { FiltersFromURLType } from 'hooks/alerts/use-filters-from-url/useFiltersFromURL.types';

export const mountDateFilterObject = (
  from: Date,
  to?: Date,
): { createdBefore: string; createdAfter: string } | undefined => {
  if (!isValid(from)) return;

  if (!to) {
    from.setHours(0, 0, 0, 0);
    const endRange = endOfDay(from);

    return {
      createdBefore: endRange.toISOString(),
      createdAfter: from.toISOString(),
    };
  }

  if (to && isValid(to)) {
    return {
      createdBefore: to.toISOString(),
      createdAfter: from.toISOString(),
    };
  }
};

export const getLabelFromDate = (date: string[]) => {
  const startDate = new Date(date[0]);
  const endDate = new Date(date[1]);

  if (
    date.length === 1 ||
    `${format(startDate, 'MMM dd, yyyy')}` ===
      `${format(endDate, 'MMM dd, yyyy')}`
  ) {
    return `${format(startDate, 'MMM dd, yyyy')}`;
  }

  return `${format(startDate, 'MMM dd')} - ${format(endDate, 'MMM dd, yyyy')}`;
};

export const getDateRangePreset = (preset: string | DateRangeFilters) => {
  const today = startOfDay(new Date());

  const timeDifference = PRESET_TIME_RANGE_MAP[preset];
  const goalDate = subDays(today, timeDifference);

  const MAP_FILTER_BY_TIME_DIFFERENCE: Partial<
    Record<
      DateRangeFilters | 'default',
      () =>
        | { createdBefore?: string; createdAfter?: string; dateRange?: string }
        | undefined
    >
  > = {
    [DateRangeFilters.allTime]: () => ({
      createdAfter: undefined,
      createdBefore: undefined,
      dateRange: preset,
    }),
    [DateRangeFilters.today]: () => mountDateFilterObject(today),
    [DateRangeFilters.yesterday]: () =>
      mountDateFilterObject(startOfYesterday(), endOfYesterday()),
    default: () => mountDateFilterObject(startOfDay(goalDate), endOfDay(today)),
  };

  const updatedDateValues = (
    MAP_FILTER_BY_TIME_DIFFERENCE[preset as DateRangeFilters] ||
    MAP_FILTER_BY_TIME_DIFFERENCE.default
  )?.();

  return {
    ...updatedDateValues,
    dateRange: preset,
  };
};

export const normalizeFiltersFromURL = (queryFromURL: FiltersFromURLType) =>
  omitBy(queryFromURL, isNil);

const today = startOfDay(new Date());

export const getMyNewIntelPresetData = (): Partial<IntelQueryParamsType> & {
  dateRange: string;
} => {
  const rivalIds = store.getState().auth.user?.curatingRivals;
  const data = {
    states: State.Unprocessed,
    createdAfter: startOfYesterday().toISOString(),
    createdBefore: endOfDay(today).toISOString(),
    dateRange: DateRangeFilters.last2Days,
    sort: Sort.NEWEST,
    view: MenuItem.MyNewIntel,
  };

  !!rivalIds?.length && Object.assign(data, { rivalIds });

  return data;
};

export const getDefaultPreset = (view: string) => {
  return {
    view,
  };
};

export const getIntelPermalink = (source?: SourceShapeType) => {
  const permalink = (() => {
    if (source) {
      if ('permalink' in source) {
        return source.permalink;
      }

      if ('fromAddress' in source) {
        return `mailto:${source.fromAddress}`;
      }
    }

    return undefined;
  })();

  try {
    const url = new URL(permalink || '');

    return {
      title: url.hostname || url.pathname,
      href: url.href,
    };
  } catch {
    return undefined;
  }
};

export const isInAlerts = () =>
  !!matchPath(
    {
      path: '/alerts',
    },
    window.location.pathname,
  );

export const isInTriageMode = () =>
  new URLSearchParams(window.location.search).get('action') === TRIAGE;

export const isInBulkEditMode = () =>
  new URLSearchParams(window.location.search).get('action') === BULK_EDIT;

export const getSecondsAndMinutes = (time: number) => {
  const convertSecondsToMinutes = Math.floor(time / 60);
  const remainderSeconds = time % 60;

  return {
    minutes: convertSecondsToMinutes,
    seconds: remainderSeconds,
  };
};

export const getCurrentDigestId = () =>
  store.getState().intelDigest.currentDigest?.id;

export const isIntelInCurrentDigest = (intelId: string) => {
  const currentDigestId = getCurrentDigestId();
  if (!currentDigestId) return false;

  const intel = store.getState().intelCards.byId.get(intelId);
  const isAddedToCurrentDigest = intel?.digests.some(
    (digest) => digest.id === currentDigestId,
  );

  return isAddedToCurrentDigest;
};

export const isIntelInPreviousDigest = (intelId: string) => {
  const currentDigestId = getCurrentDigestId();
  if (!currentDigestId) return false;

  const intel = store.getState().intelCards.byId.get(intelId);
  const isInPreviousDigest = intel?.digests.some(
    (digest) => digest.id !== currentDigestId,
  );

  return isInPreviousDigest;
};

export const isIntelSourceAllowedToSummarize = (source: SourceShapeType) =>
  [Source.Kluebot, Source.Rss, Source.Extension].includes(source?.channel);

export const intelInDigestLength = (intelId: string) => {
  const intel = store.getState().intelCards.byId.get(intelId);
  const allDigests = store.getState().intelDigest.allDigests;
  const isInAnyDigest = intel?.digests?.filter((digest) =>
    allDigests.find((d) => d.id === digest.id),
  );

  return isInAnyDigest?.length || 0;
};
