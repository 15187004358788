import { Formulas } from './formulas.types';

export const FORMULA_REGEX = /({{[^}]+}})/gi;
export const COMPLETE_FORMULA_REGEX = /{{\s*[^(]+\([^}]+\)\s*}}/i; // indicates a complete formula with arguments (not a placeholder)

//@TODO replace value by Formulas enum
export const formulaToKeyMappings = Object.freeze({
  KEY_PEOPLE: Formulas.keyPeople,
  BALANCE_SHEET_STATEMENTS: Formulas.balanceSheetStatements,
  BALANCE_SHEET_STATEMENTS_QUARTERLY: Formulas.balanceSheetStatementsQuarterly,
  CASH_FLOW_STATEMENTS: Formulas.cashFlowStatements,
  CASH_FLOW_STATEMENTS_QUARTERLY: Formulas.cashFlowStatementsQuarterly,
  COMPARE_EMPLOYEE_COUNT: Formulas.compareEmployeeCount,
  EMPLOYEE_COUNT: Formulas.employeeCount,
  HOMEPAGE_SCREENSHOT: Formulas.homepageScreenshot,
  OPERATING_METRIC: Formulas.operatingMetric,
  INCOME_STATEMENTS: Formulas.incomeStatements,
  INCOME_STATEMENTS_QUARTERLY: Formulas.incomeStatementsQuarterly,
  JOB_CATEGORIES: Formulas.jobCategories,
  JOB_POSTINGS: Formulas.jobPostings,
  JOB_POSTINGS_BY_REGION: Formulas.jobPostingsByRegion,
  OFFICE_LOCATIONS: Formulas.officeLocations,
  OPERATING_METRICS_SUMMARY: Formulas.operatingMetricsSummary,
  REVENUES: Formulas.revenues,
  COMPARE_REVENUES: Formulas.compareRevenues,
  SIMILAR_WEBSITES: Formulas.similarWebsites,
  ENGAGEMENT_SUMMARY: Formulas.engagementSummary,
  WEBSITE_TRAFFIC_ORGANIC_KEYWORDS: Formulas.websiteTrafficOrganicKeywords,
  TRAFFIC_PAGE_VIEWS: Formulas.trafficPageViews,
  WEBSITE_TRAFFIC_PAID_KEYWORDS: Formulas.websiteTrafficPaidKeywords,
  TRAFFIC_SOURCES: Formulas.trafficSources,
  TWITTER_FOLLOWERS: Formulas.twitterFollowers,
  RECENT_PRESS_RELEASES: Formulas.recentPressReleases,
  RECENT_BLOG_POSTS: Formulas.recentBlogPosts,
  RECENT_SOCIAL_UPDATES: Formulas.recentSocialUpdates,
  RECENT_VIDEOS: Formulas.recentVideos,
  RECENT_CASE_STUDIES: Formulas.recentCaseStudies,
  RECENT_PUBLICATIONS: Formulas.recentPublications,
  COMPANY_SUMMARY: Formulas.companyOverview,
  SFDC_WINS: Formulas.sfdcWins,
  SFDC_LATEST_OPPORTUNITIES: Formulas.sfdcLatestOpportunities,
});

export const formulaCategories = Object.freeze({
  COMPANY: 'Company',
  PEOPLE: 'People',
  FINANCIALS: 'Financials',
  PUBLICATIONS: 'Publications',
  WEBSITE: 'Website',
  SALESFORCE: 'Salesforce',
});

export const formulaKeyMappings = Object.freeze({
  [Formulas.officeLocations]: {
    label: 'Employee Locations',
    type: 'dynamic',
    category: formulaCategories.COMPANY,
    hasMultipleCompanies: false,
  },
  [Formulas.compareEmployeeCount]: {
    label: 'Employee Growth',
    type: 'dynamic',
    category: formulaCategories.COMPANY,
    hasMultipleCompanies: true,
  },
  [Formulas.employeeCount]: {
    label: 'Employee Count',
    type: 'dynamic',
    category: formulaCategories.COMPANY,
    hasMultipleCompanies: false,
  },
  [Formulas.jobCategories]: {
    label: 'Job Categories',
    type: 'dynamic',
    category: formulaCategories.PEOPLE,
    hasMultipleCompanies: false,
  },
  [Formulas.jobPostings]: {
    label: 'Job Postings',
    type: 'dynamic',
    hasMultipleCompanies: false,
    category: formulaCategories.PEOPLE,
  },
  [Formulas.jobPostingsByRegion]: {
    label: 'Job Postings by Region',
    type: 'dynamic',
    category: formulaCategories.PEOPLE,
    hasMultipleCompanies: false,
  },
  [Formulas.keyPeople]: {
    label: 'Key People',
    type: 'dynamic',
    category: formulaCategories.PEOPLE,
    hasMultipleCompanies: false,
  },
  [Formulas.balanceSheetStatements]: {
    label: 'Balance Sheet - Year',
    type: 'dynamic',
    category: formulaCategories.FINANCIALS,
    hasMultipleCompanies: false,
  },
  [Formulas.balanceSheetStatementsQuarterly]: {
    label: 'Balance Sheet - Quarter',
    type: 'dynamic',
    category: formulaCategories.FINANCIALS,
    hasMultipleCompanies: false,
  },
  [Formulas.cashFlowStatements]: {
    label: 'Cash Flow - Year',
    type: 'dynamic',
    category: formulaCategories.FINANCIALS,
    hasMultipleCompanies: false,
  },
  [Formulas.cashFlowStatementsQuarterly]: {
    label: 'Cash Flow - Quarter',
    type: 'dynamic',
    category: formulaCategories.FINANCIALS,
    hasMultipleCompanies: false,
  },
  [Formulas.incomeStatements]: {
    label: 'Income Statements - Year',
    type: 'dynamic',
    category: formulaCategories.FINANCIALS,
    hasMultipleCompanies: false,
  },
  [Formulas.incomeStatementsQuarterly]: {
    label: 'Income Statements - Quarter',
    type: 'dynamic',
    category: formulaCategories.FINANCIALS,
    hasMultipleCompanies: false,
  },
  [Formulas.operatingMetricsSummary]: {
    label: 'Operating Metrics',
    type: 'dynamic',
    category: formulaCategories.FINANCIALS,
    hasMultipleCompanies: false,
  },
  [Formulas.revenues]: {
    label: 'Revenue',
    type: 'dynamic',
    category: formulaCategories.FINANCIALS,
    hasMultipleCompanies: false,
  },
  [Formulas.compareRevenues]: {
    label: 'Revenue Growth',
    type: 'dynamic',
    category: formulaCategories.FINANCIALS,
    hasMultipleCompanies: true,
  },
  [Formulas.recentCaseStudies]: {
    label: 'Recent Case Studies',
    type: 'dynamic',
    category: formulaCategories.PUBLICATIONS,
    hasMultipleCompanies: false,
  },
  [Formulas.recentPublications]: {
    label: 'Recent Publications',
    type: 'dynamic',
    category: formulaCategories.PUBLICATIONS,
    hasMultipleCompanies: false,
  },
  [Formulas.recentPressReleases]: {
    label: 'Recent Press Releases',
    type: 'dynamic',
    category: formulaCategories.PUBLICATIONS,
    hasMultipleCompanies: false,
  },
  [Formulas.recentSocialUpdates]: {
    label: 'Recent Social Updates',
    type: 'dynamic',
    category: formulaCategories.PUBLICATIONS,
    hasMultipleCompanies: false,
  },
  [Formulas.recentVideos]: {
    label: 'Recent Videos',
    type: 'dynamic',
    category: formulaCategories.PUBLICATIONS,
    hasMultipleCompanies: false,
  },
  [Formulas.twitterFollowers]: {
    label: 'Twitter Followers',
    type: 'dynamic',
    category: formulaCategories.WEBSITE,
    hasMultipleCompanies: false,
  },
  [Formulas.engagementSummary]: {
    label: 'Avg. Engagement per Visit',
    type: 'dynamic',
    category: formulaCategories.WEBSITE,
    hasMultipleCompanies: false,
  },
  [Formulas.homepageScreenshot]: {
    label: 'Homepage Screenshot',
    type: 'dynamic',
    category: formulaCategories.WEBSITE,
    hasMultipleCompanies: false,
  },
  [Formulas.websiteTrafficOrganicKeywords]: {
    label: 'Organic Search Keywords',
    type: 'dynamic',
    category: formulaCategories.WEBSITE,
    hasMultipleCompanies: false,
  },
  [Formulas.trafficPageViews]: {
    label: 'Page Views per Visit',
    type: 'dynamic',
    category: formulaCategories.WEBSITE,
    hasMultipleCompanies: false,
  },
  [Formulas.websiteTrafficPaidKeywords]: {
    label: 'Paid Search Keywords',
    type: 'dynamic',
    category: formulaCategories.WEBSITE,
    hasMultipleCompanies: false,
  },
  [Formulas.recentBlogPosts]: {
    label: 'Recent Blog Posts',
    type: 'dynamic',
    category: formulaCategories.PUBLICATIONS,
    hasMultipleCompanies: false,
  },
  [Formulas.trafficSources]: {
    label: 'Traffic Share by Type',
    type: 'dynamic',
    category: formulaCategories.WEBSITE,
    hasMultipleCompanies: false,
  },
  [Formulas.similarWebsites]: {
    label: 'Similar Sites',
    type: 'dynamic',
    category: formulaCategories.WEBSITE,
    hasMultipleCompanies: false,
  },
  [Formulas.sfdcWins]: {
    label: 'Win Rate (Last 6 Months)',
    type: 'static',
    category: formulaCategories.SALESFORCE,
    hasMultipleCompanies: false,
  },
  [Formulas.sfdcLatestOpportunities]: {
    label: 'Latest Opportunities',
    type: 'static',
    category: formulaCategories.SALESFORCE,
    hasMultipleCompanies: false,
  },
});

export const FORMULA_STATIC_KEYS = ['currentRival', 'currentCompany'];
