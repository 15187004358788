import getApiLayer from 'lib/api/getApiLayer';
import { API_BASEURL } from 'lib/urls';

import authInterceptor from './interceptors/authInterceptor';
import statusErrorInterceptor from './interceptors/statusErrorInterceptor';
import { insertCsrfToken } from './interceptors/userInterceptor';

const { abort, setEndpoint } = getApiLayer({
  baseUrl: `${API_BASEURL}`,
  contentType: 'application/json; charset=UTF-8',
  retryTimes: 0,
  interceptor: {
    before: [insertCsrfToken],
    after: [statusErrorInterceptor, authInterceptor],
    errorHandler: [authInterceptor],
  },
});

export { abort, setEndpoint };
