import { api } from '../api';

import type { CardDataType, UpdateCardsType } from 'api/api.types';

export type CreateCardPayload = {
  data: CardDataType;
  viewOrder: number;
  boardId: number;
  author: number;
  reviewerId?: number;
  templateName: string;
  allAccess?: boolean;
  visibilityGroups?: number[];
  isDraft?: boolean;
  tags?: number[];
};

const injectedApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateCard: build.mutation<any, UpdateCardsType>({
      query: (card) => ({
        url: `/cards.json?v=2`,
        method: 'PUT',
        body: card,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      transformResponse: (response: any) => {
        return response;
      },
    }),
    createCard: build.mutation<any, CreateCardPayload>({
      query: (card) => ({
        url: `/cards.json?v=2`,
        method: 'POST',
        body: card,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      transformResponse: (response: any) => {
        return response;
      },
    }),
    deleteCard: build.mutation<void, number | number[]>({
      query: (cardId) => ({
        url: Array.isArray(cardId) ? `/cards.json` : `/cards/${cardId}.json`,
        method: 'DELETE',
        body: Array.isArray(cardId) ? { cardIds: cardId } : undefined,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
});

export const enhancedApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['Cards'],
});

export const {
  useUpdateCardMutation,
  useCreateCardMutation,
  useDeleteCardMutation,
} = enhancedApi;
