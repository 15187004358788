import { api } from '../api';

import type { BoardsByRivalIdReturnType } from 'api/endpoints/boards/boards.types';

export type GetBoardsByRivalIdQuery = {
  path: {
    rivalId: number;
  };
  query?: {
    visibilityGroupId: number;
  };
};

const injectedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBoardsByRivalId: build.query<
      BoardsByRivalIdReturnType,
      GetBoardsByRivalIdQuery
    >({
      query: ({ path: { rivalId }, query }) => ({
        url: `/rivals/${rivalId}/boards.json`,
        params: query,
      }),
      transformResponse: (returnValue: BoardsByRivalIdReturnType) => {
        return returnValue;
      },
    }),
  }),
});

export const enhancedApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['Boards'],
});

export const { useGetBoardsByRivalIdQuery } = enhancedApi;
