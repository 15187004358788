/* eslint-disable max-lines */
import { createModel } from '@rematch/core';

import { State } from 'api/endpoints/intel/intel.enums';
import { fetchDigests } from 'api/endpoints/intel-digest';
import { favoritePost, unfavoritePost } from 'api/endpoints/posts';
import store from 'store';
import { mergeArrayValues } from 'store/utils';
import { sendExtensionReloadAlertMessage } from 'utils/extension/sendMessageToExtension';

import type { IntelListItemType } from 'api/endpoints/intel/intel.types';
import type { IntelDigestItemType } from 'api/endpoints/intel-digest/intel-digest.types';
import type { RootModel } from 'store/models';

export type StateType = {
  byId: Map<string, IntelDigestItemType>;
  allIds: Set<string>;
  allDigests: IntelDigestItemType[] | [];
  currentDigest: IntelDigestItemType;
  processingIds: Set<string>;
  digestError: boolean;
};
export const initialState = {
  byId: new Map(),
  allIds: new Set(),
  currentDigest: {},
  processingIds: new Set(),
  allDigests: [],
  digestError: false,
} as StateType;
export const intelDigest = createModel<RootModel>()({
  state: initialState,
  reducers: {
    populate: (
      state: StateType,
      { digests }: { digests: IntelDigestItemType[] },
    ): StateType => {
      return {
        ...state,
        byId: mergeArrayValues(state.byId, digests, 'id') as Map<
          string,
          IntelDigestItemType
        >,
        allIds: new Set([
          ...state.allIds,
          ...digests.map((digest) => digest.id.toString()),
        ]),
        allDigests: digests,
      };
    },
    setCurrentDigest: (state: StateType, digest: IntelDigestItemType) => {
      return {
        ...state,
        currentDigest: digest,
      };
    },
    setProcessingIds: (
      state,
      { add, remove }: { add?: { id: string }; remove?: { id: string } },
    ) => {
      const newSet = new Set(state.processingIds);

      add?.id && newSet.add(add.id);
      remove?.id && newSet.delete(remove.id);

      return {
        ...state,
        processingIds: newSet,
      };
    },
    setDigestError: (state, value: boolean) => {
      return {
        ...state,
        digestError: value,
      };
    },
  },
  effects: (dispatch) => ({
    async fetchCurrentDigest() {
      try {
        const { data } = await fetchDigests({
          query: {
            limit: 1,
            filter: 'draft',
          },
        });

        if (!!data.items.length) {
          dispatch.intelDigest.setCurrentDigest(data.items[0]);
          dispatch.intelDigest.populate({ digests: data.items });
          return;
        }
      } catch (err) {
        throw err;
      }
    },
    async fetchAllDigests() {
      try {
        const { data } = await fetchDigests({
          query: {
            filter: 'draft',
            page: 1,
            limit: 20,
            typeId: 'all',
          },
        });

        if (data.items.length > 0) {
          dispatch.intelDigest.populate({ digests: data.items });
          return;
        }
      } catch (err) {
        throw err;
      }
    },
    async addToIntelDigest(
      {
        intelId,
        currentIntelDigest,
      }: { intelId: string; currentIntelDigest?: IntelDigestItemType },
      rootState,
    ) {
      const {
        intelCards,
        intelDigest: { currentDigest },
      } = rootState;

      if (currentIntelDigest?.id || currentDigest?.id) {
        dispatch.intelDigest.setProcessingIds({
          add: {
            id: intelId,
          },
        });

        try {
          const currentIntelCard = intelCards.byId.get(intelId);
          const isNotPublishedToFeed = !currentIntelCard?.publishedAt;
          const isNotArchived = currentIntelCard?.state !== State.Archived;

          if (isNotPublishedToFeed) {
            await dispatch.intelCards.publishToFeed({ intelId });
          } else if (isNotArchived) {
            await dispatch.intelCards.updateIntelCard({
              intelId,
              data: {
                state: State.Archived,
              },
            });
          }

          const updatedIntel = store.getState().intelCards.byId.get(intelId);
          if (updatedIntel?.postId) {
            try {
              await favoritePost({
                path: {
                  postId: updatedIntel?.postId,
                },
                params: {
                  postId: updatedIntel?.postId,
                  emailDigestId: currentIntelDigest?.id
                    ? currentIntelDigest?.id
                    : currentDigest?.id,
                  viewOrder: currentIntelDigest?.favoritesLiveCount
                    ? currentDigest?.favoritesLiveCount
                    : 0,
                },
              }).catch((error) => {
                throw error;
              });

              dispatch.intelCards.populate({
                cards: [
                  {
                    ...updatedIntel,
                    rank: currentIntelCard?.rank as number,
                    digests: [
                      {
                        id: currentDigest.id,
                      },
                    ],
                  },
                ],
              });
            } catch (err) {
              throw err;
            }
          }
          sendExtensionReloadAlertMessage(intelId);
        } catch (err) {
          throw err;
        } finally {
          dispatch.intelDigest.setProcessingIds({
            remove: {
              id: intelId,
            },
          });
        }
      }
    },
    async removeFromDigest(
      {
        intelId,
        currentIntelDigest,
      }: { intelId: string; currentIntelDigest?: IntelDigestItemType },
      rootState,
    ) {
      const currentIntelCard = rootState.intelCards.byId.get(intelId);
      const { currentDigest } = rootState.intelDigest;
      if (
        currentIntelCard?.postId &&
        (currentIntelDigest?.id || currentDigest?.id)
      ) {
        try {
          await unfavoritePost({
            path: {
              postId: currentIntelCard?.postId,
            },
            params: {
              postId: currentIntelCard?.postId,
              emailDigestId: currentIntelDigest?.id || currentDigest.id,
            },
          });

          dispatch.intelCards.populate({
            cards: [
              {
                ...currentIntelCard,
                digests: currentIntelCard.digests.filter((digest) =>
                  currentIntelDigest?.id
                    ? digest.id !== currentIntelDigest?.id
                    : digest.id !== currentDigest?.id,
                ),
              },
            ],
          });
          sendExtensionReloadAlertMessage(intelId);
        } catch (err) {
          throw err;
        } finally {
          dispatch.intelDigest.setProcessingIds({
            remove: {
              id: intelId,
            },
          });
        }
      }
    },
    async addAllToIntelDigest({ intelIds }: { intelIds: string[] }) {
      await Promise.all(
        intelIds.map(async (intelId: string) => {
          await dispatch.intelDigest.addToIntelDigest({ intelId });
          sendExtensionReloadAlertMessage(intelId);
        }),
      ).catch((err) => {
        throw err;
      });
    },
    async addIntelToMultipleDigests({
      intelId,
      intelDigests,
    }: {
      intelId: string;
      intelDigests: IntelDigestItemType[];
    }) {
      try {
        const intelCards = store.getState().intelCards;
        const currentIntelCard = intelCards.byId.get(intelId);
        const isNotPublishedToFeed = !currentIntelCard?.publishedAt;
        const isNotArchived = currentIntelCard?.state !== State.Archived;

        if (isNotPublishedToFeed) {
          await dispatch.intelCards.publishToFeed({ intelId });
        } else if (isNotArchived) {
          await dispatch.intelCards.updateIntelCard({
            intelId,
            data: {
              state: State.Archived,
            },
          });
        }
        await Promise.all(
          intelDigests.map(async (currentIntelDigest: IntelDigestItemType) => {
            if (currentIntelDigest?.id) {
              dispatch.intelDigest.setProcessingIds({
                add: {
                  id: intelId,
                },
              });
              try {
                const updatedIntel = store
                  .getState()
                  .intelCards.byId.get(intelId);
                if (updatedIntel?.postId) {
                  try {
                    await favoritePost({
                      path: {
                        postId: updatedIntel?.postId,
                      },
                      params: {
                        postId: updatedIntel?.postId,
                        emailDigestId: currentIntelDigest?.id,

                        viewOrder: currentIntelDigest?.favoritesLiveCount,
                      },
                    }).catch((error) => {
                      throw error;
                    });
                  } catch (err) {
                    throw err;
                  }
                }
              } catch (err) {
                throw err;
              } finally {
                dispatch.intelDigest.setProcessingIds({
                  remove: {
                    id: intelId,
                  },
                });
              }
            }
          }),
        ).catch((err) => {
          throw err;
        });
        const updatedIntelCard = store.getState().intelCards.byId.get(intelId);
        dispatch.intelCards.populate({
          cards: [
            {
              ...(updatedIntelCard as IntelListItemType),
              rank: updatedIntelCard?.rank as number,
              digests: [
                ...(updatedIntelCard?.digests as []),
                ...intelDigests.map((digest) => ({ id: digest.id })),
              ],
            },
          ],
        });
        sendExtensionReloadAlertMessage(intelId);
      } catch (err) {
        throw err;
      }
    },
    async removeIntelFromMultipleDigests({
      intelId,
      intelDigests,
    }: {
      intelId: string;
      intelDigests: IntelDigestItemType[];
    }) {
      try {
        const intelCards = store.getState().intelCards;
        const currentIntelCard = intelCards.byId.get(intelId);
        await Promise.all(
          intelDigests.map(async (currentIntelDigest: IntelDigestItemType) => {
            if (currentIntelCard?.postId && currentIntelDigest?.id) {
              try {
                await unfavoritePost({
                  path: {
                    postId: currentIntelCard?.postId,
                  },
                  params: {
                    postId: currentIntelCard?.postId,
                    emailDigestId: currentIntelDigest?.id,
                  },
                });
              } catch (err) {
                throw err;
              } finally {
                dispatch.intelDigest.setProcessingIds({
                  remove: {
                    id: intelId,
                  },
                });
              }
            }
          }),
        ).catch((err) => {
          throw err;
        });
        const updatedIntelCard = store.getState().intelCards.byId.get(intelId);
        if (updatedIntelCard === undefined) return;
        dispatch.intelCards.populate({
          cards: [
            {
              ...(updatedIntelCard as IntelListItemType),
              digests: updatedIntelCard?.digests?.filter(
                (digest) => !intelDigests.find((d) => d.id === digest.id),
              ),
            },
          ],
        });
        sendExtensionReloadAlertMessage(intelId);
      } catch (err) {
        throw err;
      }
    },
  }),
});
