export type BulkEditProviderPropTypes = {
  children: React.ReactChild | React.ReactNode[];
};

export enum BulkEditOption {
  CardPermissions = 'cardPermissions',
  Tags = 'tags',
  Delete = 'delete',
}

export type BulkEditContextType = {
  isActive: boolean;
  activeOption: BulkEditOption | null;
};

export type BulkEditOptionsContextType = BulkEditContextType & {
  options: Set<BulkEditOption>;
  setActiveOption: (option: BulkEditOption) => void;
};
