import { setEndpoint } from 'api/configure';

import type { NotificationsCountsType } from './notifications.types';

export const [fetchNotificationsCounts] = setEndpoint<
  any,
  any,
  { ownProfiles?: boolean },
  NotificationsCountsType
>({
  path: '/notifications/counts.json',
  id: 'fetchNotificationsCounts',
  method: 'GET',
  namespace: 'notifications',
});
