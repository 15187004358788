import { profileSchema, profileRivalSchema } from 'store/schema';

import { setEndpoint } from '../configure';
import {
  normalizeDataBySchema,
  normalizeObject,
} from '../interceptors/normalize';
import profileInterceptor from '../interceptors/profileInterceptor';

import type {
  fetchProfileByIdReturnType,
  fetchProfileByIdParamsType,
  FetchCardsByLaneIdQueryType,
  FetchProfileByIdQueryType,
  FetchProfileRivalQueryType,
} from './profile.types';
import type { DeleteBoardsType } from 'api/api.types';

export const [fetchProfileById] = setEndpoint<
  any,
  fetchProfileByIdParamsType,
  FetchProfileByIdQueryType,
  fetchProfileByIdReturnType
>({
  path: '/profiles/:id.json',
  id: 'fetchProfileById',
  method: 'GET',
  namespace: 'profiles',
  globals: {
    interceptor: {
      after: [profileInterceptor, normalizeDataBySchema(profileSchema)],
    },
  },
});

export const [fetchProfileRival] = setEndpoint<
  { id: number },
  any,
  FetchProfileRivalQueryType
>({
  path: '/profiles/:id/rival.json',
  id: 'fetchProfileRival',
  method: 'GET',
  namespace: 'profiles',
  globals: {
    interceptor: {
      after: [normalizeDataBySchema(profileRivalSchema)],
    },
  },
});

export const [fetchCardsByLaneId] = setEndpoint<
  any,
  { boardId: number },
  FetchCardsByLaneIdQueryType,
  any
>({
  path: '/boards/:boardId/cards.json',
  id: 'fetchCardsByLaneId',
  namespace: 'profiles',
  method: 'GET',
  globals: {
    interceptor: {
      after: [normalizeObject()],
    },
  },
});

export const [deleteBoards] = setEndpoint<any, DeleteBoardsType, any, any>({
  path: '/boards.json',
  id: 'deleteBoards',
  method: 'DELETE',
  namespace: 'profile',
  globals: {
    contentType: 'application/json',
  },
});
