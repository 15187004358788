import { useEffect } from 'react';

import { useMicrosoftTeams } from './useMicrosoftTeams';

const MsTeamsNotifier = () => {
  const { teams } = useMicrosoftTeams({
    onSuccess() {
      // eslint-disable-next-line no-console
      console.log('MS Teams SDK: initialization successful');
    },
    onFailure(error: string) {
      // eslint-disable-next-line no-console
      console.error('MS Teams SDK: initialization failed', error);
    },
  });

  useEffect(() => {
    if (teams?.isInitialized) {
      teams.authentication.notifySuccess();
    }
  }, [teams]);

  return null;
};

export default MsTeamsNotifier;
