import { Modal, Toast } from '@kluein/klue-ui';

import { appUIContext, useAppUIProvider } from './appUIContext';

type AppProps = {
  children: React.ReactElement;
};

export function AppUIProvider(props: AppProps) {
  const uiState = useAppUIProvider();

  return (
    <>
      {uiState.toast.isOpen && (
        <Toast {...uiState.toast.props} onClose={uiState.toast.close} />
      )}

      {uiState.modal.isOpen && (
        <Modal {...uiState.modal.props} onClose={uiState.modal.close} />
      )}

      <appUIContext.Provider value={uiState}>
        {props.children}
      </appUIContext.Provider>
    </>
  );
}
