import { Box, Button, tokens } from '@kluein/klue-ui';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { UserAvatar } from 'components/common/user-avatar';
import { APP_V1_BASEURL } from 'lib/urls';
import store from 'store';
import TEST_IDS from 'test-ids';

import type { UserType } from 'api/api.types';
import type { RootState } from 'store/store.types';

const StyledBox = styled(Box)`
  transition: transform 250ms 125ms ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

const NavBarAvatar: React.FC<{ borderColor?: string }> = ({
  borderColor = tokens.color.primary.contrastlight,
}) => {
  const user = useSelector<RootState, UserType | null>(
    (state) => state.auth.user,
  );
  const notifications = useSelector<RootState, number>(
    store.select.auth.notifications,
  );

  const { t } = useTranslation(['Common']);

  return (
    <StyledBox alignSelf="center">
      <Button
        variant="plain"
        key="appNavBar.navBarLink.usersButton"
        title={`${t('Common:actions.edit')} ${t('Common:app.profile')}`}
        href={`${APP_V1_BASEURL}/notifications`}
        data-test-id={TEST_IDS.layout.appNavBar.userAvatarLink}
        badge={
          !!notifications && {
            value: notifications,
            max: 99,
          }
        }
      >
        <UserAvatar
          userId={user?.id as number}
          size="22px"
          border={{ color: borderColor, size: 'small' }}
          round="full"
        />
      </Button>
    </StyledBox>
  );
};

export default memo(NavBarAvatar);
