import { createModel } from '@rematch/core';

import type {
  IntelQueryParamsType,
  PaginationShapeType,
} from 'api/endpoints/intel/intel.types';
import type { RootModel } from 'store/models';

export type StateType = {
  filters: IntelQueryParamsType;
  pagination: PaginationShapeType;
  isFetchingNextPage: boolean;
};

export const initialState = {
  filters: {},
  pagination: {
    size: 50,
    offset: 0,
    total: -1,
    nextOffset: 0,
  },
  isFetchingNextPage: false,
} as StateType;

export const intelFilters = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setFilters: (
      state: StateType,
      filters: IntelQueryParamsType,
      rehydrate?: boolean,
    ) => {
      if (rehydrate) return { ...state, filters };

      return {
        ...state,
        filters: {
          ...state.filters,
          ...filters,
        },
      };
    },
    setPaginationInfo: (
      state: StateType,
      pagination: Partial<PaginationShapeType>,
    ): StateType => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...pagination,
        },
      };
    },
    resetFilters: () => {
      return {
        ...initialState,
      };
    },
    resetPagination: (state: StateType) => {
      return {
        ...state,
        pagination: {
          ...initialState.pagination,
        },
      };
    },
    setIsFetchingNextPage: (state: StateType, isFetchingNextPage: boolean) => {
      return {
        ...state,
        isFetchingNextPage,
      };
    },
  },
  selectors: (slice) => ({
    filters: () => slice((filters) => filters),
    isFetchingNextPage: () => slice((isFetchingNextPage) => isFetchingNextPage),
  }),
});
