import errorInterceptor from 'api/interceptors/errorInterceptor';

import { setEndpoint } from '../../configure';

import type {
  FetchSearchRivalsReturnType,
  SearchRivalsQueryType,
} from './search-rivals.types';

export const [fetchSearchRivals] = setEndpoint<
  any,
  any,
  SearchRivalsQueryType,
  FetchSearchRivalsReturnType
>({
  path: '/rivals/search.json',
  id: 'fetchSearchRivals',
  method: 'GET',
  namespace: 'searchrivals',
  globals: {
    interceptor: {
      errorHandler: [(error) => errorInterceptor(error, {})],
    },
  },
});
