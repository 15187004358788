import { Box, Button, Text, Heading, Modal } from '@kluein/klue-ui';
import { useTranslation } from 'react-i18next';

import TEST_IDS from 'test-ids';

type BulkCompletionProps = {
  title: string;
  onContinueEditing: VoidFunction;
  onAllDone: VoidFunction;
};

const BulkEditCompletion = ({
  title,
  onContinueEditing,
  onAllDone,
}: BulkCompletionProps) => {
  const { t } = useTranslation(['Common']);

  return (
    <Modal onClose={onContinueEditing} showCloseButton={false}>
      <Modal.Content>
        <Box>
          <Box>
            <Heading level="2">{t('Common:status.success')}</Heading>
            <Text>{title}</Text>
          </Box>
          <Box
            margin={{ top: 'large' }}
            gap="small"
            direction="row"
            justify="between"
          >
            <Button
              title={t('Common:actions.continueEditing')}
              data-test-id={TEST_IDS.bulkEdit.continueEditingButton}
              label={t('Common:actions.continueEditing')}
              onClick={onContinueEditing}
            />
            <Button
              title={t('Common:actions.allDone')}
              data-test-id={TEST_IDS.bulkEdit.allDoneButton}
              label={t('Common:actions.allDone')}
              onClick={onAllDone}
            />
          </Box>
        </Box>
      </Modal.Content>
    </Modal>
  );
};

export default BulkEditCompletion;
