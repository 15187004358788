import { rivalGroupsSchema } from 'store/schema';

import { setEndpoint } from '../configure';
import { normalizeDataBySchema } from '../interceptors/normalize';

import type {
  FetchRivalsQueryType,
  FetchRivalsReturnType,
} from './rivals.types';

export const [fetchRivals] = setEndpoint<
  any,
  any,
  FetchRivalsQueryType,
  FetchRivalsReturnType
>({
  path: '/rivals.json',
  id: 'fetchRivals',
  method: 'GET',
  namespace: 'rivals',
});

export const [fetchRivalGroups] = setEndpoint({
  path: '/rival_groups.json',
  id: 'fetchRivalGroups',
  method: 'GET',
  namespace: 'rivals',
  globals: {
    interceptor: {
      after: [normalizeDataBySchema([rivalGroupsSchema])],
    },
  },
});

export const [fetchSellerRivals] = setEndpoint({
  path: '/seller_rivals.json',
  id: 'fetchSellerRivals',
  method: 'GET',
  namespace: 'rivals',
});
