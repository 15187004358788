import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';

import type { IntelReturnType, IntelListItemType } from './intel.types';
import type { ComposedReturnType } from 'lib/api';

const hasSnakeCase = (item: IntelListItemType) => {
  return Object.keys(item).some((key) => key.includes('_'));
};

export const intelInterceptor = (
  response: ComposedReturnType<IntelReturnType>,
) => {
  const { pagination, hits } = response.data || ({} as IntelReturnType);
  const { nextOffset = 0, size = 0 } = pagination;
  const currentOffset = Math.max(nextOffset - size, 0);
  const intelList = hits.map((intel, index) => {
    const rank = currentOffset + index + 1;

    if (hasSnakeCase(intel)) {
      return mapKeys({ ...intel, rank }, (v, k) => camelCase(k));
    }

    return {
      ...intel,
      rank,
    };
  });

  return {
    ...response,
    data: {
      ...response.data,
      hits: intelList,
    },
  };
};
