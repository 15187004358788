import { Tip, Box, Button, tokens } from '@kluein/klue-ui';
import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import type { AnimatedCardSentimentButtonProps } from './AnimatedCardSentimentButton.types';

const animation01 = keyframes`
  from { transform: rotate(-15deg) scale(0); }
  40% { opacity: 1; }
  to { 
    transform: rotate(-30deg) scale(2.5); 
    opacity: 0; 
  }
`;

const animation02 = keyframes`
  from { transform: rotate(10deg) scale(0); }
  40% { opacity: 1; }
  to { 
    transform: rotate(30deg) scale(2); 
    opacity: 0; 
  }
`;

const WrapperButton = styled(Button)`
  border-radius: 50%;
  border: 1px solid;
  border-color: ${tokens.color.neutral.lightgrey.main};
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    color: ${tokens.color.secondary.dark};
    border-color: ${tokens.color.secondary.main};
    box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    color: ${tokens.color.status.disabled.dark};
    border-color: ${tokens.color.status.disabled.main};
    box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.1);
  }

  &:active svg {
    transform: scale(0.8);
  }

  &.active {
    color: ${tokens.color.primary.main};
    border-color: ${tokens.color.neutral.lightgrey.main};
    box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.1);
  }

  &.trigger-animation {
    animation: 0.6s;
    box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.1);

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      transform: scale(0);
      color: ${tokens.color.primary.main};
      border: 1px solid transparent;
      box-shadow: -0.8em 0 0 -2px, 0.8em 0 0 -2px, 0 -0.8em 0 -2px,
        0 0.8em 0 -2px, -0.6em -0.6em 0 -2px, -0.6em 0.6em 0 -2px,
        0.6em -0.6em 0 -2px, 0.6em 0.6em 0 -2px;
    }

    &:before {
      animation: ${animation01} 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    &:after {
      animation: ${animation02} 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }
`;

function AnimatedCardSentimentButton({
  tipContent,
  tipAlign,
  onClick,
  icon,
  iconActive = icon,
  a11ytitle,
  disabled = false,
  active = false,
  'data-test-id': dataTestId,
  'data-tracking-id': dataTrackingId,
}: AnimatedCardSentimentButtonProps) {
  // Toggled on/off - determines icon
  const [isActive, setIsActive] = useState(active);

  // Whether to trigger the animation or not
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
    setIsClicked(!isActive);
    onClick?.();
  };

  const getClassName = () => {
    const classNames = [];
    if (isActive) {
      classNames.push('active');
    }
    if (isClicked) {
      classNames.push('trigger-animation');
    }
    return classNames.join(' ');
  };

  useEffect(() => {
    setIsActive(active);
    if (!active) {
      setIsClicked(false);
    }
  }, [active]);

  return (
    <Tip content={tipContent} dropProps={tipAlign} color="dark" variant="small">
      <Box>
        <WrapperButton
          variant={'flat-outlined'}
          onClick={handleClick}
          className={getClassName()}
          icon={isActive ? iconActive : icon}
          a11yTitle={a11ytitle}
          disabled={disabled}
          data-test-id={dataTestId}
          data-tracking-id={dataTrackingId}
        />
      </Box>
    </Tip>
  );
}

export default AnimatedCardSentimentButton;
