import { AccordionPanel, Box } from '@kluein/klue-ui';
import { useTranslation } from 'react-i18next';

import TEST_IDS from 'test-ids';

import RivalConnector from './RivalConnector';

type RivalGroupTypes = {
  name: string;
  id: number;
  rivals: Array<number>;
  currentProfileId: number;
  onNavigate: VoidFunction;
};

const RivalGroup = ({
  name,
  rivals,
  currentProfileId,
  onNavigate,
}: RivalGroupTypes) => {
  const { t } = useTranslation(['Common']);

  return (
    <AccordionPanel
      data-test-id={TEST_IDS.layout.quickSwitcher.rivalGroupItem}
      expandClickLabel={`${t('Common:actions.clickToExpand')}\n${name}`}
      label={name}
      count={rivals.length}
    >
      <Box
        data-test-id={TEST_IDS.layout.rivalItem.container}
        gap="xsmall"
        pad={{ vertical: 'small', horizontal: 'small' }}
        direction="column"
      >
        {rivals.map((rivalID) => (
          <RivalConnector
            key={rivalID}
            id={rivalID}
            currentProfileId={currentProfileId}
            onNavigate={onNavigate}
          />
        ))}
      </Box>
    </AccordionPanel>
  );
};

export default RivalGroup;
