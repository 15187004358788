import { getBrowsers } from 'lib/browserUtils';

type ExtensionMessageType = {
  type: string;
  body?: {
    intelId?: string;
  };
};

const CHROME_APP_ID = import.meta.env.VITE_CHROME_APP_ID || '';
const EDGE_APP_ID = import.meta.env.VITE_EDGE_APP_ID || '';

const isKlueMessage = (message: ExtensionMessageType) =>
  message && message.type && message.type.startsWith('klue');

const isValidExtensionId = (id = '') => /^[a-zA-Z]{32}$/.test(id);

export const sendMessageToExtension = (message: ExtensionMessageType) => {
  const { Chrome, Edge } = getBrowsers();

  const isSupportedBrowser = Chrome || Edge;

  if (!isSupportedBrowser) {
    return Promise.reject(
      'ExtUtils.sendMessageToExtension: unsupported browser',
    );
  }

  if (!isKlueMessage(message)) {
    return Promise.reject(
      'ExtUtils.sendMessageToExtension: no message provided',
    );
  }

  let extensionId;
  if (Chrome) extensionId = CHROME_APP_ID;
  if (Edge) extensionId = EDGE_APP_ID;

  if (!isValidExtensionId(extensionId)) {
    return Promise.reject(
      'ExtUtils.sendMessageToExtension: invalid or missing extension ID',
    );
  }

  if (!chrome?.runtime?.sendMessage) {
    return Promise.reject(
      'ExtUtils.sendMessageToExtension: no extension installed',
    );
  }

  return Promise.resolve(chrome.runtime.sendMessage(extensionId, message));
};

export const sendExtensionReloadAlertMessage = (intelId: string) =>
  sendMessageToExtension({
    type: 'klue:app:alerts:update',
    body: { intelId },
  }).catch((error) => {});
