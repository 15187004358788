import type { KlueCardProps } from './KlueCard.types';

export const getStickyStyles = ({
  variant,
}: Pick<KlueCardProps, 'variant'>) => {
  switch (variant) {
    case 'default':
      return {
        flex: '0 0 auto',
      };
    case 'single-page':
      return {
        height: '100%',
      };
    case 'stacker':
      return {
        flex: '0 0 auto',
        height: '100%',
      };
    default:
      return {};
  }
};
