import { createModel } from '@rematch/core';
import isEmpty from 'lodash/isEmpty';

import { fetchSellerRivals } from 'api/endpoints/rivals';

import type { SellerRival } from 'api/api.types';
import type { RootModel } from 'store/models';

export type StateType = {
  loading: boolean;
  sellerRivals: SellerRival;
};

export const initialState = {
  loading: true,
  sellerRivals: {},
} as StateType;

type PopulatePayload = { sellerRivals: SellerRival };

export const sellerRivals = createModel<RootModel>()({
  state: initialState,
  reducers: {
    populate: (state: StateType, { sellerRivals }: PopulatePayload) => {
      return {
        ...state,
        loading: false,
        sellerRivals: sellerRivals,
      };
    },
    setLoading: (state: StateType, loading: boolean) => {
      return { ...state, loading };
    },
    reset: () => initialState,
  },
  selectors: (slice, createSelector) => ({
    loading() {
      return slice(({ loading }) => loading);
    },
    sellerRivals() {
      return slice(({ sellerRivals }) => sellerRivals);
    },
  }),
  effects: (dispatch: any) => ({
    async fetchSellerRivalsFromApi(_, rootState) {
      try {
        if (!isEmpty(rootState.sellerRivals.sellerRivals)) return;

        const { data } = await fetchSellerRivals();
        dispatch.sellerRivals.setLoading(true);

        dispatch.sellerRivals.populate({ sellerRivals: data });
      } catch (error) {
      } finally {
        dispatch.sellerRivals.setLoading(false);
      }
    },
  }),
});
