import { createModel } from '@rematch/core';

import { fetchInstanceTags } from 'api/endpoints/tags';

import type { TagType } from 'api/api.types';
import type { RootModel } from 'store/models';

type TagMapType = Map<number, TagType>;

export type StateType = {
  byId: TagMapType;
  allIds: Set<number>;
  selectedTags: Set<number>;
};

export const initialState = {
  byId: new Map(),
  allIds: new Set(),
  selectedTags: new Set(),
} as StateType;

type PopulatePayload = { tags: TagMapType };
type ToggleSelectedPayload = { id: number };

export const normalizeTags = (data: Array<TagType>) =>
  data.reduce((acc, cur: TagType) => {
    acc.set(cur.id, { id: cur.id, name: cur.name });

    return acc;
  }, new Map());

export const tags = createModel<RootModel>()({
  state: initialState,
  reducers: {
    populate: (state, { tags }: PopulatePayload) => {
      return {
        ...state,
        byId: tags,
        allIds: new Set([...tags.keys()]),
      };
    },
    toggleSelectedTag: (state: StateType, { id }: ToggleSelectedPayload) => {
      const selectedTags = new Set(state.selectedTags);
      if (selectedTags.has(id)) {
        selectedTags.delete(id);
      } else {
        selectedTags.add(id);
      }
      return {
        ...state,
        selectedTags,
      };
    },
    deselectAll: (state: StateType) => {
      return {
        ...state,
        selectedTags: new Set<number>(),
      };
    },
  },
  selectors: (slice, createSelector) => ({
    byId() {
      return slice(({ byId }) => byId);
    },
    allIds() {
      return slice(({ allIds }) => allIds);
    },
    all() {
      return createSelector(this.byId as any, (byId: TagMapType) =>
        Array.from(byId.values()),
      );
    },
  }),
  effects: () => ({
    async fetchAndPopulateInstanceTags() {
      const { data } = await fetchInstanceTags({
        query: {
          page: 1,
          limit: 10000,
          minimal: 1,
          filter: 'active',
        },
      });

      const normalized = normalizeTags(data.items);

      this.populate({ tags: normalized });

      return data;
    },
  }),
});
