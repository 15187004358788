import { Box, Text, tokens, type BoxProps } from '@kluein/klue-ui';

type SectionTypes = BoxProps & {
  title: string;
  children: React.ReactNode;
};

const Section = ({ title, children, ...rest }: SectionTypes) => (
  <Box flex={{ shrink: 0 }} {...rest}>
    <Text
      margin={{ bottom: 'small' }}
      color={tokens.color.neutral.black.main}
      fontWeight="semibold"
      size="small"
    >
      {title.toUpperCase()}
    </Text>
    {children}
  </Box>
);

export default Section;
