import { createModel } from '@rematch/core';
import sortBy from 'lodash/sortBy';

import { fetchRivalGroups } from 'api/endpoints/rivals';
import { addVisibilityGroupIdToQuery, mergeMapValues } from 'store/utils';

import type { RivalGroupType } from 'api/api.types';
import type { FetchRivalGroupsQueryType } from 'api/endpoints/rivals.types';
import type { RootModel } from 'store/models';

export type StateType = {
  byId: Map<string, RivalGroupType>;
  allIds: Set<string>;
  loading: boolean;
};

export const initialState = {
  byId: new Map(),
  allIds: new Set(),
  loading: true,
} as StateType;

type PopulatePayload = { rivalGroups: Record<string, RivalGroupType> };

export const rivalGroups = createModel<RootModel>()({
  state: initialState,
  reducers: {
    populate: (state: StateType, { rivalGroups }: PopulatePayload) => {
      return {
        ...state,
        byId: mergeMapValues(state.byId, rivalGroups),
        allIds: new Set([...state.allIds, ...Object.keys(rivalGroups)]),
        loading: false,
      };
    },
    setLoading: (state: StateType, loading: boolean) => {
      return { ...state, loading };
    },
    reset: () => initialState,
    updateRivalGroupById: (state, { id, data }) => {
      const rivalGroup = state.byId.get(id);

      if (!rivalGroup) {
        return state;
      }

      const updatedRivalGroup = {
        ...rivalGroup,
        ...data,
      } as RivalGroupType;

      return {
        ...state,
        byId: new Map(state.byId).set(id, updatedRivalGroup),
      };
    },
  },
  selectors: (slice, createSelector) => ({
    byId() {
      return slice(({ byId }) => byId);
    },
    loading() {
      return slice(({ loading }) => loading);
    },
    getSortedRivalGroups() {
      return createSelector(this.byId as any, (byId: StateType['byId']) => {
        const sortedRivalGroups = sortBy(
          [...byId.values()],
          ({ viewOrder }) => +viewOrder,
        );
        return sortedRivalGroups;
      });
    },
  }),
  effects: (dispatch: any) => ({
    async fetchRivalGroupsFromApi(_, rootState) {
      try {
        if (!!rootState.rivalGroups.allIds.size) return;

        const query: FetchRivalGroupsQueryType = {};
        addVisibilityGroupIdToQuery(rootState, query);

        const { data } = await fetchRivalGroups({ query });
        dispatch.rivalGroups.setLoading(true);

        const { rivalGroups } = data.entities;

        dispatch.rivalGroups.populate({ rivalGroups });
      } catch (error) {
      } finally {
        dispatch.rivalGroups.setLoading(false);
      }
    },
  }),
});
