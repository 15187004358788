import { api } from '../api';

function transformResponse() {
  return undefined;
}

type QueryArg = { cardId: number; body: string };

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    createCardComment: build.mutation<void, QueryArg>({
      query: ({ body, cardId }) => ({
        method: 'POST',
        url: `/cards/${cardId}/comments`,
        body: { body },
      }),
      transformResponse,
    }),
  }),
});

export const { useCreateCardCommentMutation } = extendedApi;
