import { createModel } from '@rematch/core';

import { fetchVisibilityGroups } from 'api/endpoints/visibility-groups';
import { mergeArrayValues } from 'store/utils';

import type { VisibilityGroupType } from 'api/api.types';
import type { RootModel } from 'store/models';

export type StateType = {
  byId: Map<number, VisibilityGroupType>;
  sorted: Array<VisibilityGroupType>;
  visibleTo: number;
  selectedGroups: Set<number>;
};

export const initialState = {
  byId: new Map(),
  sorted: [],
  visibleTo: 0,
  selectedGroups: new Set(),
} as StateType;

type PopulatePayload = {
  visibilityGroups: Array<VisibilityGroupType>;
};
type ToggleVisibleToPayload = { id: number };
export type ToggleSelectedPayload = { id: number };

const FULL_ACCESS_GROUP = { id: 0 } as const;
type FullAccessGroupType = typeof FULL_ACCESS_GROUP;

export const visibilityGroups = createModel<RootModel>()({
  state: initialState,
  selectors: (slice) => ({
    byId() {
      return slice(({ byId }) => byId);
    },
    viewAs() {
      return slice(({ sorted }) => {
        const groups: (VisibilityGroupType | FullAccessGroupType)[] = [
          ...sorted,
        ];
        groups.push(FULL_ACCESS_GROUP);
        return groups;
      });
    },
  }),
  reducers: {
    populate: (state: StateType, { visibilityGroups }: PopulatePayload) => {
      const nameCompare = (a: string, b: string) => {
        if (a === b) {
          return 0;
        }
        return a < b ? -1 : 1;
      };
      const orderCompare = (
        { viewOrder: viewOrderA, name: nameA }: Partial<VisibilityGroupType>,
        { viewOrder: viewOrderB, name: nameB }: Partial<VisibilityGroupType>,
      ) => {
        if (viewOrderA === viewOrderB) {
          return nameCompare(nameA || '', nameB || '');
        }

        return (viewOrderA || '') < (viewOrderB || '') ? -1 : 1;
      };
      const sorted = [...visibilityGroups].sort(orderCompare);
      return {
        ...state,
        byId: mergeArrayValues(state.byId, visibilityGroups, 'id'),
        sorted,
      };
    },
    setVisibleTo: (state: StateType, { id }: ToggleVisibleToPayload) => {
      return {
        ...state,
        visibleTo: id,
      };
    },
    toggleSelectedGroup: (state: StateType, { id }: ToggleSelectedPayload) => {
      const updatedSelectedGroups = new Set(state.selectedGroups);
      if (updatedSelectedGroups.has(id)) {
        updatedSelectedGroups.delete(id);
      } else {
        updatedSelectedGroups.add(id);
      }
      return {
        ...state,
        selectedGroups: updatedSelectedGroups,
      };
    },
    deselectAllGroups: (state: StateType) => {
      return {
        ...state,
        selectedGroups: new Set<number>(),
      };
    },
  },
  effects: () => ({
    async fetchVisibilityGroupsFromApi() {
      try {
        const { data } = await fetchVisibilityGroups();

        this.populate({ visibilityGroups: data });
      } catch (error) {}
    },
  }),
});
