import { Box, tokens, PhosphorIcon } from '@kluein/klue-ui';
import styled from 'styled-components';

import { KLUE_CARD_ZOOM_IMG } from 'lib/constants';

export const StyledZoomInIcon = styled(PhosphorIcon.MagnifyingGlassPlus)`
  transform: scale(0.8);
  transition: transform 0.2s ease;
  pointer-events: none;
`;

export const StyledOverlay = styled(Box)`
  &.${KLUE_CARD_ZOOM_IMG} {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(1, 22, 39, 0.4);
  width: 100%;
  height: 100%;
  position: absolute;
  top: auto;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.5s ease;
`;

export const StyledBox = styled(Box)`
  color: ${tokens.color.neutral.white.main};
  display: block;
  position: relative;
  flex-direction: column;

  &:hover {
    ${StyledOverlay} {
      opacity: 1;
    }

    ${StyledZoomInIcon} {
      transform: scale(1);
    }
  }
`;
