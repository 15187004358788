import { normalizeObject } from 'api/interceptors/normalize';

import { setEndpoint } from '../configure';

import type { fetchBattlecardCardsByIdParamType } from './battlecards.types';

export const [fetchBattlecardCardsById] = setEndpoint<
  { id: number },
  any,
  fetchBattlecardCardsByIdParamType,
  any
>({
  path: '/battlecards/:id/cards.json',
  id: 'fetchBattlecardById',
  method: 'GET',
  globals: {
    interceptor: {
      after: [normalizeObject()],
    },
  },
  namespace: 'battlecards',
});
