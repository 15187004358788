import companyInterceptor from 'api/interceptors/companyInterceptor';

import { setEndpoint } from '../configure';

import type { CompanyType } from '../api.types';

export const [fetchCompany] = setEndpoint<any, any, any, CompanyType>({
  path: '/company.json',
  id: 'fetchCompany',
  method: 'GET',
  namespace: 'company',
  globals: {
    interceptor: {
      after: [companyInterceptor],
    },
  },
});

export const [updateCompany] = setEndpoint<any, any, any, CompanyType>({
  path: '/company.json',
  id: 'updateCompany',
  method: 'PUT',
  namespace: 'company',
  globals: {
    contentType: 'application/json',
    interceptor: {
      after: [companyInterceptor],
    },
  },
});
