import { createModel } from '@rematch/core';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

import {
  fetchBookmarks,
  createBookmark,
  updateBookmark as apiUpdateBookmark,
  deleteBookmark,
  fetchBookmarksCount,
} from 'api/endpoints/bookmarks';
import { mergeArrayValues } from 'store/utils';

import type { BookmarkType } from 'api/endpoints/bookmarks/bookmarks.types';
import type { ApiErrorType } from 'lib/api';
import type { RootModel } from 'store/models';

export type StateType = {
  byId: Map<number, BookmarkType>;
  allIds: Set<number>;
  errors?: ApiErrorType | null;
  isLoading: boolean;
  counts?: { view: string; total: number }[];
  previousView?: string;
};

export const initialState = {
  byId: new Map(),
  allIds: new Set(),
  errors: null,
  isLoading: false,
  counts: [],
  previousView: '',
} as StateType;

export const bookmarks = createModel<RootModel>()({
  state: initialState,
  reducers: {
    populate: (
      state,
      {
        bookmarks,
        rehydrate,
      }: { bookmarks: BookmarkType[]; rehydrate: boolean },
    ) => {
      if (rehydrate) {
        return {
          ...state,
          byId: mergeArrayValues(new Map(), bookmarks, 'id'),
          allIds: new Set(bookmarks.map((bookmark) => bookmark.id)),
          isLoading: false,
        };
      }

      return {
        ...state,
        byId: mergeArrayValues(state.byId, bookmarks, 'id'),
        allIds: new Set([
          ...state.allIds,
          ...bookmarks.map((bookmark) => bookmark.id),
        ]),
        isLoading: false,
      };
    },
    removeBookmarkFromState: (state, { id }: { id: number }) => {
      const byId = new Map(state.byId);
      const allIds = new Set(state.allIds);

      byId.delete(id);
      allIds.delete(id);

      return {
        ...state,
        byId,
        allIds,
      };
    },
    setBookmarkError: (state, error: ApiErrorType | null) => {
      return {
        ...state,
        errors: error,
      };
    },
    setIsLoading: (state, value: boolean) => {
      return {
        ...state,
        isLoading: value,
      };
    },
    setPreviousView: (state, value: string) => {
      return {
        ...state,
        previousView: value,
      };
    },
    updateCount: (state, counts) => {
      return {
        ...state,
        ...counts,
      };
    },
  },
  selectors: (slice, createSelector) => ({
    byId() {
      return slice(({ byId }) => byId);
    },
    allIds() {
      return slice(({ allIds }) => allIds);
    },
    all() {
      return createSelector(
        this.byId as any,
        (byId: Map<string, BookmarkType>): BookmarkType[] =>
          Array.from(byId.values()),
      );
    },
    errors() {
      return slice(({ errors }) => errors);
    },
    isLoading() {
      return slice(({ isLoading }) => isLoading);
    },
    previousView() {
      return slice(({ previousView }) => previousView);
    },
    counts() {
      return slice(({ counts }) => counts);
    },
  }),
  effects: () => ({
    async fetchAndPopulateBookmarks(path: string) {
      try {
        this.setIsLoading(true);
        const { data } = await fetchBookmarks({
          query: {
            path,
          },
        });
        this.populate({ bookmarks: data, rehydrate: true });
        this.setIsLoading(false);
      } catch (err) {
        throw err;
      }
    },
    async addBookmark({ name, url }: { name: string; url: string }) {
      try {
        const { data } = await createBookmark({
          params: {
            name,
            url,
          },
        });

        this.populate({ bookmarks: [data] });
      } catch (error) {
        this.setBookmarkError(error);
        throw error;
      }
    },
    async removeBookmark({ id }: { id: number }) {
      try {
        await deleteBookmark({
          path: {
            id,
          },
        });

        this.removeBookmarkFromState({ id });
      } catch (error) {
        this.setBookmarkError(error);
        throw error;
      }
    },
    async updateBookmark({
      id,
      name,
      url,
    }: {
      id: number;
      name?: string;
      url?: string;
    }) {
      try {
        const updatePayload = omitBy(omitBy({ name, url }, isUndefined), isNil);

        const { data } = await apiUpdateBookmark({
          path: {
            id,
          },
          params: {
            id,
            ...updatePayload,
          },
        });

        this.populate({ bookmarks: [data] });
      } catch (error) {
        this.setBookmarkError(error);
        throw error;
      }
    },
    async fetchBookmarksCount({ bookmarksData }) {
      try {
        const { data } = await fetchBookmarksCount({
          params: {
            search: bookmarksData,
          },
        });
        const bookmarksCount = data?.search?.map(
          (e: { pagination: { total: number } }, i: string | number) => {
            const count = {} as any;
            count.total = e.pagination.total;
            count.view = bookmarksData?.[i]?.view;
            return count;
          },
        );
        this.updateCount({ counts: bookmarksCount });
      } catch (error) {
        throw error;
      }
    },
  }),
});
