import { ImportanceEnum } from 'lib/api';

import { setEndpoint } from '../configure';

import type {
  CommonQueryStringType,
  ContifyQueryStringType,
  ContifyReturnType,
  SimilarwebReturnType,
  KlueReturnType,
  CraftReturnType,
  SalesforceOpportunitiesReturnType,
} from './dynamic-data.types';

export const [fetchCraftData] = setEndpoint<
  any,
  any,
  CommonQueryStringType,
  CraftReturnType
>({
  path: '/providers/craft.json',
  id: 'fetchCraftData',
  method: 'GET',
  namespace: 'dynamicData',
  importance: ImportanceEnum.low,
});

export const [fetchPdlData] = setEndpoint<
  any,
  any,
  CommonQueryStringType,
  CraftReturnType
>({
  path: '/providers/peopledatalabs.json',
  id: 'fetchPdlData',
  method: 'GET',
  namespace: 'dynamicData',
  importance: ImportanceEnum.low,
});

export const [fetchKlueData] = setEndpoint<
  any,
  any,
  CommonQueryStringType,
  KlueReturnType
>({
  path: '/providers/klue.json',
  id: 'fetchKlueData',
  method: 'GET',
  namespace: 'dynamicData',
  importance: ImportanceEnum.low,
});

export const [fetchSimilarwebData] = setEndpoint<
  any,
  any,
  CommonQueryStringType,
  SimilarwebReturnType
>({
  path: '/providers/similarweb.json',
  id: 'fetchSimilarwebData',
  method: 'GET',
  namespace: 'dynamicData',
  importance: ImportanceEnum.low,
});

export const [fetchContifyData] = setEndpoint<
  any,
  any,
  ContifyQueryStringType,
  ContifyReturnType
>({
  path: '/providers/contify.json',
  id: 'fetchContifyData',
  method: 'GET',
  namespace: 'dynamicData',
  importance: ImportanceEnum.low,
});

export const [fetchSalesforceInstance] = setEndpoint<
  any,
  any,
  any,
  {
    competitorsField: Array<string>;
    instance: string;
    notesField: string;
  }
>({
  path: '/providers/sfdc/instance',
  id: 'fetchSalesforceInstance',
  method: 'GET',
  namespace: 'dynamicData',
  importance: ImportanceEnum.low,
});

export const [fetchSalesforceOpportunities] = setEndpoint<
  any,
  any,
  any,
  SalesforceOpportunitiesReturnType
>({
  path: '/providers/sfdc/opportunities',
  id: 'fetchSalesforceOpportunities',
  method: 'GET',
  namespace: 'dynamicData',
  importance: ImportanceEnum.low,
});

export const [fetchSalesforcePerson] = setEndpoint<any, any, any>({
  path: '/providers/sfdc/person',
  id: 'fetchSalesforcePerson',
  method: 'GET',
  namespace: 'dynamicData',
  importance: ImportanceEnum.low,
});

export const [fetchSalesforceAccount] = setEndpoint<any, any, any>({
  path: '/providers/sfdc/account',
  id: 'fetchSalesforceAccount',
  method: 'GET',
  namespace: 'dynamicData',
  importance: ImportanceEnum.low,
});

export const [fetchPredictLeads] = setEndpoint<any, any, any>({
  path: '/providers/predictleads.json',
  id: 'fetchPredictLeads',
  method: 'GET',
  namespace: 'dynamicData',
  importance: ImportanceEnum.low,
});
