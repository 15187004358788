import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useBulkEditOptions } from 'contexts/ui/bulk-edit-context';
import { BulkEditOption } from 'contexts/ui/bulk-edit-context/bulkEditContext.types';
import { useCardSelections } from 'hooks/use-card-selections';

import useBulkEditDelete from './useBulkEditDelete';
import useBulkEditPermissions from './useBulkEditPermissions';
import useBulkEditTags from './useBulkEditTags';

import type { Dispatch } from 'store/store.types';

const useBulkEditor = () => {
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [tagsCount, setTagsCount] = useState(0);
  const { options, activeOption, setActiveOption } = useBulkEditOptions();

  const { selectedCards = new Set(), totalSelectedCount: count } =
    useCardSelections();

  const bulkEditPermissions = useBulkEditPermissions(selectedCards);
  const bulkEditTags = useBulkEditTags(selectedCards);
  const bulkEditDelete = useBulkEditDelete(selectedCards);

  let handleApply: () => Promise<any> = () =>
      Promise.reject(new Error('Unsupported bulk edit option.')),
    disabled = false;
  switch (activeOption) {
    case BulkEditOption.CardPermissions:
      ({ handleApply, disabled } = bulkEditPermissions);
      break;
    case BulkEditOption.Tags:
      ({ handleApply, disabled } = bulkEditTags);
      break;
    case BulkEditOption.Delete:
      ({ handleApply, disabled } = bulkEditDelete);
      break;
  }

  const apply = async () => {
    setProcessing(true);
    try {
      await handleApply();
      setSuccess(true);
    } catch (err) {
      setError(true);
    } finally {
      setProcessing(false);
    }
  };

  const deselectAllCards = () => {
    dispatch.cards.deselectAllCards();
  };

  const reset = ({ resetCards = true } = {}) => {
    if (resetCards) deselectAllCards();
    bulkEditPermissions.reset();
    bulkEditTags.reset();
    setSuccess(false);
    setError(false);
  };

  const setOption = (option: BulkEditOption) => {
    setActiveOption(option);
    reset({ resetCards: false });
  };

  useEffect(() => {
    const fetchTags = async () => {
      // Fetch tags, populate the global state and use the async response to determine
      // if we need to redirect to card permissions options in case there's no tags available
      const { totalItems = 0 } =
        await dispatch.tags.fetchAndPopulateInstanceTags();
      setLoading(false);
      setTagsCount(totalItems);

      if (activeOption === BulkEditOption.Tags && totalItems === 0) {
        setActiveOption(BulkEditOption.CardPermissions);
      }
    };

    fetchTags();
  }, [dispatch, setActiveOption, activeOption]);

  const filteredOptions = Array.from(options).filter((option) => {
    if (tagsCount === 0) {
      return option !== BulkEditOption.Tags;
    }

    return option;
  });

  return {
    loading,
    processing,
    success,
    error,
    options: filteredOptions,
    option: activeOption,
    count,
    setOption,
    apply,
    disabled: disabled || success || !count || processing,
    deselectAllCards,
    reset,
  };
};

export default useBulkEditor;
