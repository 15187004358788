export enum BattlecardType {
  desktop = 'desktop',
  tablet = 'tablet',
  mobile = 'mobile',
}

export type fetchBattlecardCardsByIdParamType = {
  v: string;
  battlecardType: BattlecardType;
  cardIds?: Array<number>;
  visibilityGroupId?: number;
};
