import type { FetchOptionsType, InitType } from 'lib/api/getApiLayer.types';

let csrf = '';

const userInterceptor = (response: any) => {
  const { data } = response;
  csrf = data.csrfToken;
  return response;
};

export const getCsrfToken = () => csrf;

// TODO: we need a better strategy from ApiLayer to change global config on demand
export const insertCsrfToken = (
  options: FetchOptionsType,
): FetchOptionsType => {
  const requestIndex = 0;
  const initIndex = 1;
  let fetchConfig = options[initIndex] as InitType;

  if (fetchConfig?.method !== 'GET') {
    fetchConfig = {
      ...fetchConfig,
      headers: {
        ...(fetchConfig?.headers ?? {}),
        'X-CSRF-Token': csrf,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    };
  }

  return [options[requestIndex], fetchConfig];
};

export default userInterceptor;
