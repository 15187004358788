import { useState, useContext, createContext, useCallback } from 'react';

import type { ModalPropType, ToastPropType } from '@kluein/klue-ui';

type UIModalType = {
  isOpen: boolean;
  open: (args: ModalPropType) => void;
  close: () => void;
  props: ModalPropType;
};

type UIToastType = {
  isOpen: boolean;
  props: ToastPropType | null;
  open: (args: ToastPropType) => void;
  close: () => void;
};

export const UIModalIntialValues = {
  isOpen: false,
  props: {},
  open: () => null,
  close: () => null,
};

export const UIToastIntialValues = {
  isOpen: false,
  props: {},
  open: () => null,
  close: () => null,
};

// If more context added to the UIType,
// it may be better splitting into separate files
type UIType = {
  modal: UIModalType;
  toast: UIToastType;
};

export const initialValue = {
  modal: UIModalIntialValues,
  toast: UIToastIntialValues,
};

export const appUIContext = createContext<UIType>(initialValue);

export const useAppUIProvider = () => {
  const [modalState, setModalState] =
    useState<UIModalType>(UIModalIntialValues);
  const [toastState, setToastState] =
    useState<UIToastType>(UIToastIntialValues);

  const openModal = useCallback(
    (modalProps: ModalPropType) => {
      setModalState({
        ...modalState,
        isOpen: true,
        props: modalProps || {},
      });
    },
    [modalState],
  );

  const closeModal = () => {
    setModalState({
      ...modalState,
      isOpen: false,
      props: {},
    });
  };

  const openToast = (toastProps: ToastPropType) => {
    setToastState((s) => ({
      ...s,
      isOpen: true,
      props: toastProps || {},
    }));
  };

  const closeToast = () => {
    if (!!toastState.props?.onClose) {
      toastState.props.onClose();
    }

    setToastState((s) => ({
      ...s,
      isOpen: false,
      props: {},
    }));
  };

  return {
    modal: {
      ...modalState,
      open: openModal,
      close: closeModal,
    },
    toast: {
      ...toastState,
      open: openToast,
      close: closeToast,
    },
  };
};

/**
 * @name useAppUIContext
 * @description Access UI global context to control modals and other layout elements
 * @example
 * const {modal} = useAppUIContext();
 * modal.open({image: 'http://...'})
 */
export const useAppUIContext = () => {
  return useContext(appUIContext);
};
