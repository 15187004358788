import { matchPath } from 'react-router-dom';

import { HTTP_STATUS } from 'lib/constants';
import { APP_V1_BASEURL } from 'lib/urls';

import { PUBLIC_ROUTES } from '../../constants';

const isPublicRoute = () => {
  const { pathname } = window.location;

  for (const route of PUBLIC_ROUTES) {
    if (
      !!matchPath(
        {
          path: route,
          caseSensitive: true,
          end: false,
        },
        pathname,
      )
    ) {
      return true;
    }
  }

  return false;
};

//response is any, and here it is correct cause
//this interceptor is a global interceptor, so it will run
//in case any endpoint receives an error
const authInterceptor = (response: any) => {
  const { status } = response;

  if (isPublicRoute()) {
    return response;
  }

  if (status === HTTP_STATUS?.UNAUTHORIZED) {
    const redirect = encodeURIComponent(window.location.href);
    return (window.location.href = `${APP_V1_BASEURL}/account/signin?redirectTo=${redirect}`);
  }

  return response;
};

export default authInterceptor;
