import { setEndpoint } from 'api/configure';

import type { CardType, TagType } from '../api.types';

type InstanceTagsQueryType = {
  filter?: 'active' | 'assigned,active';
  search?: string;
  limit?: number;
  page?: number;
  minimal?: 1;
};

type InstanceTagsApiResponseType = {
  totalItems: number;
  items: Array<TagType>;
};

export const [fetchInstanceTags] = setEndpoint<
  any,
  any,
  InstanceTagsQueryType,
  InstanceTagsApiResponseType
>({
  path: '/tags.json',
  id: 'fetchInstanceTags',
  method: 'GET',
  namespace: 'tags',
});

type TagByIdParamsType = {
  tagId: number;
};

export const [getTagById] = setEndpoint<
  any,
  TagByIdParamsType,
  any,
  InstanceTagsApiResponseType
>({
  path: '/tags/:tagId.json',
  id: 'fetchTagById',
  method: 'GET',
  namespace: 'tags',
});

type BulkUpdateCardTagsParamsType = {
  cardIds: Array<number>;
  tags?: Array<number>;
  addTags?: Array<number>;
  removeTags?: Array<number>;
  keepUpdatedAt?: boolean;
};

export type BulkUpdateCardTagsResponseType = Array<CardType>;

export const [bulkUpdateCardTags] = setEndpoint<
  any,
  BulkUpdateCardTagsParamsType,
  any,
  BulkUpdateCardTagsResponseType
>({
  path: '/cards.json',
  id: 'bulkUpdateCardTags',
  method: 'PUT',
  namespace: 'tags',
});
