import { useCallback } from 'react';
import { useNavigate, useMatch, useLocation } from 'react-router-dom';
import { useQueryParams } from 'use-query-params';

import { useSearch } from 'contexts/ui/search-context';
import { useFiltersFromURL } from 'hooks/alerts/use-filters-from-url';
import { LAST_UPDATED_FILTERS } from 'lib/constants';
import { NumericCommaSetParam } from 'utils/query-params';

import type { UpdatedStartType } from 'api/endpoints/search.types';

const LAST_UPDATED_QUERY_PARAM_VALUES = Object.values(
  LAST_UPDATED_FILTERS,
).filter(
  (lastUpdatedFilter) => lastUpdatedFilter !== LAST_UPDATED_FILTERS.allTime,
);

const LastUpdatedParam = {
  encode: (lastUpdated: UpdatedStartType | null | undefined) => {
    return lastUpdated || undefined;
  },
  decode: (
    lastUpdated: string | (string | null)[] | null | undefined,
  ): UpdatedStartType | null => {
    if (Array.isArray(lastUpdated)) {
      return LastUpdatedParam.decode(lastUpdated[lastUpdated.length - 1]);
    }
    if (
      !lastUpdated ||
      !LAST_UPDATED_QUERY_PARAM_VALUES.includes(lastUpdated)
    ) {
      return null;
    }
    return lastUpdated as UpdatedStartType;
  },
};

const QueryParamConfig = {
  lastUpdated: LastUpdatedParam,
  tags: NumericCommaSetParam,
  boards: NumericCommaSetParam,
};

export default function useSearchParams() {
  const navigate = useNavigate();
  const { context } = useSearch();
  const { filtersFromURL } = useFiltersFromURL();
  const { search } = useLocation();
  const { params: { terms = '' } = {} } = useMatch('/search/:terms') || {};

  const setTerms = useCallback(
    (terms: string, method: 'push' | 'replace' = 'push') =>
      navigate(
        `/search${terms ? `/${encodeURIComponent(terms)}` : ''}${search}`,
        {
          replace: method === 'replace',
        },
      ),
    [navigate, search],
  );
  const [{ lastUpdated, tags, boards }, setQuery] =
    useQueryParams(QueryParamConfig);

  if (context === 'alerts') {
    return {
      terms: filtersFromURL.q || '',
    };
  }

  return {
    terms,
    setTerms,
    lastUpdated,
    tags,
    boards,
    setQuery,
  };
}
