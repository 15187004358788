import { CheckBox } from '@kluein/klue-ui';

import TEST_IDS from 'test-ids';
import TRACKING_IDS from 'tracking-ids';

type CardSelectorCheckProps = {
  checked?: boolean;
  mixed?: boolean;
  onClick?: VoidFunction;
};

const CardSelectorCheck = ({
  checked = false,
  mixed = false,
  onClick,
}: CardSelectorCheckProps) => {
  return (
    <CheckBox
      checked={!mixed && checked}
      data-test-id={TEST_IDS.klueCard.cardSelector.checkbox}
      data-tracking-id={TRACKING_IDS.profile.bulkedit.selectAllLane}
      indeterminate={mixed}
      onClick={onClick}
    />
  );
};

export default CardSelectorCheck;
