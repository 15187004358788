export const SIDEBAR_SHORTCUT = 'ctrl+shift+d, cmd+shift+d';
export const BATTLECARD_STACKER_NAV_PREV = 'left';
export const BATTLECARD_STACKER_NAV_NEXT = 'right';
export const COMMAND_CENTER_IMPORTANT = 'a';
export const COMMAND_CENTER_INTERESTING = 's';
export const COMMAND_CENTER_ARCHIVE = 'd';
export const COMMAND_CENTER_DELETE = 'f';
export const COMMAND_CENTER_BACK = 'v';
export const COMMAND_CENTER_BACK_UP = 'up';
export const COMMAND_CENTER_SKIP = 'space';
export const COMMAND_CENTER_SKIP_DOWN = 'down';
export const COMMAND_CENTER_EXIT_TRIAGE = 'esc';
