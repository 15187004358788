import type { TrackerConfiguration } from '@snowplow/browser-tracker';

export type PageTrackerType = (page: { path: string; title?: string }) => void;

export type StateType = {
  providers?: {
    ga?: { accountNames: string[]; isInitialized: boolean };
    sp?: {
      trackers?: { appId: string; collectorUrl: string }[];
      isInitialized: boolean;
    };
  };
};

export type TrackerType = {
  provider: 'ga' | 'sp';
  config?: Record<string, any>;
};

export type GoogleAnalyticsConfig = {
  trackingId: string;
};
export type SnowplowAnalyticsConfig = Partial<TrackerConfiguration> & {
  collectorUrl?: string;
};

export type AnalyticsParams = {
  companyId: string;
  companyName: string;
  debug?: boolean;
  providers: {
    ga?: GoogleAnalyticsConfig;
    sp?: SnowplowAnalyticsConfig;
  };
  userId: string;
  emailDomain: string;
  isCurator: boolean;
  isV2: boolean;
};

export enum AnalyticsCategory {
  profile = 'Profile',
  battlecard = 'Battlecard',
  search = 'Global Search',
  zoom = 'Content Zoom',
  curatorAddedLinks = 'Curator added links',
  // Intel
  intelAction = 'intel_action',
  impression = 'impression',
  // Card
  cardInteraction = 'card_interaction',
  // Search
  searchAction = 'search_action',
  // Insights_action
  insightsAction = 'insights_action',
  // Ask Klue
  askKlueInteraction = 'ask_klue_interaction',
  // Report
  report = 'report',
  // Integration Card Actions
  integrationCardAction = 'integration_card_action',
  // Data Sync
  dataSyncConfigurationComplete = 'data_sync_configuration_complete',
  dataSyncAuthModified = 'data_sync_auth_modified',
  researchModuleAction = 'research_module_action',
  // Composer
  composerCard = 'composer_card',
  composerCardAction = 'composer_card_action',
  // Call Insights
  callDataSyncAuthModified = 'call_data_sync_auth_modified',
  callDataSyncConfigurationComplete = 'call_data_sync_configuration_complete',
  // Competitive Dashboard
  competitiveDashboardAction = 'competitive_dashboard_action',
  //  Unleash
  unleashFeatureFlag = 'unleash_feature_flag',
}

export const AnalyticsAction = {
  view: 'view',
  tagClick: 'Tag Click',
  search: 'search',
  searchEmpty: 'search (no results)',
  tagFilter: 'Filter By Tag',
  tagSearch: 'Search by Tag',
  battlecardSearch: 'click_battlecard_search',
  //Intel
  intel: 'intel',
  visitSource: 'visit_source',
  visitLink: 'visit_link',
  addWhyItMatters: 'add_why_it_matters',
  editWhyItMatters: 'edit_why_it_matters',
  removeWhyItMatters: 'remove_why_it_matters',
  addToDigest: 'add_to_digest',
  removeFromDigest: 'remove_from_digest',
  sendToSlack: 'send_to_slack',
  publishToSlack: 'publish_to_slack',
  monitor: 'monitor',
  addImportant: 'add_important',
  addInteresting: 'add_interesting',
  removeImportant: 'remove_important',
  removeInteresting: 'remove_interesting',
  addTag: 'add_tag',
  removeTag: 'remove_tag',
  removeCard: 'remove_card',
  addBoard: 'add_board',
  removeBoard: 'remove_board',
  visitBoard: 'visit_board',
  publish: 'publish',
  unpublish: 'unpublish',
  archived: 'archived',
  deleted: 'deleted',
  unprocessed: 'unprocessed',
  working: 'working',
  skip: 'skip',
  unknown: 'unknown', // TODO: I don't see this action type in Slite docs. should we remove this and fix the place that is sending this instead?
  // card
  positiveFeedback: 'positive_feedback',
  negativeFeedback: 'negative_feedback',
  removedFeedback: 'removed_feedback',
  expand: 'expand',
  viewSources: 'view_sources',
  edit: 'click_edit',
  copyEmbed: 'copy_embed',
  copyLink: 'copy_share',
  duplicateCard: 'duplicate_card',
  moveCard: 'move_card',
  link: 'link',
  table: 'table',
  boardCard: 'board_card',
  card: 'card',
  tag: 'tag_click',
  image: 'image',
  highlight: 'highlight',
  // insights_action
  updateFilter: 'update_filter',
  updateInsightFilter: 'update_insight_filter',
  pinReview: 'pin_review',
  unpinReview: 'unpin_review',
  hideAspect: 'hide_aspect',
  unhideAspect: 'unhide_aspect',
  visitReviewSource: 'visit_source',
  generateSummary: 'generate_summary',
  sourceButtonClick: 'source_button_click',
  createInsightCard: 'create_insight_card',
  copyTopicSummary: 'copy_topic_summary',
  createTopicSummaryCard: 'create_topic_summary_card',
  // search
  generateResult: 'generate_result',
  generateResultFail: 'generate_result_fail',
  supportedRivalsResults: 'supported_rivals_result',
  // ask_klue
  ask: 'ask',
  askSuggest: 'ask_suggest',
  renderResponse: 'render_response',
  copyResult: 'copy_result',
  openModal: 'open_modal',
  closeModal: 'close_modal',
  renderAskKlue: 'render_ask_klue',
  renderAskSuggest: 'render_ask_suggest',
  focusTextInputBox: 'focus_text_input_box',
  // report
  downloadChart: 'download_chart',
  drillDown: 'drill_down',
  // integration
  clickInstall: 'click_install',
  clickUninstall: 'click_uninstall',
  clickConnect: 'click_connect',
  clickWaitlist: 'click_waitlist',
  clickContactSupport: 'click_contact_support',
  clickResumeInstall: 'click_resume_install',
  clickResolveDegraded: 'click_resolve_degraded',
  // data sync
  dataSyncConfigurationComplete: 'data_sync_configuration_complete',
  dataSyncAuthModified: 'data_sync_auth_modified',
  // research module
  shareFeedback: 'share_feedback',
  // call insights
  callDataSyncAuthModified: 'call_data_sync_auth_modified',
  callDataSyncConfigurationComplete: 'call_data_sync_configuration_complete',
  callDataSyncToggled: 'call_data_sync_toggled',
  // competitive dashboard
  competitiveDashboardAction: 'competitive_dashboard_action',
  competitiveDashboardClick: 'competitive_dashboard_click',
  competitiveDashboardView: 'view',
  // unleash
  unleashFeatureFlag: 'unleash_feature_flag',
} as const;

export type AnalyticsActionType =
  (typeof AnalyticsAction)[keyof typeof AnalyticsAction];

export type AnalyticsEvent = {
  category: AnalyticsCategory;
  action: AnalyticsActionType;
  label?: string;
  value?: number;
  provider?: 'ga' | 'sp';
};

export type SnowplowAnalyticsEvent = {
  event: {
    schema: string;
    data: Record<string, any>;
  };
  context?: any[];
};

export type EventLoggerType = {
  event: AnalyticsEvent;
  data?: Record<string, any>;
};

export type AnalyticsContextType = {
  logEvent: (data: EventLoggerType) => void;
  addTracker: (trackerConfig: TrackerType) => void;
  removeTracker: (trackerConfig: TrackerType) => void;
  analytics: StateType;
};
