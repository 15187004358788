import { checkCuratorAccess } from 'contexts/auth/auth.utils';
import { transformBoardNameToUpperCase } from 'lib/stringUtils';
import store from 'store';

const profileInterceptor = (response: any) => {
  const { auth } = store.getState();
  const isCurator = checkCuratorAccess(auth.user);
  const mappedResponse = {
    ...response,
    data: {
      ...response.data,
      boards: (response.data?.boards || []).reduce((acc: any[], board: any) => {
        if (isCurator && board.name === 'Scratchpad') {
          return acc;
        }
        acc.push({
          ...board,
          name: transformBoardNameToUpperCase(board.name),
        });
        return acc;
      }, []),
    },
  };
  return mappedResponse;
};

export default profileInterceptor;
