import {
  Anchor,
  Avatar,
  Box,
  Button,
  Modal,
  Text,
  tokens,
  useResponsive,
  PhosphorIcon,
} from '@kluein/klue-ui';
import { useTranslation } from 'react-i18next';

import { KlueCard } from 'components/common/klueCard';
import { startCase } from 'lib/utils';
import TEST_IDS from 'test-ids';

import { KlueSpinner } from '../klue-spinner';

import type { CardViewerModalTypes } from './CardViewer.types';

export default function CardViewerModal(props: CardViewerModalTypes) {
  const { t } = useTranslation(['Common']);
  const { maxWidth } = useResponsive();
  const {
    onClose,
    profile,
    card,
    onProfileClick,
    onLaneClick,
    loadFailed,
    rank,
  } = props;

  const isLoading = (!card?.id || !profile?.id) && !loadFailed;

  return (
    <Modal
      onClose={onClose}
      showCloseButton={false}
      width={maxWidth.medium ? 'auto' : 'xxlarge'}
      testId={TEST_IDS.cardViewer.container}
      full={maxWidth.medium}
    >
      <>
        {!!profile?.id && (
          <Modal.Header>
            <Box
              direction="row"
              gap="medium"
              align="center"
              data-test-id={TEST_IDS.cardViewer.breadcrumb}
            >
              <Avatar size="medium" round="xxsmall" background="brand">
                {profile?.name.substring(0, 2).toUpperCase()}
              </Avatar>
              <Anchor label={profile?.name} onClick={onProfileClick} /> /
              <Anchor
                label={startCase(card?.board.name)}
                onClick={onLaneClick}
              />
            </Box>
            <Button
              variant="flat-outlined"
              size="small"
              onClick={onClose}
              icon={<PhosphorIcon.X color={tokens.color.primary.main} />}
              data-test-id={TEST_IDS.cardViewer.closeButton}
            />
          </Modal.Header>
        )}

        {loadFailed && (
          <Modal.Content align="center">
            <Box
              gap="medium"
              justify="center"
              align="center"
              height="large"
              data-test-id={TEST_IDS.cardViewer.error}
            >
              <Text size="large">{t(`Common:cardViewer.failed`)}</Text>

              <Button variant="flat-outlined" onClick={onClose} size="small">
                {t(`Common:actions.clickToClose`)}
              </Button>
            </Box>
          </Modal.Content>
        )}

        {!loadFailed && (
          <Modal.Content align="center">
            {isLoading ? (
              <Box
                height={'large'}
                align="center"
                justify="center"
                data-test-id={TEST_IDS.cardViewer.loading}
              >
                <KlueSpinner />
              </Box>
            ) : (
              <KlueCard
                cardData={card}
                rivalName={profile?.name}
                boardName={card?.board.name}
                isLoading={Boolean(card)}
                isWide
                rank={rank}
              />
            )}
          </Modal.Content>
        )}
      </>
    </Modal>
  );
}
