/* eslint-disable max-lines */
import {
  Box,
  Button,
  List,
  Text,
  type ListItemType,
  tokens,
  useResponsive,
  Badge,
  PhosphorIcon,
} from '@kluein/klue-ui';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from 'contexts/auth';
import { APP_V1_BASEURL } from 'lib/urls';
import TEST_IDS from 'test-ids';
import TRACKING_IDS from 'tracking-ids';

import { StyledButton, StyledResponsiveBox } from './AppNavBarMenu.styles';

type ResponsiveListItemType = {
  title: string;
  data: ListItemType[];
};

type MenuItemProps<T> = {
  label: string;
  selectedItem: string | null;
  listItems: T;
  tab?: string;
};

type MenuListItemType = {
  label: string;
} & ListItemType;

function MenuItem({
  label,
  selectedItem,
  listItems,
  tab,
}: MenuItemProps<MenuListItemType[]>) {
  const boxRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const tabName = selectedItem?.split(':')[0];
  const isTabSelected = tab === tabName;

  const trackingId = TRACKING_IDS.global.appNavBar[
    tab as keyof typeof TRACKING_IDS.global.appNavBar
  ] as string;

  listItems.sort((linkA, linkB) =>
    linkA.label.toLowerCase().localeCompare(linkB.label.toLowerCase()),
  );

  return (
    <Box style={{ position: 'relative' }}>
      <StyledButton isActive={isVisible || isTabSelected} ref={boxRef}>
        <Button
          data-tracking-id={trackingId}
          variant="flat"
          label={label}
          dropContentVisible={isVisible}
          onClick={() => setIsVisible(!isVisible)}
          rightSlot={
            <PhosphorIcon.CaretDown
              color={tokens.color.neutral[1000]}
              size={tokens.iconSize.small}
            />
          }
          dropProps={{
            round: tokens.borderRadius.medium,
            margin: { top: 'xsmall' },
            align: { right: 'right', top: 'bottom' },
            elevation: 'medium',
            onClickOutside: (e) => {
              if (
                boxRef.current &&
                boxRef.current.contains(e.target as HTMLElement)
              ) {
                return;
              }
              setIsVisible(false);
            },
          }}
          dropContent={
            <Box
              pad="medium"
              style={{ cursor: 'default' }}
              width={{ min: 'medium' }}
              round={tokens.borderRadius.medium}
              border={{
                color: tokens.color.neutral[500],
                size: 'xsmall',
              }}
            >
              <List
                a11yTitle={label}
                data={listItems as ListItemType[]}
                selectedItem={selectedItem}
                itemProps={{
                  size: 'medium',
                }}
                truncateLength={25}
                step={listItems.length}
                paginate={false}
                onClickItem={() => setIsVisible(false)}
              />
            </Box>
          }
        />
      </StyledButton>
    </Box>
  );
}

function MenuItemResponsive({
  label,
  selectedItem,
  listItems,
}: MenuItemProps<ResponsiveListItemType[]>) {
  const boxRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  return (
    <StyledResponsiveBox
      data-test-id={TEST_IDS.appNavBar.appNavBarMenuResponsive}
      justify="center"
      align="center"
      height="full"
      pad={{ horizontal: 'small' }}
      isActive={isVisible}
      ref={boxRef}
    >
      <Button
        variant="plain"
        dropContentVisible={isVisible}
        onClick={() => setIsVisible(!isVisible)}
        icon={<PhosphorIcon.DotsNine />}
        label={undefined}
        data-tracking-id={TRACKING_IDS.global.appNavBar.responsiveMenu}
        dropProps={{
          round: tokens.borderRadius.medium,
          margin: { top: 'medium', left: 'medium' },
          align: { right: 'right', top: 'bottom' },
          elevation: 'medium',
          onClickOutside: (e) => {
            if (
              boxRef.current &&
              boxRef.current.contains(e.target as HTMLElement)
            ) {
              return;
            }
            setIsVisible(false);
          },
        }}
        dropContent={
          <Box
            pad="medium"
            style={{ cursor: 'default' }}
            width={{ min: 'medium' }}
            round={tokens.borderRadius.medium}
            border={{
              color: tokens.color.neutral[500],
              size: 'xsmall',
            }}
          >
            {listItems.map((item, index) => (
              <Box
                margin={{
                  bottom: index + 1 === listItems.length ? 'none' : 'medium',
                }}
                key={item.title}
                flex="grow"
              >
                <Box justify="between">
                  <Text
                    fontWeight="medium"
                    size="small"
                    color={tokens.color.neutral[800]}
                    style={{ textTransform: 'uppercase' }}
                  >
                    {item.title}
                  </Text>
                </Box>
                <Box
                  flex="grow"
                  height="hair"
                  background={tokens.color.neutral[500]}
                  margin={{ vertical: 'small' }}
                />

                <Box>
                  <List
                    a11yTitle={label}
                    data={item.data}
                    selectedItem={selectedItem}
                    itemProps={{
                      size: 'medium',
                    }}
                    truncateLength={25}
                    step={item.data.length}
                    paginate={false}
                    onClickItem={() => setIsVisible(false)}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        }
      />
    </StyledResponsiveBox>
  );
}

function AppNavBarMenu() {
  const { pathname } = useLocation();
  const {
    isCurator,
    isWinLossEnabled,
    user,
    isAdmin,
    isCRAReportEnabledForCurators,
    isConsumerReportEnabledForCurators,
    isCuratorReportEnabledForCurators,
    isComposerEnabled,
  } = useAuth();

  const { maxWidth } = useResponsive();
  const navigate = useNavigate();

  const { t } = useTranslation(['Common', 'Alerts']);

  const discoverMenu = useMemo(() => {
    const links: MenuListItemType[] = [];

    if (isCurator) {
      links.push({
        label: t('Common:alerts'),
        key: 'discover:alerts',
        onClick: () => navigate('/alerts'),
        icon: <PhosphorIcon.Pulse />,
        testId: TEST_IDS.appNavBar.navBarLinks.alerts,
        trackingId: TRACKING_IDS.global.appNavBar.alerts,
      });
      links.push({
        label: t('Common:monitors'),
        key: 'discover:monitors',
        onClick: () => navigate('/monitors'),
        icon: <PhosphorIcon.Binoculars />,
        testId: TEST_IDS.appNavBar.navBarLinks.monitors,
        trackingId: TRACKING_IDS.global.appNavBar.monitors,
      });
      links.push({
        label: t('Common:salesCalls'),
        key: 'discover:sales-calls',
        onClick: () => navigate('/call-insights'),
        icon: <PhosphorIcon.Phone />,
        testId: TEST_IDS.appNavBar.navBarLinks.salesCalls,
        trackingId: TRACKING_IDS.global.appNavBar.salesCalls,
        rightSlot: (
          <Badge
            label={t('Common:new').toUpperCase()}
            size="small"
            color="blue"
          />
        ),
      });
    }

    links.push({
      label: t('Common:winLoss'),
      key: 'discover:win-loss',
      onClick: () => navigate('/win-loss'),
      icon: <PhosphorIcon.WinLoss />,
      rightSlot: isWinLossEnabled ? undefined : (
        <Badge
          label={t('Common:new').toUpperCase()}
          size="small"
          color="purple"
        />
      ),
      testId: TEST_IDS.appNavBar.navBarLinks.winLoss,
      trackingId: TRACKING_IDS.winLoss.globalNav,
    });

    const hasAccessToInsight = isAdmin || isCurator || user?.isImpersonating;

    if (hasAccessToInsight) {
      links.push({
        label: t('Common:insights'),
        key: 'discover:insights',
        onClick: () => navigate('/insights'),
        icon: <PhosphorIcon.Sparkle />,
        testId: TEST_IDS.appNavBar.navBarLinks.insights,
        trackingId: TRACKING_IDS.global.appNavBar.insights,
      });
    }

    return links;
  }, [t, navigate, isCurator, isWinLossEnabled, user, isAdmin]);

  const shareMenu = useMemo(() => {
    const links = [
      {
        label: t('Common:digest'),
        key: 'share:digest',
        href: `${APP_V1_BASEURL}/digest/overview`,
        icon: <PhosphorIcon.TelegramLogo />,
        testId: TEST_IDS.appNavBar.navBarLinks.digest,
        trackingId: TRACKING_IDS.global.appNavBar.digest,
      },
      {
        label: t('Common:feed'),
        key: 'share:feed',
        href: `${APP_V1_BASEURL}/feed`,
        icon: <PhosphorIcon.Newspaper />,
        testId: TEST_IDS.appNavBar.navBarLinks.feed,
        trackingId: TRACKING_IDS.global.appNavBar.feed,
      },
      {
        label: t('Common:boards'),
        key: 'share:boards',
        onClick: () => navigate('/dashboard'),
        icon: <PhosphorIcon.Cards />,
        testId: TEST_IDS.appNavBar.navBarLinks.boards,
        trackingId: TRACKING_IDS.global.appNavBar.boards,
      },
      {
        label: t('Common:integrations'),
        key: 'share:integrations',
        onClick: () => navigate('/integrations'),
        icon: <PhosphorIcon.Plugs />,
        testId: TEST_IDS.appNavBar.navBarLinks.integrations,
        trackingId: TRACKING_IDS.global.appNavBar.integrations,
      },
    ] as MenuListItemType[];

    if (isComposerEnabled) {
      links.push({
        label: t('Common:navBar.composer'),
        key: 'share:composer',
        onClick: () => navigate('/composer'),
        icon: <PhosphorIcon.MagicWand />,
        testId: TEST_IDS.appNavBar.navBarLinks.composer,
        trackingId: TRACKING_IDS.global.appNavBar.composer,
      });
    }

    return links;
  }, [t, isComposerEnabled, navigate]);

  const measureMenu = useMemo(() => {
    let links = [];
    const consumer = {
      label: t('Common:consumerUsage'),
      key: 'measure:consumer-usage',
      onClick: () => navigate('/reports/consumer-usage/overview'),
      icon: <PhosphorIcon.UsersThree />,
      testId: TEST_IDS.appNavBar.navBarLinks.consumerUsage,
      trackingId: TRACKING_IDS.global.appNavBar.consumerUsage,
    };
    const curator = {
      label: t('Common:curatorActivity'),
      key: 'measure:curator',
      onClick: () => navigate('/reports/curator'),
      icon: <PhosphorIcon.IdentificationBadge />,
      testId: TEST_IDS.appNavBar.navBarLinks.curatorActivity,
      trackingId: TRACKING_IDS.global.appNavBar.curatorActivity,
    };
    const cra = {
      label: t('Common:competitiveAnalytics'),
      key: 'measure:competitive-analytics',
      onClick: () => navigate('/reports/competitive-analytics/overview'),
      icon: <PhosphorIcon.ChartBar />,
      testId: TEST_IDS.appNavBar.navBarLinks.competitiveAnalytics,
      trackingId: TRACKING_IDS.global.appNavBar.competitiveAnalytics,
    };
    if (isAdmin) {
      links = [consumer, curator, cra] as MenuListItemType[];
    } else if (isCurator) {
      if (isCRAReportEnabledForCurators) links.push(cra);
      if (isConsumerReportEnabledForCurators) links.push(consumer);
      if (isCuratorReportEnabledForCurators) links.push(curator);
    }
    return links;
  }, [
    t,
    isAdmin,
    isCurator,
    navigate,
    isCRAReportEnabledForCurators,
    isConsumerReportEnabledForCurators,
    isCuratorReportEnabledForCurators,
  ]);

  const responsiveMenu = useMemo(() => {
    const measure = {
      title: t('Common:navBarMenu.measure'),
      data: measureMenu,
    };

    const menu = [
      {
        title: t('Common:navBarMenu.discover'),
        data: discoverMenu,
      },
      {
        title: t('Common:navBarMenu.share'),
        data: shareMenu,
      },
    ];

    if (measureMenu.length > 0) menu.push(measure);

    return menu;
  }, [t, discoverMenu, shareMenu, measureMenu]);

  const pageSelected = useMemo(() => {
    const allMenuLinks = [...discoverMenu, ...shareMenu, ...measureMenu];
    const findPageSelected = allMenuLinks.find((item) => {
      const key = item.key.split(':')[1];
      return key === pathname.split('/')[1] || key === pathname.split('/')[2];
    });
    if (findPageSelected) return findPageSelected.key;
    return null;
  }, [pathname, discoverMenu, shareMenu, measureMenu]);

  if (maxWidth.large)
    return (
      <MenuItemResponsive
        label={t('Common:navBarMenu.discover')}
        selectedItem={pageSelected}
        listItems={responsiveMenu}
      />
    );

  return (
    <Box
      direction="row"
      gap="medium"
      data-test-id={TEST_IDS.appNavBar.appNavBarMenu}
    >
      <MenuItem
        label={t('Common:navBarMenu.discover')}
        selectedItem={pageSelected}
        listItems={discoverMenu}
        tab="discover"
      />
      <MenuItem
        label={t('Common:navBarMenu.share')}
        selectedItem={pageSelected}
        listItems={shareMenu}
        tab="share"
      />
      {(isAdmin || isCurator) && measureMenu.length > 0 && (
        <MenuItem
          label={t('Common:navBarMenu.measure')}
          selectedItem={pageSelected}
          listItems={measureMenu}
          tab="measure"
        />
      )}
    </Box>
  );
}

export default AppNavBarMenu;
