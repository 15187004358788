import { setEndpoint } from '../../configure';
import { normalizeObject } from '../../interceptors/normalize';
import userInterceptor from '../../interceptors/userInterceptor';

import type { UserType } from '../../api.types';
import type { UserFilterType } from 'api/endpoints/users/users.types';

export const [fetchUserMe] = setEndpoint<any, any, any, UserType>({
  path: '/users/me.json',
  id: 'fetchUserMe',
  method: 'GET',
  namespace: 'user',
  globals: {
    interceptor: {
      after: [userInterceptor],
    },
  },
});

export const [fetchUserById] = setEndpoint<{ id: number }, any, any, UserType>({
  path: '/users/:id.json',
  id: 'fetchUserById',
  method: 'GET',
  namespace: 'user',
});

export const [fetchUsers] = setEndpoint<
  any,
  any,
  {
    typeFilter: UserFilterType;
    page?: number;
    limit?: number;
    order?: string;
    query?: string;
  },
  Record<number, UserType>
>({
  path: '/users.json',
  id: 'fetchUsers',
  method: 'GET',
  namespace: 'user',
  globals: {
    interceptor: {
      after: [normalizeObject()],
    },
  },
});

export const [updateCurrentUser] = setEndpoint<
  any,
  | { featureFlag: [string | number | (string | number)[], any] }
  | Partial<UserType>,
  any,
  UserType
>({
  path: `/users/me.json`,
  id: 'updateCurrentUser',
  method: 'PUT',
  namespace: 'user',
  globals: {
    contentType: 'application/json',
    interceptor: {
      after: [userInterceptor],
    },
  },
});

export const [updateUser] = setEndpoint<any, Partial<UserType>, any, UserType>({
  path: `/users/:id.json`,
  id: 'updateUser',
  method: 'PUT',
  namespace: 'user',
  globals: {
    contentType: 'application/json',
    interceptor: {
      after: [userInterceptor],
    },
  },
});
