import { createModel } from '@rematch/core';

import { mergeMapValues } from 'store/utils';

import type { RootModel } from '..';
import type { CardTitlesType } from 'api/api.types';

export type StateType = {
  byId: Map<string, CardTitlesType>;
  allIds: Set<string>;
};

export const initialState = {
  byId: new Map<string, CardTitlesType>(),
  allIds: new Set<string>(),
} as StateType;

type PopulatePayload = { cardTitles: Record<number, CardTitlesType> };

export const cardTitles = createModel<RootModel>()({
  state: initialState,
  reducers: {
    populate: (state, { cardTitles }: PopulatePayload) => {
      return {
        byId: mergeMapValues(state.byId, cardTitles),
        allIds: new Set([...state.allIds, ...Object.keys(cardTitles)]),
      };
    },
  },
});
