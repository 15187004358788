import { createModel } from '@rematch/core';

import { updateCompany } from 'api/endpoints/company';
import { updateCurrentUser } from 'api/endpoints/users';
import { APP_V1_BASEURL } from 'lib/urls';

import {
  sortRecentlyViewedItems,
  type UpdateLastProfileViewedType,
} from './auth.utils';

import type { StateType as VisibilityGroupsStateType } from '../profile/visibilityGroups.model';
import type {
  CompanyType,
  KlueCardSentimentEnum,
  UserType,
} from 'api/api.types';
import type { NotificationsCountsType } from 'api/endpoints/notifications.types';
import type { RootModel } from 'store/models';
import type { StoreSelectors } from 'store/store.types';

export type StateType = {
  user: UserType | null;
  notificationsCounts?: NotificationsCountsType;
  company: CompanyType | null;
  visibilityGroup: number | null;
  previewExitInfoDialog: {
    destination: string;
    redirectUrl: string;
  } | null;
};

export const initialState = {
  user: null,
  notificationsCounts: {},
  company: null,
  visibilityGroup: null,
  previewExitInfoDialog: null,
} as StateType;

export const auth = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setUser: (state, user: UserType | null) => {
      return {
        ...state,
        user,
      };
    },
    setNotificationsCounts: (
      state,
      notificationsCounts: NotificationsCountsType,
    ) => {
      return {
        ...state,
        notificationsCounts,
      };
    },
    setCompany: (state, company: CompanyType | null) => {
      return {
        ...state,
        company,
      };
    },
    setVisibilityGroup: (state, visibilityGroup: number | null) => {
      return {
        ...state,
        visibilityGroup,
      };
    },
    setPreviewExitInfoDialog: (
      state: StateType,
      previewExitInfoDialog: StateType['previewExitInfoDialog'],
    ) => {
      return {
        ...state,
        previewExitInfoDialog,
      };
    },
  },
  selectors: (slice, createSelector) => ({
    user: () => slice(({ user }) => user),
    userData: () => slice(({ user }) => user?.userData),
    userDataDefaults: () => slice(({ user }) => user?.userData?.defaults),
    company: () => slice(({ company }) => company),
    companyDataDefaults: () =>
      slice(({ company }) => company?.companyData?.defaults),
    notifications: () =>
      slice(
        ({ notificationsCounts }) =>
          (notificationsCounts?.notificationsCount || 0) +
          (notificationsCounts?.staleBattlecardsCount || 0),
      ),
    visibilityGroupId: () => slice(({ visibilityGroup }) => visibilityGroup),
    visibilityGroup(models: StoreSelectors) {
      return createSelector(
        models.visibilityGroups.byId as any,
        this.visibilityGroupId as any,
        (
          visibilityGroupsById: VisibilityGroupsStateType['byId'],
          visibilityGroupId: StateType['visibilityGroup'],
        ) => {
          if (visibilityGroupId !== null) {
            return visibilityGroupsById.get(visibilityGroupId);
          }
        },
      );
    },
  }),
  effects: (dispatch) => ({
    /**
     * Update the current user state with the most recently viewed item
     * @param param { profileId, profileName, rivalLogo }
     * @param rootState redux state
     */
    async updateLastProfileViewed(
      { profileId, profileName, rivalLogo }: UpdateLastProfileViewedType,
      rootState,
    ) {
      const currentUser = rootState.auth.user;

      const recentlyViewedObject =
        currentUser?.userData?.recentlyViewed?.profile || [];

      const newItems = sortRecentlyViewedItems(recentlyViewedObject, {
        profileId,
        profileName,
        rivalLogo,
      });

      await dispatch.auth.updateUserData({
        path: ['recentlyViewed', 'profile'],
        value: newItems,
      });
    },
    /**
     * Update the current user state signifying that they are not on their fist load
     * @param none
     */
    async updateUserFirstLoad() {
      await dispatch.auth.updateUserData({
        path: 'alertsPastFirstLoad',
        value: true,
      });
    },
    /**
     * Update the current user state signifying whether or not they have dashboard news toggled on
     * @param toggle: boolean
     */
    async updateUserDashboardNewsToggle(toggle: boolean) {
      await dispatch.auth.updateUserData({
        path: 'dashboardNewsToggle',
        value: toggle,
      });
    },
    /**
     * Update the current user state signifying which companies they have favorited
     * @param toggle: boolean
     */
    async updateUserFavoritedCompanies(favoritedCompanies: number[]) {
      await dispatch.auth.updateUserData({
        path: 'favoritedCompanies',
        value: favoritedCompanies,
      });
    },
    /**
     * Update the current user state signifying what are user preferences for summary settings
     * @param param { defaultAlertsSummarySettings: { length: string; form: string; } }
     */
    async updateSummaryDefaultSettings({ length, form }) {
      await dispatch.auth.updateUserData({
        path: 'defaultAlertsSummarySettings',
        value: {
          length,
          form,
        },
      });
    },
    /**
     * Update the current user state preferences for review insights summary settings
     * @param param { defaultReviewInsightsSummarySettings: { form: string; } }
     */
    async updateReviewInsightsDefaultSettings({ format }) {
      await dispatch.auth.updateUserData({
        path: 'defaultReviewInsightsSummarySettings',
        value: { format },
      });
    },
    /**
     * Update the current user state with the card sentiment
     * @param cardId: number
     * @param sentimentType: KlueCardSentimentEnum
     */
    async updateUserCardSentiment(
      {
        cardId,
        sentimentType,
      }: {
        cardId: number;
        sentimentType: KlueCardSentimentEnum;
      },
      rootState,
    ) {
      await dispatch.auth.updateUserData({
        path: ['cardSentiment', `${cardId}`],
        value: sentimentType,
      });
    },
    /**
     * remove the card sentiment
     * @param cardId: number
     */
    async removeUserCardSentiment(
      {
        cardId,
      }: {
        cardId: number;
      },
      rootState,
    ) {
      await dispatch.auth.updateUserData({
        path: ['cardSentiment', `${cardId}`],
        value: null,
      });
    },
    async updateUserData(
      {
        path,
        value,
      }: {
        path: string | number | (string | number)[];
        value: any;
      },
      rootState,
    ) {
      const userData = rootState.auth.user?.userData;

      if (!userData) {
        throw new Error('State not ready');
      }

      const { data: updatedUser } = await updateCurrentUser({
        params: { featureFlag: [path, value] },
      });

      dispatch.auth.setUser(updatedUser);
    },
    async updateBoardNewsVisits(rivalId: number, rootState) {
      await dispatch.auth.updateUserData({
        path: ['boardNewsVisits', `${rivalId}`],
        value: new Date().toISOString(),
      });
    },
    async updateFeatureFlag(
      {
        path,
        value,
      }: {
        path: string | number | (string | number)[];
        value: any;
      },
      rootState,
    ) {
      const newCompany = {
        featureFlag: [path, value],
      };

      const { data: updatedCompany } = await updateCompany({
        params: { ...newCompany },
      });

      dispatch.auth.setCompany(updatedCompany);
    },
    signOut(_, rootState) {
      const userToken = rootState.auth.user?.csrfToken;

      if (!userToken) return;

      const signOutForm = document.createElement('form');
      const methodInput = document.createElement('input');
      const tokenInput = document.createElement('input');

      Object.assign(signOutForm, {
        action: `${APP_V1_BASEURL}/account/signout`,
        method: 'POST',
      });

      Object.assign(methodInput, {
        name: '_method',
        type: 'hidden',
        value: 'DELETE',
      });

      Object.assign(tokenInput, {
        name: 'authenticity_token',
        type: 'hidden',
        value: userToken,
      });

      signOutForm.append(methodInput, tokenInput);

      document.body.appendChild(signOutForm);
      signOutForm.submit();

      dispatch.auth.setUser(null);
    },

    revertImpersonating(_, rootState) {
      const userToken = rootState.auth.user?.csrfToken;

      if (!userToken) return;

      const revertForm = document.createElement('form');
      const methodInput = document.createElement('input');
      const tokenInput = document.createElement('input');

      Object.assign(revertForm, {
        action: `${APP_V1_BASEURL}/impersonate/revert`,
        method: 'POST',
      });

      Object.assign(methodInput, {
        name: '_method',
        type: 'hidden',
        value: 'DELETE',
      });

      Object.assign(tokenInput, {
        name: 'authenticity_token',
        type: 'hidden',
        value: userToken,
      });

      revertForm.append(methodInput, tokenInput);

      document.body.appendChild(revertForm);
      revertForm.submit();
    },
  }),
});
