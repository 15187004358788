import store from 'store';

import { intelInterceptor } from './intelInterceptor';
import ErrorsDictionary, { SKIP_ERRORS } from './utils/errors-dictionary';

import { setEndpoint } from '../../configure';
import errorInterceptor from '../../interceptors/errorInterceptor';

import type { Topic } from 'api/endpoints/intel/intel.enums';
import type {
  BulkUpdateIntelBodyType,
  UpdateIntelBodyType,
  SummarizeIntelBodyType,
  IntelListItemType,
  IntelReturnType,
  IntelQueryParamsType,
  IntelCountersType,
  IntelJobDetailsType,
} from 'api/endpoints/intel/intel.types';

export const [fetchIntel] = setEndpoint<
  any,
  any,
  IntelQueryParamsType,
  IntelReturnType
>({
  path: '/intel/v1',
  id: 'fetchIntel',
  method: 'GET',
  namespace: 'intel',
  globals: {
    interceptor: {
      after: [intelInterceptor],
      errorHandler: [
        (error): any => {
          if (SKIP_ERRORS.includes(error?.name as string)) return;

          const detailedError =
            ErrorsDictionary[
              error.status as unknown as keyof typeof ErrorsDictionary
            ] || ErrorsDictionary.default;

          return errorInterceptor(error, {
            ...detailedError,
            errorNotifier: store.dispatch.intelCards.setIntelListError,
          });
        },
      ],
    },
  },
});

export const [fetchIntelById] = setEndpoint<
  { intelId: string },
  any,
  any,
  IntelListItemType
>({
  path: '/intel/v1/:intelId',
  id: 'fetchIntelById',
  method: 'GET',
  namespace: 'intel',
});

export const [fetchIntelByJobId] = setEndpoint<
  { jobId: string },
  any,
  any,
  IntelJobDetailsType
>({
  path: '/intel/v1/jobs/:jobId',
  id: 'fetchIntelByJobId',
  method: 'GET',
  namespace: 'intel',
});

export const [fetchIntelCounters] = setEndpoint<
  any,
  any,
  IntelQueryParamsType,
  IntelCountersType
>({
  path: '/intel/v1/count',
  id: 'intelCounters',
  method: 'GET',
  namespace: 'intel',
});

export const [fetchIntelTopic] = setEndpoint<any, any, Topic[]>({
  path: '/intel/v1/topics',
  id: 'fetchIntelTopic',
  method: 'GET',
  namespace: 'intel',
});

export const [cancelIntelByJobId] = setEndpoint<
  { jobId: string },
  any,
  any,
  any
>({
  path: '/intel/v1/jobs/:jobId/cancel',
  id: 'cancelIntelByJobId',
  method: 'POST',
  namespace: 'intel',
});

export const [updateIntelCardData] = setEndpoint<
  { intelId: string },
  UpdateIntelBodyType,
  any,
  IntelListItemType
>({
  path: '/intel/v1/:intelId',
  id: 'updateIntelCardData',
  method: 'PATCH',
  namespace: 'intel',
  globals: {
    interceptor: {
      errorHandler: [
        (error) =>
          errorInterceptor(error, {
            retryAction: () => {
              store.dispatch.intelCards.retryFailedActions(null);
            },
          }),
      ],
    },
  },
});

export const [updateIntelCardsData] = setEndpoint<
  { intelIds: string[] },
  BulkUpdateIntelBodyType,
  any,
  any
>({
  path: '/intel/v1/',
  id: 'updateIntelCardsData',
  method: 'PATCH',
  namespace: 'intel',
  globals: {
    interceptor: {
      errorHandler: [
        (error) =>
          errorInterceptor(error, {
            retryAction: () => {
              store.dispatch.intelCards.retryFailedActions(null);
            },
          }),
      ],
    },
  },
});

export const [removeIntelRival] = setEndpoint<
  { intelId: string; rivalId: number },
  any,
  any,
  any
>({
  path: '/intel/v1/:intelId/rival/:rivalId',
  id: 'removeRival',
  method: 'DELETE',
  namespace: 'intel',
  globals: {
    interceptor: {
      errorHandler: [
        (error) =>
          errorInterceptor(error, {
            retryAction: () => {
              store.dispatch.intelCards.retryFailedActions(null);
            },
          }),
      ],
    },
  },
});

export const [updateIntelCardState] = setEndpoint<
  { intelId: string; state: string },
  any,
  IntelListItemType
>({
  path: '/intel/:intelId',
  id: 'updateIntelCardState',
  method: 'PUT',
  namespace: 'intel',
  globals: {
    interceptor: {
      errorHandler: [
        (error) =>
          errorInterceptor(error, {
            retryAction: () => {
              store.dispatch.intelCards.retryFailedActions(null);
            },
          }),
      ],
    },
  },
});

export const [unpublishIntel] = setEndpoint<
  { intelId: string },
  any,
  any,
  IntelListItemType
>({
  path: '/intel/v1/:intelId/unpublish',
  id: 'unpublishIntel',
  method: 'POST',
  namespace: 'intel',
  globals: {
    interceptor: {
      errorHandler: [
        (error) =>
          errorInterceptor(error, {
            retryAction: () => {
              store.dispatch.intelCards.retryFailedActions(null);
            },
          }),
      ],
    },
  },
});

export const [fetchIntelSummary] = setEndpoint<
  { intelId: string },
  SummarizeIntelBodyType,
  any,
  IntelListItemType
>({
  path: '/intel/v1/:intelId/summarize',
  id: 'fetchIntelSummary',
  method: 'POST',
  namespace: 'intel',
  globals: {
    interceptor: {
      errorHandler: [
        (error) =>
          errorInterceptor(error, {
            retryAction: () => {
              store.dispatch.intelCards.retryFailedActions(null);
            },
          }),
      ],
    },
  },
});
