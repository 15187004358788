import { setEndpoint } from 'api/configure';

import type { AlertItemBodyType } from './alerts.types';

export const [createAlert] = setEndpoint<any, AlertItemBodyType, any, any>({
  path: '/alerts.json',
  id: 'createAlert',
  method: 'POST',
  namespace: 'alerts',
});
