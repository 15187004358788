import { useState } from 'react';

type CopiedTextType = string | null;
type CopyToClipboardType = (text: string) => Promise<boolean>;

function useCopyToClipboard(): [CopyToClipboardType, CopiedTextType] {
  const [copiedText, setCopiedText] = useState<CopiedTextType>(null);

  const copyToClipboard = async (str: string) => {
    if (!navigator?.clipboard) return false;

    try {
      await navigator.clipboard.writeText(str);
      setCopiedText(str);
      return true;
    } catch (error) {
      setCopiedText(null);
      return false;
    }
  };

  return [copyToClipboard, copiedText];
}

export default useCopyToClipboard;
